import { Box, Button } from '@mui/material'
import { formatKey } from '../../../../../utils/stringUtils'
import { isEmpty } from 'lodash'
import { useAppContext } from '../../../../../app'
import { useCciLeftPanelContext } from '../CCI_LeftPanel'
import { useCciMainContext } from '../../../CCI_Main'
import React, { useCallback, useEffect } from 'react'

// components

export const CCI_LeftPanel_Controls = () => {
  const { isSearchDataLoading, searchTerm, setSearchPlaceholder } = useAppContext()
  const { activeKeys, searchableData, setActiveKeys, setSearchableData } = useCciLeftPanelContext()

  const { setExpandAllCallback, tab } = useCciMainContext()

  const generateActiveKeys = useCallback(() => {
    if (!searchableData) return []

    switch (tab) {
      case undefined:
      case 'checklist':
        return searchableData.map((item: any) => formatKey(item))
      case 'documentTypes':
        setSearchableData(null)
        break
      case 'integrations':
        return searchableData.map((item: any) => formatKey(item.node.id))
      case 'roles':
        return searchableData.map((item: any) => formatKey(item.id))
      case 'users':
        return searchableData.map((item: any) => formatKey(item.user_name))
      default:
        return []
    }
  }, [searchableData, setSearchableData, tab])

  useEffect(() => setExpandAllCallback(() => () => setActiveKeys(generateActiveKeys)), [generateActiveKeys, setActiveKeys, setExpandAllCallback])

  useEffect(() => {
    if (searchTerm && tab === 'checklist') {
      setActiveKeys(generateActiveKeys)
    }
  }, [generateActiveKeys, searchTerm, setActiveKeys, tab])

  useEffect(() => {
    switch (tab) {
      case 'checklist':
        setSearchPlaceholder(`${isSearchDataLoading ? 'Loading' : 'Search'} checklist…`)
        break
      case 'integrations':
        setSearchPlaceholder(`${isSearchDataLoading ? 'Loading' : 'Search'} integrations…`)
        break
      case 'roles':
        setSearchPlaceholder(`${isSearchDataLoading ? 'Loading' : 'Search'} roles…`)
        break
      case 'users':
        setSearchPlaceholder(`${isSearchDataLoading ? 'Loading' : 'Search'} users…`)
        break
    }
  }, [isSearchDataLoading, setSearchPlaceholder, tab])

  return (
    <>
      <Box sx={{ display: 'flex', gap: 0.5, justifyContent: 'flex-end', p: 2 }}>
        <Button
          color="inherit"
          disabled={activeKeys.length === searchableData?.length}
          onClick={() => setActiveKeys(generateActiveKeys)}
          size="small"
          variant="contained"
        >
          Expand all
        </Button>

        <Button color="inherit" disabled={isEmpty(activeKeys)} onClick={() => setActiveKeys([])} size="small" variant="contained">
          Collapse all
        </Button>
      </Box>
    </>
  )
}

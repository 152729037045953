import { AdditionalInfoInput } from './CreateInputs/AdditionalInfoInput'
import { BooleanInputs } from './CreateInputs/BooleanInputs'
import { Box, Button, List, ListItem, Typography } from '@mui/material'
import { CollisionTypeInput, CollisionTypes } from './SharedInputs/CollisionTypeInput'
import { DataTableMatchRules, MatchRule, MatchRules } from './SharedInputs/MatchRule'
import { ExtractionFieldTypeInput } from './SharedInputs/ExtractionFieldTypeInput'
import { Features, Permissions, useUserAccess } from '../../../../../../hooks/useUserAccess'
import { FieldOptions } from './CreateInputs/FieldOptions'
import { FieldSemanticsInput } from './SharedInputs/FieldSemanticsInput'
import { FieldTypeInput, FieldTypes } from './CreateInputs/FieldTypeInput'
import { MatchFields } from './SharedInputs/MatchFields'
import { ModalOptions, useCciMainContext } from '../../../../CCI_Main'
import { NeutralTagInput } from './CreateInputs/NeutralTagInput'
import { ResolutionStrategyInput } from './SharedInputs/ResolutionStrategyInput'
import { SourceInput, Sources } from './CreateInputs/SourceInput'
import { isEmpty, size } from 'lodash'
import { useMatchingChildrenDataPointFieldsQuery } from '../../../../../../graphql/codegen/hooks'
import CircleIcon from '@mui/icons-material/Circle'
import DefaultValueInput from './CreateInputs/DefaultValueInput'
import GroupInput from './CreateInputs/GroupInput'
import NameInput from './CreateInputs/NameInput'
import React, { FC, useEffect, useMemo, useState } from 'react'
import WithTooltip from '../../../../../../components/WithTooltip'
import css from './style.module.scss'

// constants

export const BOOLEAN_OPTIONS = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'N/A', label: 'N/A' }
]

// components

export const CreateField: FC = () => {
  const { openModal } = useCciMainContext()
  const { data: matchingChildrenData } = useMatchingChildrenDataPointFieldsQuery()
  const [values, setValues] = useState<any>(undefined)
  const [isDisabled, setIsDisabled] = useState(false)
  const [disabledMessage, setDisabledMessage] = useState<any>(undefined)

  const hasChecklistCreateAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.CREATE_FIELD })

  const handleData = (type: string, value: any) => {
    if (type && !value && value !== false) {
      setValues((prev: any) => {
        const updatedObject = { ...prev }
        delete updatedObject[type]
        return { ...updatedObject }
      })
    } else {
      switch (type) {
        case '':
        case undefined:
        case null:
          console.error(`CreateField handleData error: Must provide a valid option`)
          return
        case 'options':
          return setValues((prev: any) => {
            return { ...prev, options: [...value] }
          })
        default:
          return setValues((prev: any) => {
            return { ...prev, [type]: value }
          })
      }
    }
  }

  useMemo(() => {
    if (values?.source !== Sources.MATCHING) {
      delete values?.match_children_fields
      delete values?.match_rule
    }
  }, [values?.source]) // eslint-disable-line react-hooks/exhaustive-deps

  useMemo(() => {
    if (values?.source === Sources.MATCHING && values?.field_type !== FieldTypes.BOOLEAN) {
      handleData('field_type', FieldTypes.BOOLEAN)
    }
  }, [values?.field_type, values?.source])

  const isMatchRuleReadOnly = useMemo(() => {
    if (!matchingChildrenData || !values?.match_children_fields || values.source !== Sources.MATCHING) return false

    const fieldType = matchingChildrenData.matching_children_data_point_fields?.edges.find(
      dataPointField => values.match_children_fields[0]?.data_point_field_id === dataPointField?.node?.id
    )?.node?.field_type

    return fieldType !== FieldTypes.TEXT && fieldType !== FieldTypes.DATA_TABLE
  }, [matchingChildrenData, values?.match_children_fields, values?.source])

  useMemo(() => (isMatchRuleReadOnly ? handleData('match_rule', MatchRules.STRICT_EQUALITY) : delete values?.match_rule), [isMatchRuleReadOnly]) // eslint-disable-line react-hooks/exhaustive-deps

  // Any change to values checks if required values are present and enables/disables the submit button
  useEffect(() => {
    validateInputs(values, setIsDisabled, setDisabledMessage)
  }, [values])

  return (
    <>
      <div className={css.editField}>
        <Typography sx={{ mb: 4, mt: 1.5, textAlign: 'center' }} variant="h3">
          Create Field
        </Typography>

        <NameInput handleData={handleData} value={values?.name} />

        <GroupInput handleData={handleData} value={values?.group} />

        <SourceInput handleData={handleData} hasFullAccess={hasChecklistCreateAccess} value={values?.source} />

        {values?.source === Sources.INTERNAL && <NeutralTagInput handleData={handleData} value={values?.neutral_tag} />}

        <FieldTypeInput handleData={handleData} isReadOnly={values?.source === Sources.MATCHING} value={values?.field_type} />

        <ExtractionFieldTypeInput handleData={handleData} source={values?.source} value={values?.extraction_field_type} />

        <FieldOptions
          defaultValue={values?.default_value || ''}
          fieldType={values?.field_type || ''}
          handleData={handleData}
          optionList={values?.options}
          tagType={values?.tag_type}
          valueFormat={values?.value_format}
        />

        {values?.source === Sources.MATCHING && (
          <>
            <MatchFields handleData={handleData} list={values?.match_fields?.edges} />

            <MatchRule fieldType={values?.matchFieldType} handleData={handleData} isCreateViewReadOnly={isMatchRuleReadOnly} rule={values?.match_rule} />
          </>
        )}

        {values?.source !== Sources.MATCHING && (
          <DefaultValueInput
            defaultValue={values?.default_value || ''}
            fieldType={values?.field_type || ''}
            handleData={handleData}
            options={values?.field_type === 'BOOLEAN' ? BOOLEAN_OPTIONS : values?.options || undefined}
            valueFormat={values?.value_format || undefined}
          />
        )}

        <CollisionTypeInput collisionType={values?.collision_type} handleData={handleData} source={values?.source} />

        <ResolutionStrategyInput
          collisionType={values?.collision_type}
          handleData={handleData}
          resolutionStrategyList={values?.resolution_strategy || []}
          source={values?.source}
        />

        {values?.source === Sources.INTERNAL && <FieldSemanticsInput handleData={handleData} value={values?.field_semantics} />}

        <AdditionalInfoInput handleData={handleData} value={values?.description} />

        <BooleanInputs handleData={handleData} />
      </div>

      <div className={css.modalButtonRow}>
        <WithTooltip content={disabledMessage}>
          <Button
            disableElevation
            disabled={isDisabled}
            onClick={() => openModal({ content: { dataPointName: values?.name, values }, modalOption: ModalOptions.REVIEW_NEW_FIELD })}
            variant="contained"
          >
            Review New Field
          </Button>
        </WithTooltip>
      </div>
    </>
  )
}

const validateInputs = (values?: any, setIsDisabled?: any, setDisabledMessage?: any) => {
  const errors: any[] = []

  if (!values?.name) {
    errors.push(`Name`)
  }
  if (!values?.group) {
    errors.push(`Group`)
  }
  if (!values?.source) {
    errors.push(`Source`)
  }

  if (values?.tag_type !== 'option_level' && values?.source === Sources.INTERNAL && !values?.neutral_tag) {
    errors.push('Internal sources require an automation tag')
  }

  if (values?.tag_type === 'option_level' && values?.source === Sources.INTERNAL) {
    const missingTags =
      values?.options?.filter((item: any) => {
        if (!item?.neutral_tag) {
          return true
        } else {
          return false
        }
      }) || []
    if (missingTags.length > 0) {
      errors.push('Each option requires an automation tag')
    }
  }
  if (!values?.field_type) {
    errors.push(`Field Type`)
  }

  if (values?.source === Sources.INTERNAL && isEmpty(values?.extraction_field_type)) {
    errors.push('Internal sources require an extraction type')
  }

  if (values?.source === Sources.INTERNAL && !values?.collision_type) {
    errors.push('Internal sources require a collision type')
  }

  if ((values?.field_type === 'DROP_DOWN' || values?.field_type === 'MULTI_SELECT_DROP_DOWN') && (!values?.options?.length || values?.options?.length < 1)) {
    errors.push(`Dropdown and Multi-Dropdowns must have at least one option`)
  }
  if (values?.field_type === 'DATE' && !values?.value_format) {
    errors.push(`Value Format is required when Field Type is DATE`)
  }

  if (values?.source === Sources.MATCHING) {
    if (!values?.match_rule) errors.push('Match Rule')

    if (size(values?.match_children_fields) < 2) errors.push('Match Fields (minimum two)')
    else if (
      [
        DataTableMatchRules.TABLE_MATCHING,
        DataTableMatchRules.TABLE_MATCHING_ALPHA,
        DataTableMatchRules.TABLE_MATCHING_BETA,
        DataTableMatchRules.TABLE_MATCHING_GAMMA,
        DataTableMatchRules.TABLE_MATCHING_O1_2024_12_17,
        DataTableMatchRules.TABLE_MATCHING_SONNET_V2_OCT_2024
      ].includes(values?.match_rule) &&
      size(values?.match_children_fields) > 2
    ) {
      errors.push('Match Fields (maximum two for “Table Matching” match rule)')
    }
  }

  if (values?.collision_type === CollisionTypes.CASCADE && isEmpty(values?.resolution_strategy)) {
    errors.push('Cascade collision types require a resolution strategy')
  }

  // no duplicate options
  if (values?.options?.length) {
    const formattedValues =
      values?.options?.map((item: any) => {
        return item?.option
      }) || []

    const duplicateTags =
      formattedValues.filter((item?: any) => {
        if (!item || item === '@@_DELETE_THIS_VALUE') {
          return false
        }

        // should be one match for the item to match itself, but if length is more than one there is a duplicate in the newValue array
        const duplicates = formattedValues?.filter((newValue?: any) => {
          if (item === newValue) {
            return true
          } else {
            return false
          }
        })
        if (duplicates?.length > 1) {
          return true
        } else {
          return false
        }
      }) || []
    if (duplicateTags.length > 0) {
      errors.push(`Options must be unique`)
    }
  }

  if (errors.length > 0) {
    setIsDisabled(true)

    setDisabledMessage(
      <Box sx={{ p: 1 }}>
        <Typography sx={{ fontSize: 14 }}>Missing required values:</Typography>

        <List sx={{ pb: 0 }}>
          {errors.map((error: string) => (
            <ListItem key={error} sx={{ alignItems: 'baseline', gap: 1, py: 0.5 }}>
              <CircleIcon sx={{ bottom: 1, fontSize: 6, position: 'relative' }} />

              <Typography sx={{ fontSize: 14 }}>{error}</Typography>
            </ListItem>
          ))}
        </List>
      </Box>
    )
  } else {
    setIsDisabled(false)
    setDisabledMessage(undefined)
  }
}

import { Box, Button, Checkbox, ToggleButton, Tooltip, Typography } from '@mui/material'
import { DataPoint } from '../../graphql/codegen/schemas'
import { EditDataPointUserNeedsReviewMutation } from '../../graphql/codegen/operations'
import { FetchResult, MutationResult } from '@apollo/client'
import { keyframes } from '@emotion/react'
import { useIsKlarityEmployee } from '../../hooks/useIsKlarityEmployee'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, { FC, useCallback, useRef, useState } from 'react'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

// types

type _UserReviewFilterProps = {
  checkForCorrectnessFlaggedCount: number
  expandAll: () => void
  hasUserReviewFields: boolean
  isDisabled: boolean
  setValue: (value: boolean) => void
  value: boolean
}

type _UserReviewNotificationProps = {
  dataPoint: DataPoint
  mutation: (options?: any) => Promise<FetchResult>
  mutationState: MutationResult<EditDataPointUserNeedsReviewMutation>
}

// animations

const successFlash = keyframes`
  0% { opacity: 0; transform: scale(1); }
  50% { opacity: 0.3; transform: scale(1.05); }
  100% { opacity: 0; transform: scale(1); }
`

const checkboxPop = keyframes`
  0% { transform: scale(1); }
  30% { transform: scale(1.3); }
  60% { transform: scale(0.8); }
  100% { transform: scale(1); }
`

// ADD: Create a helper for confetti-like particles
function createParticle(container: HTMLDivElement, x: number, y: number, angle: number): void {
  const particle = document.createElement('div')

  // A few vibrant colors
  const colors = [
    '#22c55e', // green
    '#10b981', // emerald
    '#06b6d4', // cyan
    '#3b82f6', // blue
    '#6366f1', // indigo
    '#8b5cf6' // violet
  ]
  const randomColor = colors[Math.floor(Math.random() * colors.length)]
  particle.className = 'confetti-particle'
  particle.style.position = 'absolute'
  particle.style.zIndex = '9999'
  particle.style.left = `${x}px`
  particle.style.top = `${y}px`
  particle.style.backgroundColor = randomColor
  particle.style.borderRadius = '9999px'

  const size = 2 + Math.random() * 5
  particle.style.width = `${size}px`
  particle.style.height = `${size}px`

  const distance = 60 + Math.random() * 80
  const duration = 600 + Math.random() * 300
  const scale = 1 + Math.random() * 0.9

  particle.animate(
    [
      { transform: 'scale(1) translate(0, 0)', opacity: 1 },
      {
        transform: `scale(${scale}) translate(${Math.cos(angle) * distance}px, ${Math.sin(angle) * distance}px)`,
        opacity: 0
      }
    ],
    {
      duration,
      easing: 'cubic-bezier(0.4, 0, 0.2, 1)'
    }
  )

  setTimeout(() => particle.remove(), duration)
  container.appendChild(particle)
}

// components

export const UserReviewFilter: FC<_UserReviewFilterProps> = ({
  checkForCorrectnessFlaggedCount,
  expandAll,
  hasUserReviewFields,
  isDisabled,
  setValue,
  value
}) => {
  const handleChange = useCallback(() => {
    expandAll()
    setValue(!value)
  }, [expandAll, setValue, value])

  if (!hasUserReviewFields) return null

  return (
    <ToggleButton
      aria-label="Fields needing check for correctness"
      disabled={isDisabled}
      onChange={handleChange}
      selected={value}
      size="small"
      sx={{ pl: 0.5, pr: 0.75 }}
      value={value}
    >
      <Tooltip arrow placement="top" title="Fields needing check for correctness">
        <Box sx={{ alignItems: 'center', display: 'flex', gap: 0.25 }}>
          <WarningAmberIcon color="warning" sx={{ height: 20 }} />

          <>{checkForCorrectnessFlaggedCount}</>
        </Box>
      </Tooltip>
    </ToggleButton>
  )
}

export const UserReviewNotification: FC<_UserReviewNotificationProps> = ({
  dataPoint: { check_for_correctness_details, check_for_correctness_verified, check_for_correctness_verified_at, check_for_correctness_verified_by, id },
  mutation,
  mutationState: { loading: isLoading }
}) => {
  const isKlarityEmployee = useIsKlarityEmployee()
  const containerRef = useRef<HTMLDivElement>(null)
  const [isCompleting, setIsCompleting] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const checkboxRef = useRef<HTMLInputElement>(null)

  const isMarkedAsReviewed = check_for_correctness_verified || isLoading

  const markAsReviewed = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked
    setIsCompleting(true)
    await mutation({ variables: { dataPointId: id, checkForCorrectnessVerified: newValue } })

    // trigger confetti only when marking as reviewed for the first time
    if (newValue && !check_for_correctness_verified && containerRef.current && checkboxRef.current) {
      const checkboxRect = checkboxRef.current.getBoundingClientRect()
      const containerRect = containerRef.current.getBoundingClientRect()
      const centerX = checkboxRect.left - containerRect.left + checkboxRect.width / 2
      const centerY = checkboxRect.top - containerRect.top + checkboxRect.height / 2

      const particleCount = 24
      for (let i = 0; i < particleCount; i++) {
        const angle = (i * 2 * Math.PI) / particleCount
        createParticle(containerRef.current, centerX, centerY, angle)
      }
      // add extra random bursts
      for (let i = 0; i < 16; i++) {
        const angle = Math.random() * 2 * Math.PI
        createParticle(containerRef.current, centerX, centerY, angle)
      }
    }

    setTimeout(() => setIsCompleting(false), 1000)
  }

  const toggleExpand = () => setIsExpanded(!isExpanded)

  const uniqueReasons = Array.from(new Set(check_for_correctness_details?.edges?.map(edge => edge?.node?.actionable_reason) ?? []))

  return (
    <Box
      ref={containerRef}
      sx={{
        bgcolor: isMarkedAsReviewed ? 'success.lighter' : 'warning.lighter',
        border: isMarkedAsReviewed ? `2px solid rgba(34, 197, 94, 0.2)` : `2px solid rgba(255, 122, 0, 0.2)`,
        borderRadius: 2,
        mb: 1,
        overflow: 'hidden',
        position: 'relative',
        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bgcolor: 'success.main',
          opacity: 0,
          borderRadius: 'inherit',
          animation: isCompleting ? `${successFlash} 1s ease-in-out` : 'none',
          pointerEvents: 'none'
        }}
      />

      <Box display="flex" gap={0.75} p={1}>
        {isMarkedAsReviewed ? (
          <CheckCircleIcon
            color="success"
            sx={{
              animation: isCompleting ? `${checkboxPop} 0.5s ease-in-out` : 'none'
            }}
          />
        ) : (
          <WarningAmberIcon color="warning" />
        )}

        <Box flex={1}>
          <Typography color={isMarkedAsReviewed ? 'success.main' : 'text.primary'} fontSize={15} fontWeight="medium">
            {isMarkedAsReviewed ? 'Reviewed for Correctness' : 'Check for Correctness:'}
          </Typography>

          <Box
            sx={{
              mt: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: 0.5
            }}
          >
            {uniqueReasons.map((reason, index) => (
              <Typography
                alignItems="center"
                display="flex"
                fontSize={12}
                key={index}
                sx={{
                  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                  opacity: isExpanded || index === 0 ? 1 : 0,
                  maxHeight: isExpanded || index === 0 ? '100px' : '0px',
                  visibility: isExpanded || index === 0 ? 'visible' : 'hidden',
                  transform: isExpanded || index === 0 ? 'translateY(0)' : 'translateY(-8px)',
                  overflow: 'hidden'
                }}
              >
                <span style={{ marginRight: 8 }}>{isMarkedAsReviewed ? '✓' : '•'}</span>
                {isExpanded || (reason && reason.length <= 40) ? (
                  reason
                ) : (
                  <Box component="span" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {`${reason?.slice(0, 40)}...`}
                    <Button
                      endIcon={<ExpandMoreIcon sx={{ fontSize: 16 }} />}
                      onClick={toggleExpand}
                      sx={{
                        minWidth: 0,
                        p: 0,
                        fontSize: 12,
                        color: isMarkedAsReviewed ? 'success.main' : 'warning.main',
                        '&:hover': {
                          bgcolor: 'transparent',
                          color: isMarkedAsReviewed ? 'success.dark' : 'warning.dark'
                        }
                      }}
                    >
                      See more
                    </Button>
                  </Box>
                )}
              </Typography>
            ))}

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                opacity: isExpanded ? 1 : 0,
                maxHeight: isExpanded ? '40px' : '0px',
                transform: isExpanded ? 'translateY(0)' : 'translateY(-8px)',
                overflow: 'hidden',
                mt: isExpanded ? 1 : 0
              }}
            >
              {isExpanded && (
                <Button
                  endIcon={<ExpandLessIcon sx={{ fontSize: 16 }} />}
                  onClick={toggleExpand}
                  sx={{
                    color: isMarkedAsReviewed ? 'success.main' : 'warning.main',
                    '&:hover': {
                      bgcolor: 'transparent',
                      color: isMarkedAsReviewed ? 'success.dark' : 'warning.dark'
                    }
                  }}
                >
                  See less
                </Button>
              )}
            </Box>
          </Box>

          {check_for_correctness_verified && check_for_correctness_verified_by && (
            <Typography fontSize={10}>
              {isKlarityEmployee ? (
                'by Klarity'
              ) : (
                <>
                  {'by'} {check_for_correctness_verified_by.first_name} {check_for_correctness_verified_by.last_name}
                </>
              )}
              {', '}
              {'on '}
              {new Intl.DateTimeFormat(undefined, {
                dateStyle: 'medium',
                timeStyle: 'short'
              }).format(new Date(check_for_correctness_verified_at + 'Z'))}
            </Typography>
          )}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'flex-start', pt: 0.5 }}>
          <Tooltip
            PopperProps={{
              sx: { '.MuiTooltip-tooltip': { visibility: isLoading ? 'hidden' : 'visible' } }
            }}
            arrow
            placement="top"
            title="Mark as reviewed"
          >
            <Checkbox
              checked={isMarkedAsReviewed}
              color={isMarkedAsReviewed ? 'success' : 'warning'}
              disabled={isLoading}
              inputProps={{ 'aria-label': 'Mark as reviewed' }}
              inputRef={checkboxRef}
              onChange={markAsReviewed}
              size="small"
              sx={{
                p: 0,
                '&.Mui-checked': {
                  animation: `${checkboxPop} 0.5s ease-in-out`
                }
              }}
            />
          </Tooltip>
        </Box>
      </Box>
    </Box>
  )
}

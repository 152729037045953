import { AppBar, Box, Button, Dialog, DialogContent, IconButton, Toolbar, Tooltip, Typography } from '@mui/material'
import { Opening, useOpening } from '@hoologic/use-opening'
import { RESIZABLE_MINIMUM_HEIGHT, Resizable } from '../Resizable'
import { SlideUpTransition } from '../Transition'
import { Z_INDEX_OVERLAY } from '../../utils/styleUtils'
import { captureError } from '../../utils/sentry'
import { useFeedbackPageContext } from '../../pages/FeedbackPage'
import { useRefCallback } from '../../hooks/useRefCallback'
import CloseIcon from '@mui/icons-material/Close'
import DataGrid, { Column, TextEditor } from 'react-data-grid'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import React, { FC, useCallback, useMemo, useState } from 'react'
import css from '../DocumentPanel/DataTable/style.module.scss'

// types

type _DataTableDialogProps = { columns: Column<any>[]; handleRowHeight: ({ row }: any) => 40 | 60; name: string; opening: Opening; rows: _Row[] }
type _ExpandViewProps = { opening: Opening }
type _Row = { [k: string]: number | string; id: number }

// constants

const BIG_ROW_HEIGHT = 60
const DEFAULT_ROW_HEIGHT = 40
const MAXIMUM_TEXT_CHARS = 41

// components

export const FeedbackDataTable: FC = () => {
  const { dataPoint, feedbackDataTableOpening } = useFeedbackPageContext()
  const opening = useOpening()
  const [absoluteHeight, setAbsoluteHeight] = useState<number>(RESIZABLE_MINIMUM_HEIGHT)

  const dataTable = useMemo(() => dataPoint?.value_data_table, [dataPoint])

  const columns = useMemo<Column<any>[]>(() => {
    const formattedColumns = []

    for (const name of dataTable?.columns || []) {
      if (name) {
        const displayName = name.includes('Unnamed') ? '' : name
        const formattedName = name.toLowerCase().split(' ').join('_')

        formattedColumns.push({
          cellClass: css.cell,
          editor: TextEditor,
          headerRenderer: () => (
            <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={displayName}>
              {displayName}
            </Box>
          ),
          key: formattedName,
          name: displayName
        })
      }
    }

    return formattedColumns
  }, [dataTable])

  const rows = useMemo(() => {
    const { rows } = dataPoint?.value_data_table || {}

    if (rows) {
      try {
        return JSON.parse(rows)
      } catch (error) {
        captureError(error)

        return []
      }
    } else {
      return []
    }
  }, [dataPoint])

  const handleRowHeight = useCallback(({ row }) => (row?.item_name?.length > MAXIMUM_TEXT_CHARS ? BIG_ROW_HEIGHT : DEFAULT_ROW_HEIGHT), [])

  const name = useMemo(() => dataPoint?.data_point_field?.name || '', [dataPoint])

  return (
    <Resizable height={absoluteHeight} setHeight={setAbsoluteHeight}>
      <Box sx={{ height: absoluteHeight }}>
        <Box alignItems="center" bgcolor="#f3f4f8" display="flex" flexDirection="row-reverse" justifyContent="space-between" padding="8px 4px 4px">
          <Box display="flex" mr={0.5}>
            <Tooltip arrow title="Close table">
              <IconButton onClick={feedbackDataTableOpening.close} size="small">
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>

          {Boolean(dataTable) && (
            <Box display="flex" gap={0.5} ml={0.5}>
              <ExpandView opening={opening} />
            </Box>
          )}
        </Box>

        <DataGrid
          className="rdg-light"
          columns={columns}
          defaultColumnOptions={{ resizable: true }}
          rowHeight={handleRowHeight}
          rows={rows}
          style={{ height: absoluteHeight, paddingBottom: 59 }}
        />

        <DataTableDialog columns={columns} handleRowHeight={handleRowHeight} name={name} opening={opening} rows={rows} />
      </Box>
    </Resizable>
  )
}

const DataTableDialog: FC<_DataTableDialogProps> = ({ columns, handleRowHeight, name, opening, rows }) => (
  <Dialog
    PaperProps={{ sx: { backgroundColor: '#e7ebf0' } }}
    TransitionComponent={SlideUpTransition}
    fullScreen
    onClose={opening.close}
    open={opening.isOpen}
    sx={{ zIndex: Z_INDEX_OVERLAY }}
  >
    <AppBar sx={{ mb: 1, position: 'relative' }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ alignItems: 'center', display: 'flex', gap: 0.5 }}>
          <Typography component="h2" sx={{ mr: 2 }} variant="h4">
            {name}
          </Typography>
        </Box>

        <Box sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
          <Button color="inherit" onClick={opening.close} variant="outlined">
            Close
          </Button>
        </Box>
      </Toolbar>
    </AppBar>

    <DialogContent sx={{ p: 2.5, pt: 2 }}>
      <DataGrid
        className="rdg-light"
        columns={columns}
        defaultColumnOptions={{ resizable: true }}
        rowHeight={handleRowHeight}
        rows={rows}
        style={{ height: '100%' }}
      />
    </DialogContent>
  </Dialog>
)

const ExpandView: FC<_ExpandViewProps> = ({ opening }) => {
  const [element, refCallback] = useRefCallback()

  const handleExpandView = useCallback(() => {
    element?.blur()

    opening.open()
  }, [element, opening])

  return (
    <Tooltip arrow title="Expand view">
      <IconButton onClick={handleExpandView} ref={refCallback} size="small">
        <FullscreenIcon />
      </IconButton>
    </Tooltip>
  )
}

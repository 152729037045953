import 'react-datepicker/dist/react-datepicker.css'
import { Box } from '@mui/material'
import { useRefCallback } from '../../../hooks/useRefCallback'
import React, { FC, useEffect } from 'react'
import ReactDatePicker from 'react-datepicker'
import clsx from 'clsx'
import css from './style.module.scss'

// types

type _DateInputProps = {
  ariaLabel?: string
  displayFormat?: string | null
  id?: string
  isDisabled?: boolean
  onCalendarClose?: () => void
  onChange: (dateString: string | null) => void
  setFocused?: (arg: boolean) => void
  value: string | null
}

// constants

const MOMENT_TO_DATEFNS_MAP: { [k: string]: string } = {
  'MMMM/DD/YYYY': 'MMMM/dd/yyyy',
  'MMMM DD, YYYY': 'MMMM dd, yyyy',
  'MM/DD/YYYY': 'MM/dd/yyyy',
  'DD/MM/YYYY': 'dd/MM/yyyy',
  'YYYY-MM-DD': 'yyyy-MM-dd',
  'DD MMMM, YYYY': 'dd MMMM, yyyy'
}

// components

const DateInput: FC<_DateInputProps> = ({ ariaLabel, displayFormat, id, isDisabled, onCalendarClose, onChange, setFocused, value }) => {
  const convertedFormat = (displayFormat && MOMENT_TO_DATEFNS_MAP[displayFormat]) || 'MMMM dd, yyyy'
  const [element, refCallback] = useRefCallback()

  function isValidDate(date: any) {
    // @ts-ignore
    return date instanceof Date && !isNaN(date)
  }

  // Below prevents a bad value (like []) from crashing the app.
  // Incorrect value types are not picked up by typescript because they are generated programatically from incoming data
  let dateValue = value ? new Date(value) : null
  if (!isValidDate(dateValue)) {
    dateValue = null
  }

  // ReactDatePicker does not support aria-label, so it must be set manually.
  useEffect(() => {
    element?.querySelector('input')?.setAttribute('aria-label', `${ariaLabel || 'Enter a date'} (Date format: ${convertedFormat})`)
  }, [ariaLabel, element, convertedFormat])

  return (
    <Box ref={refCallback}>
      <ReactDatePicker
        className={clsx(css.dateInput, isDisabled && css.disabled)}
        dateFormat={convertedFormat}
        disabled={isDisabled}
        id={id}
        onBlur={() => setFocused?.(false)}
        onCalendarClose={onCalendarClose}
        onChange={d => {
          if (Array.isArray(d)) {
            return console.error('No date range support')
          }
          if (d instanceof Date) {
            // Offset time by user's local time to ensure that whatever exact date value that was picked is what shows up in the input.
            const timeOffsetInMS = d.getTimezoneOffset() * 60000
            d.setTime(d.getTime() - timeOffsetInMS)

            // Store date locally as the backend does - ISO string WITHOUT time or tz
            // Append empty time because otherwise JS Date will offset by user's current time
            const isoString = d.toISOString().slice(0, 10).concat('T00:00:00')
            return onChange(isoString)
          }
          onChange(null)
        }}
        onFocus={() => setFocused?.(true)}
        placeholderText={convertedFormat}
        selected={dateValue}
      />
    </Box>
  )
}

export default DateInput

import '@mescius/spread-sheets-charts'
import '@mescius/spread-sheets-io'
import '@mescius/spread-sheets-shapes'
import '@mescius/spread-sheets/styles/gc.spread.sheets.excel2013white.css'
import 'react-resizable/css/styles.css'
import { Box, TextField } from '@mui/material'
import { Cursors } from '../../Resizable/Resizable'
import { Resizable, ResizeCallbackData } from 'react-resizable'
import { SPREADJS_LICENSE_KEY } from '../../SpreadsheetViewer/spreadJsLicenseKey'
import { SpreadSheets, Worksheet } from '@mescius/spread-sheets-react'
import { SpreadsheetErrorBoundary } from '../../SpreadsheetViewer/SpreadsheetErrorBoundary'
import { Z_INDEX_1 } from '../../../utils/styleUtils'
import { _Row } from './DataTableSpreadsheet'
import { captureError } from '../../../utils/sentry'
import { dropRightWhile, isEmpty } from 'lodash'
import { grey } from '@mui/material/colors'
import { useAppContext } from '../../../app'
import GC from '@mescius/spread-sheets'
import Loader from '../../Loader/Loader'
import React, { FC, MutableRefObject, SyntheticEvent, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'

GC.Spread.Sheets.LicenseKey = SPREADJS_LICENSE_KEY

// types

type _SpreadsheetEditorProps = {
  columns: string[]
  id: string
  isReadOnly?: boolean
  onChange?: () => void
  rows?: _Row[]
}

export type _SpreadsheetEditorRef = {
  getSpreadsheetData: () => { columns: string[]; emptyColumnHeaderIndices: number[]; isUnchanged: boolean; rows: _Row[] } | null
  getWorkbook: () => GC.Spread.Sheets.Workbook | null
  initializeSheet: (columns: string[], rows?: _Row[]) => void
}

type _ToolbarHandleProps = { cursor: Cursors; handleAxis?: unknown }

type _ToolbarProps = {
  cellInfo: { column?: number; formula?: string; row?: number; value?: string } | null
  isReadOnly?: boolean
  onValueChange?: () => void
  setToolbarHeight: (height: number) => void
  toolbarHeight: number
  workbookRef: MutableRefObject<GC.Spread.Sheets.Workbook | null>
}

// constants

const MAX_TOOLBAR_HEIGHT = 300

const MIN_TOOLBAR_HEIGHT = 54

const RESIZABLE_HANDLE_HEIGHT = 4

const STATUS_BAR_HEIGHT = 25

const WORKBOOK_OPTION_OVERRIDES = {
  allowCopyPasteExcelStyle: false,
  allowDragHeaderToMove: GC.Spread.Sheets.AllowDragHeaderToMove.both,
  allowSheetReorder: false,
  allowUserDragDrop: true,
  allowUserDragFill: false,
  allowUserDragMerge: false,
  allowUserEditFormula: false,
  newTabVisible: false,
  tabEditable: false
}

const WORKSHEET_OPTION_OVERRIDES = {
  clipBoardOptions: GC.Spread.Sheets.ClipboardPasteOptions.values,
  isProtected: true,
  protectionOptions: { allowResizeColumns: true, allowResizeRows: true }
}

// functions

const applyHeaderStyles = (sheet: GC.Spread.Sheets.Worksheet, startColumn: number, columnCount: number) => {
  const range = sheet.getRange(0, startColumn, 1, columnCount)
  const borderBottom = new GC.Spread.Sheets.LineBorder('black', GC.Spread.Sheets.LineStyle.thin)

  range.borderBottom(borderBottom)
  range.fontWeight('500')
}

// Compare current spreadsheet data with original data.
const compareSpreadsheetData = (currentColumns: string[], currentRows: _Row[], originalColumns: string[], originalRows: _Row[]): boolean => {
  if (currentColumns.length !== originalColumns.length || currentRows.length !== originalRows.length) return false

  // Compare columns.
  for (let i = 0; i < currentColumns.length; i++) {
    if (currentColumns[i] !== originalColumns[i]) return false
  }

  // Compare rows.
  for (let i = 0; i < currentRows.length; i++) {
    const currentRow = currentRows[i]
    const originalRow = originalRows[i]

    if (Object.keys(currentRow).length !== Object.keys(originalRow).length) return false

    for (const key in currentRow) {
      if (currentRow[key] !== originalRow[key]) return false
    }
  }

  return true
}

const createCustomContextMenuActions = (workbook: GC.Spread.Sheets.Workbook, onChange?: () => void) => {
  const createAction = (name: string, text: string, iconClass: string, action: (sheet: GC.Spread.Sheets.Worksheet) => void) => ({
    name,
    text,
    iconClass,
    command: () => {
      const sheet = workbook.getActiveSheet()

      if (sheet) {
        action(sheet)
        onChange?.()
      }
    }
  })

  const appendColumnsAction = createAction('appendColumns', 'Append more columns', '', sheet => {
    sheet.setColumnCount(sheet.getColumnCount() + 26)

    applyHeaderStyles(sheet, 0, sheet.getColumnCount())
  })

  const appendRowsAction = createAction('appendRows', 'Append more rows', '', sheet => sheet.setRowCount(sheet.getRowCount() + 100))

  const pasteAction = createAction('pasteValues', 'Paste', 'gc-spread-pasteValues', () => {
    workbook.commandManager().execute({
      cmd: 'paste',
      pasteOption: GC.Spread.Sheets.ClipboardPasteOptions.values,
      sheetName: workbook.getActiveSheet()!.name()
    })
  })

  return { appendColumnsAction, appendRowsAction, pasteAction }
}

// Identify columns with empty headers but non-empty cells.
const getEmptyColumnHeaderIndices = (sheet: GC.Spread.Sheets.Worksheet, columnCount: number, rowCount: number): number[] => {
  const emptyColumnHeaderIndices: number[] = []

  for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
    const headerValue = sheet.getValue(0, columnIndex)
    const hasEmptyHeader = headerValue === null || headerValue === undefined || headerValue === ''

    if (hasEmptyHeader) {
      // Check if any cell in this column has a value.
      for (let rowIndex = 1; rowIndex < rowCount; rowIndex++) {
        const cellValue = sheet.getValue(rowIndex, columnIndex)

        if (cellValue !== null && cellValue !== undefined && cellValue !== '') {
          emptyColumnHeaderIndices.push(columnIndex)

          break
        }
      }
    }
  }

  return emptyColumnHeaderIndices
}

const getNonEmptyColumnCount = (sheet: GC.Spread.Sheets.Worksheet) => {
  const totalCols = sheet.getColumnCount()
  let lastNonEmptyColumnIndex = -1

  // Check each column for any non-empty cells
  for (let columnIndex = 0; columnIndex < totalCols; columnIndex++) {
    const rowCount = sheet.getRowCount()

    for (let row = 0; row < rowCount; row++) {
      const value = sheet.getValue(row, columnIndex)

      if (value !== null && value !== undefined && value !== '') {
        lastNonEmptyColumnIndex = columnIndex

        break
      }
    }
  }

  return lastNonEmptyColumnIndex + 1
}

// Get all row data – only include cells from columns with valid headers.
const getRowData = (sheet: GC.Spread.Sheets.Worksheet, rowCount: number, validColumnIndices: number[], currentColumns: string[]): _Row[] => {
  const currentRows: _Row[] = []

  for (let rowIndex = 1; rowIndex < rowCount; rowIndex++) {
    const rowData: _Row = {}

    validColumnIndices.forEach((columnIndex, i) => {
      const cellValue = sheet.getValue(rowIndex, columnIndex)
      const key = currentColumns[i].toLowerCase().replace(/ /g, '_')

      rowData[key] = cellValue !== null && cellValue !== undefined ? cellValue : ''
    })

    currentRows.push(rowData)
  }

  return currentRows
}

// Get spreadsheet data from the workbook.
const getSpreadsheetData = (
  workbook: GC.Spread.Sheets.Workbook | null,
  columns: string[],
  rows: _Row[],
  onError: (message: string) => void
): { columns: string[]; emptyColumnHeaderIndices: number[]; isUnchanged: boolean; rows: _Row[] } | null => {
  if (!workbook) return null

  try {
    const sheet = workbook.getActiveSheet()
    const columnCount = sheet.getColumnCount()
    const rowCount = sheet.getRowCount()

    const { currentColumns, validColumnIndices } = getValidColumns(sheet, columnCount)

    const currentRows = getRowData(sheet, rowCount, validColumnIndices, currentColumns)

    const emptyColumnHeaderIndices = getEmptyColumnHeaderIndices(sheet, columnCount, rowCount)

    // Drop empty rows from the end.
    const trimmedRows = dropRightWhile(currentRows, (row: _Row) => !Object.entries(row).some(([, value]) => value !== ''))

    const isUnchanged = emptyColumnHeaderIndices.length === 0 && compareSpreadsheetData(currentColumns, trimmedRows, columns, rows || [])

    return { columns: currentColumns, emptyColumnHeaderIndices, isUnchanged, rows: trimmedRows }
  } catch (error) {
    captureError(error)
    onError('Error reading spreadsheet data')

    return null
  }
}

// Get valid column headers – only include columns that have header values.
const getValidColumns = (sheet: GC.Spread.Sheets.Worksheet, columnCount: number): { currentColumns: string[]; validColumnIndices: number[] } => {
  const currentColumns: string[] = []
  const validColumnIndices: number[] = []

  for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
    const headerValue = sheet.getValue(0, columnIndex)

    if (headerValue !== null && headerValue !== undefined && headerValue !== '') {
      currentColumns.push(headerValue.toString())
      validColumnIndices.push(columnIndex)
    }
  }

  return { currentColumns, validColumnIndices }
}

const initializeSheet = (sheet: GC.Spread.Sheets.Worksheet, columns: string[], rows?: _Row[]) => {
  // Clear the entire sheet first to remove any previous data.
  sheet.clear(0, 0, sheet.getRowCount(), sheet.getColumnCount(), GC.Spread.Sheets.SheetArea.viewport, GC.Spread.Sheets.StorageType.data)

  // Create text-only style for all cells.
  const textStyle = new GC.Spread.Sheets.Style()
  textStyle.formatter = '@' // Force text format.
  sheet.setDefaultStyle(textStyle)

  // Always ensure at least 26 columns (A-Z), or more if needed based on data.
  const totalColumnsNeeded = Math.max(26, Math.ceil(columns.length / 26) * 26)
  sheet.setColumnCount(totalColumnsNeeded)

  // Set total rows with tiered minimums in increments of 100 based on data size.
  const dataRowCount = (rows?.length || 0) + 1 // Add 1 to account for column headers.
  const nextTier = Math.ceil(dataRowCount / 100) * 100
  const totalRowsNeeded = Math.max(100, nextTier)
  sheet.setRowCount(totalRowsNeeded)

  // Set column headers in the first row.
  if (!isEmpty(columns)) columns.forEach((columnName: string, index: number) => sheet.setValue(0, index, columnName))

  // Set row data starting from the second row (index 1) since the first row contains column headers.
  if (rows) {
    rows.forEach((row: _Row, rowIndex: number) => {
      columns.forEach((columnName: string, columnIndex: number) => {
        const key = columnName.toLowerCase().replace(/ /g, '_')

        sheet.setValue(rowIndex + 1, columnIndex, row[key])
      })
    })
  }

  // Freeze the column headers and apply styles.
  sheet.frozenRowCount(1)
  applyHeaderStyles(sheet, 0, totalColumnsNeeded)

  // Auto-adjust column width to fit content.
  for (let columnIndex = 0; columnIndex < totalColumnsNeeded; columnIndex++) {
    const currentWidth = sheet.getColumnWidth(columnIndex)
    sheet.autoFitColumn(columnIndex)
    const autoFitWidth = sheet.getColumnWidth(columnIndex)
    const newWidth = Math.min(autoFitWidth + 8, 800) // Add 8px padding and cap at 800px.

    if (newWidth < currentWidth) {
      sheet.setColumnWidth(columnIndex, currentWidth)
    } else {
      sheet.setColumnWidth(columnIndex, newWidth)
    }
  }
}

const setCellSelectionHandler = (sheet: GC.Spread.Sheets.Worksheet, setCellInfo: (info: _ToolbarProps['cellInfo']) => void) => {
  sheet.bind(GC.Spread.Sheets.Events.SelectionChanged, (_: unknown, args: GC.Spread.Sheets.ISelectionChangedEventArgs) => {
    const selection = args.newSelections[0]

    if (selection) {
      const { col: column, row } = selection
      const value = sheet.getValue(row, column)

      setCellInfo({ value: value ?? '', row, column })
    }
  })
}

const setChangeEventHandlers = (
  sheet: GC.Spread.Sheets.Worksheet,
  onChange: (() => void) | undefined,
  previousColumnCount: MutableRefObject<number>,
  isInitializing: MutableRefObject<boolean>
) => {
  if (!onChange) return

  const handleChange = () => {
    // Skip change events during initialization.
    if (isInitializing.current) return

    onChange()
  }

  sheet.bind(GC.Spread.Sheets.Events.ColumnChanging, () => {
    if (isInitializing.current) return

    previousColumnCount.current = getNonEmptyColumnCount(sheet)
  })

  sheet.bind(GC.Spread.Sheets.Events.ColumnChanged, () => {
    if (isInitializing.current) return

    const currentColumnCount = getNonEmptyColumnCount(sheet)

    if (currentColumnCount !== previousColumnCount.current) onChange()
  })

  const changeEvents = [
    GC.Spread.Sheets.Events.RangeChanged,
    GC.Spread.Sheets.Events.ClipboardPasted,
    GC.Spread.Sheets.Events.DragDropBlockCompleted,
    GC.Spread.Sheets.Events.RowChanged,
    GC.Spread.Sheets.Events.ValueChanged
  ]

  changeEvents.forEach(event => sheet.bind(event, handleChange))
}

const setCustomContextMenu = (workbook: GC.Spread.Sheets.Workbook, onChange?: () => void) => {
  const defaultMenuItems = workbook.contextMenu.menuData.filter(item => {
    if (!item.name) return false

    const isAllowedAction = ['copy', 'cut'].some(action => item.name?.startsWith(`gc.spread.${action}`))

    const isAllowedRowColumnAction =
      (item.name.includes('Row') || item.name.includes('Column')) && ['delete', 'insert'].some(action => item.name?.includes(action))

    return isAllowedAction || isAllowedRowColumnAction
  })

  const { appendColumnsAction, appendRowsAction, pasteAction } = createCustomContextMenuActions(workbook, onChange)

  const getContextMenuItems = () => {
    const filterMenuItem = (item: any, allowedActions: string[]) => {
      const isHeaderRowSelected = workbook
        .getActiveSheet()
        ?.getSelections()
        ?.some(selection => selection.row === 0 || (selection.rowCount > 1 && selection.row === 0))

      // Prevent row deletion/insertion operations on the header row.
      if (isHeaderRowSelected && (item.name?.includes('delete') || item.name?.includes('insert'))) return false

      return allowedActions.some(action => item.name?.includes(action))
    }

    return [
      ...defaultMenuItems.filter(item => filterMenuItem(item, ['copy', 'cut'])),
      { ...pasteAction, workArea: 'viewport' },
      { ...pasteAction, workArea: 'colHeader' },
      { ...pasteAction, workArea: 'rowHeader' },
      { ...pasteAction, workArea: 'cornerHeader' },
      ...defaultMenuItems.filter(item => filterMenuItem(item, ['delete', 'insert'])),
      { ...appendColumnsAction, workArea: 'colHeader' },
      { ...appendColumnsAction, workArea: 'cornerHeader' },
      { ...appendRowsAction, workArea: 'rowHeader' },
      { ...appendRowsAction, workArea: 'cornerHeader' }
    ]
  }

  workbook.contextMenu.menuData = getContextMenuItems()

  // Update menu items whenever a selection changes.
  workbook.bind(GC.Spread.Sheets.Events.SelectionChanged, () => {
    workbook.contextMenu.menuData = getContextMenuItems()
  })
}

const setWorkbookOptions = (workbook: GC.Spread.Sheets.Workbook) => {
  workbook.options = { ...workbook.options, ...WORKBOOK_OPTION_OVERRIDES, tabStripVisible: false }
}

const setWorksheetOptions = (sheet: GC.Spread.Sheets.Worksheet, isReadOnly: boolean) => {
  sheet.options = {
    ...sheet.options,
    ...WORKSHEET_OPTION_OVERRIDES,
    isProtected: isReadOnly,
    protectionOptions: { ...WORKSHEET_OPTION_OVERRIDES.protectionOptions, allowEditObjects: !isReadOnly }
  }
}

// components

export const SpreadsheetEditor = forwardRef<_SpreadsheetEditorRef, _SpreadsheetEditorProps>(({ columns, id, isReadOnly = false, onChange, rows }, ref) => {
  const { setErrorMessage } = useAppContext()

  const [cellInfo, setCellInfo] = useState<_ToolbarProps['cellInfo']>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [toolbarHeight, setToolbarHeight] = useState(MIN_TOOLBAR_HEIGHT)

  const hasInitialized = useRef(false)
  const isInitializing = useRef(false)
  const previousColumnCount = useRef(0)
  const workbookRef = useRef<GC.Spread.Sheets.Workbook | null>(null)

  const handleWorkbookInit = (workbookInstance: GC.Spread.Sheets.Workbook) => {
    workbookRef.current = workbookInstance

    const statusBarId = `status-bar-${id}`
    const statusBar = new GC.Spread.Sheets.StatusBar.StatusBar(window.document.getElementById(statusBarId)!)
    statusBar.bind(workbookInstance)

    loadDataIntoSpreadsheet()
  }

  const loadDataIntoSpreadsheet = useCallback(() => {
    if (!workbookRef.current || rows === undefined) return

    try {
      isInitializing.current = true

      const sheet = workbookRef.current.getActiveSheet()
      const activeSelection = sheet.getSelections()[0]

      initializeSheet(sheet, columns, rows)

      if (!hasInitialized.current) {
        setWorkbookOptions(workbookRef.current)

        setWorksheetOptions(sheet, isReadOnly)

        setCellSelectionHandler(sheet, setCellInfo)

        setCustomContextMenu(workbookRef.current, onChange)

        if (!isReadOnly) setChangeEventHandlers(sheet, onChange, previousColumnCount, isInitializing)

        hasInitialized.current = true
      }

      // Get the first cell value after data is loaded and set it.
      if (activeSelection) {
        const { col: column, row } = activeSelection
        const value = sheet.getValue(row, column)

        setCellInfo({ value: value?.toString() ?? '', row, column })
      }

      setIsLoading(false)

      isInitializing.current = false
    } catch (error) {
      isInitializing.current = false

      captureError(error)

      setErrorMessage('Error loading data into spreadsheet')
    }
  }, [columns, isReadOnly, onChange, rows, setErrorMessage])

  // Expose methods to parent component.
  useImperativeHandle(ref, () => ({
    getSpreadsheetData: () => getSpreadsheetData(workbookRef.current, columns, rows || [], setErrorMessage),
    getWorkbook: () => workbookRef.current,
    initializeSheet: (columns: string[], rows?: _Row[]) => {
      if (!workbookRef.current) return

      const sheet = workbookRef.current.getActiveSheet()

      initializeSheet(sheet, columns, rows)
    }
  }))

  useEffect(() => {
    if (workbookRef.current) loadDataIntoSpreadsheet()
  }, [loadDataIntoSpreadsheet])

  if (rows === undefined) return null

  return (
    <SpreadsheetErrorBoundary>
      <Box
        sx={{
          borderTop: `1px solid ${grey[300]}`,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'flex-end',
          position: 'relative',
          textAlign: 'initial'
        }}
      >
        <Toolbar
          cellInfo={cellInfo}
          isReadOnly={isReadOnly}
          onValueChange={onChange}
          setToolbarHeight={setToolbarHeight}
          toolbarHeight={toolbarHeight}
          workbookRef={workbookRef}
        />

        <Box sx={{ height: `calc(100% - ${toolbarHeight + RESIZABLE_HANDLE_HEIGHT + STATUS_BAR_HEIGHT}px)` }}>
          {isLoading && (
            <Box sx={{ background: 'white', display: 'flex', height: '-webkit-fill-available', position: 'absolute', zIndex: Z_INDEX_1 }}>
              <Loader />
            </Box>
          )}

          <SpreadSheets {...WORKBOOK_OPTION_OVERRIDES} workbookInitialized={handleWorkbookInit}>
            <Worksheet />
          </SpreadSheets>
        </Box>

        <Box
          id={`status-bar-${id}`}
          sx={{ height: STATUS_BAR_HEIGHT, '& .gc-statusbar': { background: grey[700] }, '& [title="In Ready mode"]': { opacity: 0 } }}
        />
      </Box>
    </SpreadsheetErrorBoundary>
  )
})

export const Toolbar: FC<_ToolbarProps> = ({ cellInfo, isReadOnly = false, onValueChange, setToolbarHeight, toolbarHeight, workbookRef }) => {
  const [isResizing, setIsResizing] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const cursor = toolbarHeight >= MAX_TOOLBAR_HEIGHT ? Cursors.MINIMUM : toolbarHeight <= MIN_TOOLBAR_HEIGHT ? Cursors.MAXIMUM : Cursors.BETWEEN

  const textFieldStyles = {
    height: toolbarHeight,
    p: 1,
    textarea: { boxSizing: 'border-box', height: `${toolbarHeight - 16}px !important`, overflow: 'scroll !important', p: 1 },
    '& .MuiInputBase-root': { alignItems: 'flex-start', overflow: 'hidden', p: 0 },
    '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: grey[300] } }
  }

  const handleBlur = () => {
    // Update sheet cell value when the field is blurred.
    if (workbookRef.current && cellInfo?.row !== undefined && cellInfo?.column !== undefined) {
      const sheet = workbookRef.current.getActiveSheet()

      sheet.setValue(cellInfo.row, cellInfo.column, inputValue)
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value

    setInputValue(newValue)

    // Update sheet cell value immediately without formatting.
    if (workbookRef.current && cellInfo?.row !== undefined && cellInfo?.column !== undefined) {
      const sheet = workbookRef.current.getActiveSheet()

      sheet.setValue(cellInfo.row, cellInfo.column, newValue)

      onValueChange?.()
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') event.currentTarget.blur()
  }

  const handleResize = (_: SyntheticEvent, { size }: ResizeCallbackData) => {
    setToolbarHeight(size.height)

    workbookRef.current?.refresh()
  }

  useEffect(() => {
    if (cellInfo?.value !== undefined) setInputValue(cellInfo.formula || cellInfo.value)
  }, [cellInfo])

  return (
    <Resizable
      axis="y"
      handle={<ToolbarHandle cursor={cursor} />}
      height={toolbarHeight}
      maxConstraints={[Infinity, MAX_TOOLBAR_HEIGHT]}
      minConstraints={[Infinity, MIN_TOOLBAR_HEIGHT]}
      onResize={handleResize}
      onResizeStart={() => setIsResizing(true)}
      onResizeStop={() => setIsResizing(false)}
      width={Infinity}
    >
      <Box
        sx={{
          background: 'white',
          height: toolbarHeight,
          left: 0,
          pointerEvents: isResizing ? 'none' : 'auto',
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: Z_INDEX_1
        }}
      >
        <TextField
          disabled={isReadOnly}
          fullWidth
          multiline
          onBlur={handleBlur}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          sx={textFieldStyles}
          value={inputValue}
          variant="outlined"
        />
      </Box>
    </Resizable>
  )
}

// See: https://github.com/react-grid-layout/react-resizable/issues/175#issuecomment-1101142158
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ToolbarHandle = forwardRef<HTMLDivElement, _ToolbarHandleProps>(({ cursor, handleAxis, ...rest }, ref) => (
  <Box
    ref={ref}
    sx={{ backgroundColor: grey[300], bottom: 0, cursor, height: RESIZABLE_HANDLE_HEIGHT, left: 0, position: 'sticky', width: '100%' }}
    {...rest}
  />
))

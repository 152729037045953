import { CCI_LeftPanel_Controls } from './LeftPanel_components/CCI_LeftPanel_Controls'
import { useAppContext } from '../../../../app'
import { useCciMainContext } from '../../CCI_Main'
import { useContextInit } from '../../../../hooks/useContextInit'
import CCI_LeftPanel_Content from './LeftPanel_components/CCI_LeftPanel_Content'
import CCI_LeftPanel_Footer from './CCI_LeftPanel_Footer'
import React, { Dispatch, FC, SetStateAction, createContext, useEffect, useMemo, useState } from 'react'
import css from './style.module.scss'

// types

type _CciLeftPanelContext = {
  activeKeys: any
  searchableData: any
  setActiveKeys: Dispatch<SetStateAction<any>>
  setSearchableData: Dispatch<SetStateAction<any>>
}

// context

const CciLeftPanelContext = createContext<_CciLeftPanelContext | null>(null)

// hooks

export const useCciLeftPanelContext = () => useContextInit(CciLeftPanelContext)

// components

export const CCI_LeftPanel: FC = () => {
  const { setSearchTerm } = useAppContext()
  const { setSelectedItem, tab } = useCciMainContext()
  const [activeKeys, setActiveKeys] = useState<string[]>([])
  const [searchableData, setSearchableData] = useState<any[] | null>(null)

  useEffect(() => {
    setActiveKeys([])

    setSearchTerm('')

    setSelectedItem('')
  }, [setSearchTerm, setSelectedItem, tab])

  const context = useMemo<_CciLeftPanelContext>(() => ({ activeKeys, searchableData, setActiveKeys, setSearchableData }), [activeKeys, searchableData])

  return (
    <div className={css.panel}>
      <CciLeftPanelContext.Provider value={context}>
        <CCI_LeftPanel_Controls />

        <div className={css.inner}>
          <CCI_LeftPanel_Content />
        </div>

        <CCI_LeftPanel_Footer />
      </CciLeftPanelContext.Provider>
    </div>
  )
}

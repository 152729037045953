import 'tippy.js/dist/tippy.css'
import { AccountingImpactButton } from './AccountingImpactButton'
import { AlertTriangle, ChevronRight, Info } from 'react-feather'
import { Box, Button, Tooltip } from '@mui/material'
import {
  BypassMatchingControl,
  BypassMatchingForm,
  CouldNotRunMatching,
  CouldNotRunMatchingOrMatchingOrNonMatchingDataPointIcon,
  DataPointFieldSources,
  MissingMatchingReference,
  NonMatchingData,
  ShowFieldsControl,
  getDataPointDisplayValue
} from './DataMatching'
import { CommentButton } from './CommentButton'
import { DataPointCollisionForm, DataPointCollisionFormControl, DataPointCollisionFormLoader } from '../DataPointOverlay/DataPointCollisionForm'
import { DataPointSkeleton } from '../DataPointPanel/ChecklistSkeleton'
import { DataTableMatchRules } from '../../pages/CCI/components/RightPanel/RightPanel_components/ChecklistTab/SharedInputs/MatchRule'
import { DataTableMatchingField } from './DataTableMatching'
import { ExtractionFieldTypes } from '../../pages/CCI/components/RightPanel/RightPanel_components/ChecklistTab/SharedInputs/ExtractionFieldTypeInput'
import { Features, Permissions, useHasChecklistGptAccess, useIsAnnotator, useUserAccess } from '../../hooks/useUserAccess'
import { FeedbackButton } from './FeedbackButton'
import { FetchResult, MutationResult } from '@apollo/client'
import { Link, NavLink, useHistory, useParams } from 'react-router-dom'
import { ManualReviewCheckboxes } from './ManualReviewCheckboxes'
import { Opening, useOpening } from '@hoologic/use-opening'
import { Source } from './Source'
import { Sources } from '../../pages/CCI/components/RightPanel/RightPanel_components/ChecklistTab/CreateInputs/SourceInput'
import { UserReviewNotification } from './UserReview'
import { containsSelectedAnnotation } from '../DataPointPanel/ChecklistTab'
import { countTruthyValues } from '../../utils/dataPointUtils'
import { grey, purple } from '@mui/material/colors'
import { isEmpty } from 'lodash'
import { useContextInit } from '../../hooks/useContextInit'
import {
  useDataPointDetailQuery,
  useEditDataPointAccountingImpactMutation,
  useEditDataPointManualReviewMutation,
  useEditDataPointUserNeedsReviewMutation,
  useTransitionAuditsQuery
} from '../../graphql/codegen/hooks'
import { useDocumentPageWrapperContext } from '../DocumentPageWrapper'
import { useIsKlarityEmployee } from '../../hooks/useIsKlarityEmployee'
import { useIsProductionTenant } from '../../hooks/useTenantType'
import { useIsSpecificGoogleTenantNonCalculationField } from '../../hooks/useIsSpecificGoogleTenantNonCalculationField'
import DOMPurify from 'dompurify'
import DataPointUpdateFailure from './DataPointUpdateFailure'
import InputRow from './InputRow'
import PinnedComments from '../PinnedComments'
import React, { Dispatch, FC, Fragment, SetStateAction, createContext, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import Tippy from '@tippyjs/react'
import clsx from 'clsx'
import css from './style.module.scss'
import useQString from '../../hooks/useQString'
import type { CustomState, DataPoint, DataPointEdge, Maybe } from '../../graphql/codegen/schemas'

// types

type _AutomationTagRowProps = { dataPoint: DataPoint }

type _ChildDataPointProps = {
  dataMatching?: _DataMatching
  dataPoint: DataPoint
  dataPointCollisionFormOpening?: Opening
  isFocused?: boolean
  setFocused?: Dispatch<SetStateAction<boolean>>
}

type _CollisionAlertProps = { message: string; onClick: () => void; type: 'info' | 'warning' }

export type _DataMatching = {
  index?: number
  isBypassing?: boolean
  isDataMatchingBusy: boolean
  isDisabled: boolean
  parentDataPoint: DataPoint
  setIsDataMatchingBusy: Dispatch<SetStateAction<boolean>>
  skipTooltip?: boolean
}

type _DataMatchingFieldProps = { dataPoint: DataPoint }

type _DataPointFieldContext = {
  dealIsFinalized: boolean
  isDataTable: boolean
  isMutationLoading: boolean
  isMutationSuccess: boolean
  isResolutionNeeded: boolean
}

type _DataPointFieldProps = { dataPoint: DataPoint; dealIsFinalized: boolean; focusedDataPointId?: string | null; selectedAnnotationId?: string | null }
type _DetailOverlayButtonProps = { dataPointId: string }
type _InputListProps = { dataPoints: Maybe<DataPointEdge>[] }

type _LabelAndActionsProps = {
  accountingImpactMutation: (options?: any) => Promise<FetchResult>
  accountingImpactMutationState: MutationResult
  dataPoint: DataPoint
  manualReviewMutation: (options?: any) => Promise<FetchResult>
  manualReviewMutationState: MutationResult
}

// constants

export const DATA_POINT_PRODUCT_TABLE_MATCH_LIST = ['RGF0YVBvaW50OjY0OTM5NDdkMGI1MmQxNDZjYzIyMTk4ZA==', 'RGF0YVBvaW50OjY0OGFjZGQyOTMzYTdkZjI0NzRjZmFjMA==']

// context

const DataPointFieldContext = createContext<_DataPointFieldContext | null>(null)

// hooks

const useDataPointFieldContext = () => useContextInit(DataPointFieldContext)

// functions

export const sanitizeHtml = (description?: string) => ({ __html: DOMPurify.sanitize(description || '') })

// components

export const DataPointField: FC<_DataPointFieldProps> = ({ dataPoint, dealIsFinalized, focusedDataPointId, selectedAnnotationId }) => {
  const { activeParentDataPoint, setActiveParentDataPoint } = useDocumentPageWrapperContext()
  const { unresolved: unresolvedDataPointId } = useQString()

  const [isFocused, setFocused] = useState(false)
  const isAnnotator = useIsAnnotator()
  const isDataTable = dataPoint.data_point_field?.field_type === 'DATA_TABLE'
  const isKlarityEmployee = useIsKlarityEmployee()
  const isRequiredForPushIntegration = dataPoint.data_point_field?.required_for_push_integration
  const isResolutionNeeded = dataPoint.is_collision_resolved === false
  const isShownAsMatchingDataPoint = Boolean(
    dataPoint.data_point_field?.source === DataPointFieldSources.MATCHING &&
      (dataPoint.match_result || isEmpty(dataPoint.matching_children_data_points?.edges) || dataPoint.matching_resolved_data_point)
  )

  const [accountingImpactMutation, accountingImpactMutationState] = useEditDataPointAccountingImpactMutation()
  const [manualReviewMutation, manualReviewMutationState] = useEditDataPointManualReviewMutation()
  const [userNeedsReviewMutation, userNeedsReviewMutationState] = useEditDataPointUserNeedsReviewMutation()
  const isMutationLoading = Boolean(accountingImpactMutationState?.loading || manualReviewMutationState?.loading || userNeedsReviewMutationState?.loading)
  const isMutationSuccess = Boolean(accountingImpactMutationState?.data || manualReviewMutationState?.data || userNeedsReviewMutationState?.data)

  const dataPointCollisionFormOpening = useOpening({ anchor: null, isOpen: dataPoint.id === unresolvedDataPointId })
  const isShowingDataPointCollisionFormControl =
    Boolean(dataPoint.data_point_field?.collision_type) &&
    dataPoint.data_point_field?.source === Sources.INTERNAL &&
    !dataPointCollisionFormOpening.isOpen &&
    !dealIsFinalized &&
    !isDataTable

  const isAnnotationSelected = useMemo(() => containsSelectedAnnotation(dataPoint, selectedAnnotationId), [dataPoint, selectedAnnotationId])
  const isDataTableMatchingField = useMemo(
    () => Object.values(DataTableMatchRules).includes(dataPoint.data_point_field?.match_config?.rule as DataTableMatchRules),
    [dataPoint]
  )

  const context = useMemo<_DataPointFieldContext>(
    () => ({ dealIsFinalized, isDataTable, isMutationLoading, isMutationSuccess, isResolutionNeeded }),
    [dealIsFinalized, isDataTable, isMutationLoading, isMutationSuccess, isResolutionNeeded]
  )

  const ref = useRef<HTMLLIElement>(null)

  useLayoutEffect(() => {
    if (focusedDataPointId && focusedDataPointId === dataPoint.id) {
      const timeoutId = setTimeout(() => ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 250)

      return () => clearTimeout(timeoutId)
    }
  }, [dataPoint, focusedDataPointId])

  return (
    <DataPointFieldContext.Provider value={context}>
      <li
        className={clsx(
          css.field,
          isFocused && css.focused,
          isResolutionNeeded && css.withCollision,
          isAnnotationSelected && css.isSelected,
          isRequiredForPushIntegration && css.isRequiredForPushIntegration
        )}
        id={isAnnotationSelected ? 'checklistItem' : ''}
        ref={ref}
      >
        <LabelAndActions
          accountingImpactMutation={accountingImpactMutation}
          accountingImpactMutationState={accountingImpactMutationState}
          dataPoint={dataPoint}
          manualReviewMutation={manualReviewMutation}
          manualReviewMutationState={manualReviewMutationState}
        />

        {isAnnotator && <AutomationTagRow dataPoint={dataPoint} />}

        {(isAnnotator || isKlarityEmployee) && dataPoint?.annotator_needs_review && <DataPointUpdateFailure dataPoint={dataPoint} />}

        {dataPoint.check_for_correctness_flagged && (
          <UserReviewNotification dataPoint={dataPoint} mutation={userNeedsReviewMutation} mutationState={userNeedsReviewMutationState} />
        )}

        {isShownAsMatchingDataPoint ? (
          isDataTableMatchingField ? (
            <DataTableMatchingField dataPoint={dataPoint} />
          ) : (
            <DataMatchingField dataPoint={dataPoint} />
          )
        ) : (
          <>
            <ChildDataPoint dataPoint={dataPoint} dataPointCollisionFormOpening={dataPointCollisionFormOpening} isFocused={isFocused} setFocused={setFocused} />

            {isShowingDataPointCollisionFormControl && <DataPointCollisionFormControl opening={dataPointCollisionFormOpening} />}
          </>
        )}

        {dataPoint.has_nested_children_data_points && (
          <Button
            color="primary"
            onClick={() => setActiveParentDataPoint(activeParentDataPoint?.id === dataPoint.id ? null : dataPoint)}
            size="small"
            sx={{ alignSelf: 'flex-start', fontSize: 14, fontWeight: 600, mb: -0.5, p: 1, pt: 1.5, '&:hover': { backgroundColor: 'transparent' } }}
            variant="text"
          >
            {activeParentDataPoint?.id === dataPoint.id ? 'Hide' : 'Show'} attributes
          </Button>
        )}
      </li>
    </DataPointFieldContext.Provider>
  )
}

const DataMatchingField: FC<_DataMatchingFieldProps> = ({ dataPoint }) => {
  const { dataTableMatchingOpening, isDataTableMatchingDemoEnabled, setActiveTableId } = useDocumentPageWrapperContext()
  const bypassMatchingOpening = useOpening()
  const showDataMatchingFieldsOpening = useOpening()
  const [isDataMatchingBusy, setIsDataMatchingBusy] = useState(false)

  const isDataTableMatchingDemoField = isDataTableMatchingDemoEnabled && DATA_POINT_PRODUCT_TABLE_MATCH_LIST.includes(dataPoint.id)

  const handleClick = useCallback(() => {
    dataTableMatchingOpening.toggle()

    setActiveTableId(null)
  }, [dataTableMatchingOpening, setActiveTableId])

  return dataPoint.value_bool === true ? (
    <>
      {dataPoint.matching_resolved_data_point ? (
        <ChildDataPoint
          dataMatching={{
            isDataMatchingBusy,
            isDisabled: isDataMatchingBusy || showDataMatchingFieldsOpening.isOpen,
            parentDataPoint: dataPoint,
            setIsDataMatchingBusy,
            skipTooltip: true
          }}
          dataPoint={dataPoint.matching_resolved_data_point}
        />
      ) : null}

      <ShowFieldsControl opening={showDataMatchingFieldsOpening} />

      {showDataMatchingFieldsOpening.isOpen && (
        <Box bgcolor="grey.100" borderRadius={2} p={1} pt={0}>
          {dataPoint.matching_children_data_points!.edges.map(edge =>
            edge?.node ? (
              <ChildDataPoint
                dataMatching={{
                  isDataMatchingBusy,
                  isDisabled: isDataMatchingBusy,
                  parentDataPoint: dataPoint,
                  setIsDataMatchingBusy,
                  skipTooltip: true
                }}
                dataPoint={edge.node}
                key={edge.node.id}
              />
            ) : null
          )}
        </Box>
      )}
    </>
  ) : (
    <>
      {dataPoint.value_bool === null && <CouldNotRunMatching />}

      {dataPoint.value_bool === false && <NonMatchingData />}

      {isDataTableMatchingDemoField && (
        <Box sx={{ border: '2px dashed #ccc', borderRadius: 2, mb: 1, mt: 0.5, p: 1 }}>
          <Button color="tertiary" fullWidth onClick={handleClick} size="small" sx={{ fontWeight: 600 }} variant="outlined">
            {`${dataTableMatchingOpening.isOpen ? 'Hide' : 'Show'} Matching Demo`}
          </Button>
        </Box>
      )}

      <Box bgcolor="grey.100" borderRadius={2} display="flex" flexDirection="column" mt={0.5} pb={1} pt={0.5} px={1}>
        <MissingMatchingReference dataPoint={dataPoint} />

        {!isDataTableMatchingDemoField &&
          dataPoint.matching_children_data_points!.edges.map((edge, index) =>
            edge?.node ? (
              <ChildDataPoint
                dataMatching={{
                  index: index + 1,
                  isBypassing: bypassMatchingOpening.isOpen,
                  isDataMatchingBusy,
                  isDisabled: bypassMatchingOpening.isOpen || isDataMatchingBusy,
                  parentDataPoint: dataPoint,
                  setIsDataMatchingBusy
                }}
                dataPoint={edge.node}
                key={edge.node.id}
              />
            ) : null
          )}

        <BypassMatchingControl opening={bypassMatchingOpening} />
      </Box>

      <BypassMatchingForm dataPoint={dataPoint} opening={bypassMatchingOpening} />
    </>
  )
}

const LabelAndActions = ({
  accountingImpactMutation,
  accountingImpactMutationState,
  dataPoint,
  manualReviewMutation,
  manualReviewMutationState
}: _LabelAndActionsProps) => {
  const { dealIsFinalized } = useDataPointFieldContext()
  const { dealId, documentId } = useParams<{ dealId?: string; documentId?: string }>()
  const { data_point_field } = dataPoint
  const { description, display_accounting_impact, display_comment_field, display_manual_review, extraction_field_type, internal_mapping, name, source } =
    data_point_field || {}
  const { data: transitionAuditsData } = useTransitionAuditsQuery({ variables: { dealOrDocumentId: dealId || documentId! } })

  const hasChecklistGptAccess = useHasChecklistGptAccess()
  const isAnnotator = useIsAnnotator()
  const isDataMatchingField = source === DataPointFieldSources.MATCHING
  const isInternalSource = source === DataPointFieldSources.INTERNAL
  const isProductionTenant = useIsProductionTenant()
  const isPreAnnotationExtractionType = Boolean(extraction_field_type?.includes(ExtractionFieldTypes['PRE-ANNOTATION']))
  const isShowingFeedbackButton =
    data_point_field?.id &&
    hasChecklistGptAccess &&
    isInternalSource &&
    !isProductionTenant &&
    !dealIsFinalized &&
    !internal_mapping?.includes('document_type') &&
    !isPreAnnotationExtractionType

  const states = transitionAuditsData?.transition_audit?.states
    ? (transitionAuditsData.transition_audit.states as CustomState[]).filter(state => !state.initial && !state.final)
    : null

  return (
    <div className={css.labelRow}>
      <Box className={css.label} sx={{ mt: -0.5 }}>
        <span>{name}</span>

        {description && (
          <Tippy
            className={clsx(description?.length > 500 && css.description)}
            content={<div dangerouslySetInnerHTML={sanitizeHtml(description)} />}
            delay={[150, 0]}
          >
            <Info />
          </Tippy>
        )}

        <Source {...data_point_field} />
      </Box>

      <ul className={css.actions}>
        {isDataMatchingField && <CouldNotRunMatchingOrMatchingOrNonMatchingDataPointIcon dataPoint={dataPoint} />}

        {!isAnnotator && (
          <>
            {display_manual_review && !isAnnotator && states && (
              <li>
                <ManualReviewCheckboxes
                  dataPoint={dataPoint}
                  dealIsFinalized={dealIsFinalized}
                  mutation={manualReviewMutation}
                  mutationState={manualReviewMutationState}
                  states={states}
                />
              </li>
            )}

            {display_accounting_impact && (
              <li>
                <AccountingImpactButton
                  dataPoint={dataPoint}
                  dealIsFinalized={dealIsFinalized || false}
                  mutation={accountingImpactMutation}
                  mutationState={accountingImpactMutationState}
                />
              </li>
            )}

            {display_comment_field && (
              <li>
                <CommentButton dataPoint={dataPoint} dealIsFinalized={dealIsFinalized || false} />
              </li>
            )}

            {isShowingFeedbackButton && (
              <li>
                <FeedbackButton dataPointFieldId={data_point_field.id} />
              </li>
            )}

            <DetailOverlayButton dataPointId={dataPoint.id} />
          </>
        )}
      </ul>
    </div>
  )
}

const DetailOverlayButton: FC<_DetailOverlayButtonProps> = ({ dataPointId }) => {
  const { dealId, documentId } = useParams<{ dealId?: string; documentId?: string }>()
  const history = useHistory()

  return (
    <li>
      <Tooltip arrow placement="top" title="View history">
        <Link aria-label="View history" to={`${dealId ? `/deals/${dealId}` : `/documents/${documentId}`}/checklist/${dataPointId}${history.location.search}`}>
          <ChevronRight aria-hidden className={css.chevronRight} />
        </Link>
      </Tooltip>
    </li>
  )
}

const AutomationTagRow: FC<_AutomationTagRowProps> = ({ dataPoint }) => {
  const { internal_mapping } = dataPoint.data_point_field || {}

  const tags = internal_mapping?.map((k: any) => k.split('_').join(' ')).filter(Boolean) || []

  if (isEmpty(tags)) return null

  return (
    <div style={{ padding: '0 8px', marginBottom: 16, fontSize: 12, lineHeight: 1.6 }}>
      <span style={{ color: purple.A700 }}>Automation tag{tags.length > 1 ? 's' : ''}: </span>

      <span style={{ textTransform: 'capitalize', fontWeight: 800 }}>{tags.join(', ')}</span>
    </div>
  )
}

const ChildDataPoint: FC<_ChildDataPointProps> = ({ dataMatching, dataPoint, dataPointCollisionFormOpening, isFocused, setFocused }) => {
  const { dealIsFinalized, isDataTable, isMutationLoading, isMutationSuccess, isResolutionNeeded } = useDataPointFieldContext()
  const documentDataPoints = dataPoint.document_data_points?.edges
  const hasCollisionAccess = useUserAccess({ feature: Features.COLLISION, permission: Permissions.READ })
  const history = useHistory()
  const isSpecificGoogleTenantNonCalculationField = useIsSpecificGoogleTenantNonCalculationField(dataPoint.data_point_field?.external_source as string)
  const isShowingDataPointCollisionFormControl = Boolean(dataPointCollisionFormOpening) && hasCollisionAccess
  const resolvedDataPoints = dataPoint.resolved_data_points?.edges
  const toResolveCount = countTruthyValues(dataPoint)
  const valueCount = countTruthyValues(dataPoint)

  const { data: dataTableDataPointDetailData, loading: isDataTableDataPointDetailLoading } = useDataPointDetailQuery({
    skip: !isDataTable,
    variables: { id: dataPoint.id }
  })
  const dataTableDataPointDetail = dataTableDataPointDetailData?.data_points?.edges[0]?.node as DataPoint
  const dataTableDataPointDetailHasChildren = !isEmpty(dataTableDataPointDetail?.document_data_points?.edges)

  const openDataPointCollisionForm = () => dataPointCollisionFormOpening?.open()

  if (isDataTableDataPointDetailLoading) return <DataPointSkeleton />

  return (
    <>
      {Boolean(dataMatching) && (
        <Box className={css.labelRow} mt={1} pb={0.5}>
          <Box className={css.label}>
            <Box component="span" fontSize={14}>
              {getDataPointDisplayValue(dataPoint, isSpecificGoogleTenantNonCalculationField, dataMatching!.parentDataPoint!, {
                history,
                skipTooltip: Boolean(dataMatching!.skipTooltip),
                ...(dataMatching!.isBypassing && { index: dataMatching!.index })
              })}
            </Box>
          </Box>
        </Box>
      )}

      {dealIsFinalized ? (
        <InputRow
          ariaLabel={dataPoint.data_point_field?.name}
          dataPoint={dataPoint}
          dealIsFinalized={dealIsFinalized}
          isDisabled={dataMatching?.isDisabled}
          isFocused={isFocused}
          isMutationLoading={isMutationLoading}
          isMutationSuccess={isMutationSuccess}
          setFocused={setFocused}
        />
      ) : isShowingDataPointCollisionFormControl ? (
        isResolutionNeeded ? (
          <CollisionAlert message={`${toResolveCount ? `${toResolveCount} conflicting` : 'No'} values`} onClick={openDataPointCollisionForm} type="warning" />
        ) : resolvedDataPoints?.length ? (
          !dataPointCollisionFormOpening?.isOpen && <InputList dataPoints={resolvedDataPoints} />
        ) : documentDataPoints?.length ? (
          <CollisionAlert
            message={`${valueCount ? `${valueCount}` : 'No'} value${valueCount === 1 ? '' : 's'} detected in ${documentDataPoints.length} document${
              documentDataPoints.length === 1 ? '' : 's'
            }`}
            onClick={openDataPointCollisionForm}
            type="info"
          />
        ) : (
          !dataPointCollisionFormOpening?.isOpen && (
            <InputRow
              ariaLabel={dataPoint.data_point_field?.name}
              dataMatching={dataMatching}
              dataPoint={dataPoint}
              dealIsFinalized={dealIsFinalized}
              isDisabled={dataMatching?.isDisabled}
              isFocused={isFocused}
              isMutationLoading={isMutationLoading}
              isMutationSuccess={isMutationSuccess}
              setFocused={setFocused}
            />
          )
        )
      ) : documentDataPoints?.length ? (
        <InputList dataPoints={documentDataPoints} />
      ) : (
        <InputRow
          ariaLabel={dataPoint.data_point_field?.name}
          dataPoint={dataPoint}
          dealIsFinalized={dealIsFinalized}
          isDisabled={dataMatching?.isDisabled}
          isFocused={isFocused}
          isMutationLoading={isMutationLoading}
          isMutationSuccess={isMutationSuccess}
          setFocused={setFocused}
        />
      )}

      {!dealIsFinalized &&
        (dataTableDataPointDetailHasChildren ? (
          <DataPointCollisionForm
            dataPoint={dataTableDataPointDetail}
            dealIsFinalized={dealIsFinalized}
            isSummary
            key={JSON.stringify(dataTableDataPointDetail)}
          />
        ) : dataPointCollisionFormOpening && dataPointCollisionFormOpening.isOpen ? (
          <DataPointCollisionFormLoader dataPoint={dataPoint} dealIsFinalized={dealIsFinalized} opening={dataPointCollisionFormOpening} />
        ) : null)}

      <PinnedComments dataPoint={dataPoint} dealIsFinalized={dealIsFinalized || false} />
    </>
  )
}

// if collision is resolved, show resolved data points OR a count of how many document data points there are (in case resolved is empty)
// otherwise show display_value of the deal-level data point
const InputList: FC<_InputListProps> = ({ dataPoints }) => {
  const { dealIsFinalized } = useDataPointFieldContext()
  const { documentTab } = useQString()
  const history = useHistory()
  const isAnnotator = useIsAnnotator()
  const isKlarityEmployee = useIsKlarityEmployee()

  return (
    <>
      {dataPoints.map((dp, index) => {
        if (!dp?.node) return null

        const documentLabel = `${dp.node.document?.document_type?.name}: ${dp?.node?.document?.alias || dp.node.document?.name}`
        const ariaLabel = `"${dp.node.data_point_field?.name}" from "${documentLabel}"`
        const isDataTable = dp.node.data_point_field?.field_type === 'DATA_TABLE'

        return (
          <div key={`${dp.node.id},${index}`}>
            {(isAnnotator || isKlarityEmployee) && dp?.node?.annotator_needs_review && <DataPointUpdateFailure dataPoint={dp?.node} />}

            {!isDataTable && (
              <>
                <NavLink
                  isActive={() => documentTab === dp.node?.document?.id}
                  style={{ color: grey[600], display: 'inline-block', fontSize: 10, lineHeight: 1.5, margin: '8px 8px 4px', paddingLeft: 0, textIndent: 0 }}
                  to={`${history.location.pathname}?documentTab=${dp.node.document?.id}`}
                >
                  {documentLabel}
                </NavLink>

                <InputRow ariaLabel={ariaLabel} dataPoint={dp.node} dealIsFinalized={dealIsFinalized} setFocused={() => null} />
              </>
            )}
          </div>
        )
      })}
    </>
  )
}

const CollisionAlert: FC<_CollisionAlertProps> = ({ message, onClick, type }) => (
  <Button className={clsx(css.collisionAlert, css[type])} onClick={onClick} startIcon={type === 'info' ? <Info /> : <AlertTriangle />}>
    {message}
  </Button>
)

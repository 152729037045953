import { CciChecklistDnd } from './CCI_Checklist_DND'
import { groupDataPointFields } from '../../../../../../utils/dataPointUtils'
import { sortBy } from 'lodash'
import { useAppContext } from '../../../../../../app'
import { useCciDealDataPointFieldsQuery } from '../../../../../../graphql/codegen/hooks'
import { useCciLeftPanelContext } from '../../CCI_LeftPanel'
import { useCciMainContext } from '../../../../CCI_Main'
import ChecklistSkeleton from '../../../../../../components/DataPointPanel/ChecklistSkeleton'
import React, { FC, useEffect, useMemo } from 'react'

// components

export const CciLeftPanelChecklistList: FC = () => {
  const { searchTerm } = useAppContext()
  const { setContentData, setDocumentTypesField, setLeftPanelLoading, tab } = useCciMainContext()
  const { setSearchableData } = useCciLeftPanelContext()
  const { data: checklistData, loading: isChecklistLoading } = useCciDealDataPointFieldsQuery()

  useEffect(() => {
    if (checklistData) {
      const documentTypesField = checklistData?.cci_deal_data_point_fields?.filter(field => {
        const mappingMatch =
          field?.internal_mapping?.filter((tag: any) => {
            return tag === 'document_type'
          }) || []
        return mappingMatch?.length > 0 || false
      })
      // @ts-ignore
      setDocumentTypesField((documentTypesField?.length > 0 && documentTypesField[0]) || undefined)
    } else {
      setDocumentTypesField(undefined)
    }
    // eslint-disable-next-line
  }, [checklistData])

  const groups = useMemo(() => groupDataPointFields(checklistData?.cci_deal_data_point_fields), [checklistData])

  const sortedNames = useMemo(() => groups && sortBy(Object.keys(groups), key => groups[key][0]?.group_priority), [groups])

  // this handles resetting contentData from cached values (and for initial load for this component) when switching tabs
  useEffect(() => {
    if (!tab || tab === 'checklist') {
      setContentData(sortedNames || '')

      setSearchableData(sortedNames || null)
    }
  }, [setContentData, setSearchableData, sortedNames, tab])

  useEffect(() => setLeftPanelLoading(!checklistData || isChecklistLoading), [checklistData, isChecklistLoading, setLeftPanelLoading])

  if (isChecklistLoading) return <ChecklistSkeleton />

  if (searchTerm) {
    return (
      <CciChecklistDnd
        groups={groupDataPointFields(checklistData?.cci_deal_data_point_fields?.filter(item => item?.name.toLowerCase().includes(searchTerm.toLowerCase())))}
        isReorderingEnabled={false}
        sortedNames={sortedNames}
      />
    )
  }

  return <CciChecklistDnd groups={groups} sortedNames={sortedNames} />
}

import { Box } from '@mui/material'
import React, { FC } from 'react'

// types

type _CorrectCellProps = { correctSamples: number }

// components

export const CorrectCell: FC<_CorrectCellProps> = ({ correctSamples }) => <Box>{correctSamples}</Box>

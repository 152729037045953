import {
  AiLanguageAnalysisParams,
  AiLanguageAnalysisResponse,
  AxiosParams,
  SemanticSearchResponse,
  useLazySubmitAiLanguageAnalysisQuery,
  useLazySubmitSemanticSearchQuery
} from '../../../app/restApi'
import { BaseQueryFn, QueryDefinition } from '@reduxjs/toolkit/dist/query'
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { ExportToCsv } from 'export-to-csv'
import { FieldInputProps, FormikErrors, useFormik } from 'formik'
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { Link } from 'react-router-dom'
import { ReactComponent as StartSearchingImage } from '../../../assets/start-searching.svg'
import { Z_INDEX_HEADER } from '../../../utils/styleUtils'
import { common, deepPurple, grey, red } from '@mui/material/colors'
import { isEmpty, size } from 'lodash'
import { useContextInit } from '../../../hooks/useContextInit'
import { useDocumentTypesQuery, useSemanticSearchFieldsQuery } from '../../../graphql/codegen/hooks'
import { useDrag } from '@use-gesture/react'
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver'
import { useRefCallback } from '../../../hooks/useRefCallback'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import CloseIcon from '@mui/icons-material/Close'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import KlarityCard from '../../../components/Card'
import Markdown from 'react-markdown'
import React, {
  Dispatch,
  FC,
  FormEvent,
  RefObject,
  SetStateAction,
  createContext,
  forwardRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import SendIcon from '@mui/icons-material/Send'
import remarkGfm from 'remark-gfm'
import useCurrentUser from '../../../hooks/useCurrentUser'
import useWindowSize from '../../../utils/useWindowSize'

// types

type _AnalysisFormProps = {
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>
  handleSubmit: (event?: FormEvent<HTMLFormElement> | undefined) => void
  values: _AnalysisFormValues
}

type _AnalysisFormValues = { query: string }

type _AnalysisInitialViewProps = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void> | Promise<FormikErrors<_AnalysisFormValues>>
  submitForm: () => Promise<any>
}

type _AnalysisMarkdownProps = { markdown: string }
type _AnalysisOtherProps = { query?: string; type: ResultTypes }
type _AnalysisResult = { markdown?: string; query?: string; type: ResultTypes }

type _Context = {
  analysisPaneColumns: number
  analysisResults: _AnalysisResult[]
  cardContentRef: RefObject<HTMLDivElement>
  checklistFields: string[]
  documentTypes: string[]
  gridContainerRef: RefObject<HTMLDivElement>
  isAnalysisFetching: boolean
  isAnalysisPaneOpen: boolean
  isSearchFetching: boolean
  semanticSearchQuery: string
  setAnalysisPaneColumns: Dispatch<SetStateAction<number>>
  setAnalysisResults: Dispatch<SetStateAction<_AnalysisResult[]>>
  setIsAnalysisPaneOpen: Dispatch<SetStateAction<boolean>>
  submitAnalysis: LazyQueryTrigger<
    QueryDefinition<AiLanguageAnalysisParams, BaseQueryFn<AxiosParams, unknown, unknown>, string, AiLanguageAnalysisResponse, 'restApi'>
  >
}

type _MultiSelectFilterProps = {
  fieldProps: FieldInputProps<any>
  id: string
  isDisabled?: boolean
  isLoading: boolean
  isNoneAllowed?: boolean
  items: string[]
  label: string
  selectedItems: string[]
  setFieldValue: (field: string, value: string[]) => void
}

type _ParsedQueryResponse = {
  counterparty: string
  created_at: string
  document_id: string
  document_name: string
  document_type: string
  low_confidence: boolean
  match_score: number
  match_text: string
}[]

enum ResultTypes {
  EMPTY,
  ERROR,
  KLARITY,
  LOADING,
  MARKDOWN,
  NEW_ASK,
  QUERY_TEXT,
  YOU
}

type _SearchFormValues = { checklistFields: string[]; documentTypes: string[]; query: string; sortBy: SortOptions }

enum SortOptions {
  CREATED_AT = 'created_at',
  DOCUMENT_NAME = 'document_name',
  RELEVANCE = 'relevance'
}

// constants

const GRID_COLUMNS = 24
const MENU_ITEM_SX = { pl: 0.5, py: 0 }
const MINIMUM_ANALYSIS_PANE_COLUMNS = 10
const MINIMUM_RESULTS_PANE_COLUMNS = 7
const MIN_HEIGHT = 'calc(100vh - 272px)'
const PINNED_SEARCH_BAR_OFFSET = 104

const QUERIES = [
  'Summarize the contract language variations',
  'Generate a standard version of the contract language taking into account all the variations',
  'Generate a report summarizing the types and implications of non-standard terms',
  'Provide a glossary of non-standard terms identified, including context and standard equivalents',
  'Create a guide for readers to understand the significance of contract language variation and standardization efforts'
]

const RESULT_TYPE_SX = {
  [ResultTypes.EMPTY]: { bgcolor: grey[100], borderRadius: 2, borderTopLeftRadius: 0, fontSize: 14, my: -2, px: 1.5, py: 0.75 },
  [ResultTypes.ERROR]: { bgcolor: red[50], borderRadius: 2, borderTopLeftRadius: 0, fontSize: 14, my: -2, px: 1.5, py: 0.75 },
  [ResultTypes.KLARITY]: { fontSize: 14, fontWeight: 600, mb: 0.5 },
  [ResultTypes.LOADING]: { bgcolor: grey[100], borderRadius: 2, borderTopLeftRadius: 0, mt: -2, pb: 0.875, pl: 2, pt: 0.25, width: 58 },
  [ResultTypes.MARKDOWN]: {
    bgcolor: grey[100],
    borderRadius: 2,
    borderTopLeftRadius: 0,
    color: grey[800],
    fontSize: 14,
    my: -2,
    pb: 2,
    pt: 1.5,
    px: 1.5,
    li: { listStyle: 'unset' },
    'h1, h2, h3, h4, h5': { fontSize: 14, mb: 1, mt: 2, ':first-of-type': { mt: 0 }, ':last-child': { mb: 0 } },
    'li, ol, p, table, ul': { fontSize: 14, my: 1, ':first-of-type': { mt: 0 }, ':last-child': { mb: 0 } },
    'ol, ul': { ml: 2 },
    table: { borderSpacing: 0 },
    'table, td, th': { border: `0.5px solid ${grey[400]}` },
    'td, th': { p: 0.75, pt: 0.5, textAlign: 'left', verticalAlign: 'top' }
  },
  [ResultTypes.NEW_ASK]: { color: grey[500], fontSize: 12, mb: 0.5, mt: 2, '& + .you': { mt: -1 } },
  [ResultTypes.QUERY_TEXT]: { bgcolor: deepPurple[50], borderRadius: 2, borderTopLeftRadius: 0, color: grey[800], fontSize: 14, px: 1.5, py: 0.75 },
  [ResultTypes.YOU]: { fontSize: 14, fontWeight: 600, mb: -1.5, mt: 3, '&:first-of-type': { mt: -0.5 } }
}

const SCROLL_AMOUNT = 250
const SELECT_ALL = '_SELECT_ALL'

const SORT_OPTION_LABELS: Record<SortOptions, string> = {
  [SortOptions.RELEVANCE]: 'Relevance',
  [SortOptions.CREATED_AT]: 'Recently uploaded',
  [SortOptions.DOCUMENT_NAME]: 'Document name'
}

// functions

const parseQueryResponse = (searchResults: SemanticSearchResponse | undefined): _ParsedQueryResponse =>
  searchResults?.map(({ match_score, match_text, meta_data: { counterparty, created_at, document_id, document_name, document_type, low_confidence } }) => ({
    counterparty,
    created_at,
    document_id,
    document_name,
    document_type,
    low_confidence,
    match_score,
    match_text
  })) || []

// context

const Context = createContext<_Context | null>(null)

// hooks

const useLocalContext = () => useContextInit(Context)

// components

const AnalysisForm = forwardRef<HTMLDivElement, _AnalysisFormProps>(({ getFieldProps, handleSubmit, values }, ref) => {
  const { isAnalysisFetching, isSearchFetching } = useLocalContext()

  const isSubmitDisabled = useMemo(() => isAnalysisFetching || isSearchFetching || !values.query, [isAnalysisFetching, isSearchFetching, values.query])

  return (
    <FormControl autoComplete="off" component="form" onSubmit={handleSubmit}>
      <Box ref={ref} sx={{ alignItems: 'baseline', borderTop: `1px solid ${grey[300]}`, display: 'flex', gap: 1.5, justifyContent: 'space-between', p: 1.5 }}>
        <TextField
          InputProps={{ sx: { fontSize: 14 } }}
          autoFocus
          disabled={isAnalysisFetching || isSearchFetching}
          fullWidth
          placeholder="Ask something related to the search results"
          size="small"
          sx={{
            '& .MuiInput-underline:after, & .MuiInput-underline:before, & .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInput-underline.Mui-disabled:before':
              { borderBottom: 'none' }
          }}
          variant="standard"
          {...getFieldProps('query')}
        />

        <IconButton
          aria-label="Send"
          disabled={isSubmitDisabled}
          sx={{ '&, &:hover, &.Mui-disabled': { bgcolor: isSubmitDisabled ? grey[300] : 'primary.main', color: common.white } }}
          type="submit"
        >
          <SendIcon sx={{ fontSize: 14, left: 1, position: 'relative' }} />
        </IconButton>
      </Box>
    </FormControl>
  )
})

const AnalysisInitialView: FC<_AnalysisInitialViewProps> = ({ setFieldValue, submitForm }) => {
  const { isSearchFetching } = useLocalContext()

  const handleClick = (query: string) => {
    setFieldValue('query', query)

    submitForm()
  }

  return (
    <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', gap: 2, height: '100%', justifyContent: 'center' }}>
      <AutoAwesomeIcon sx={{ color: deepPurple[400], fontSize: 36 }} />

      <Typography sx={{ color: grey[600], fontSize: 14, textAlign: 'center', width: '50%' }}>
        Choose a prompt suggestion below or write your own to start analyzing the search results.
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '75%' }}>
        {QUERIES.map((query, index) => (
          <Button
            disabled={isSearchFetching}
            fullWidth
            key={index}
            onClick={() => handleClick(query)}
            size="small"
            sx={{
              bgcolor: deepPurple[50],
              borderRadius: 1,
              color: deepPurple[700],
              justifyContent: 'flex-start',
              px: 1,
              textAlign: 'left',
              textTransform: 'none'
            }}
          >
            {query}
          </Button>
        ))}
      </Box>
    </Box>
  )
}

const AnalysisMarkdown: FC<_AnalysisMarkdownProps> = ({ markdown }) => {
  const { analysisPaneColumns } = useLocalContext()
  const dynamicSxRef = useRef<{}>()
  const [element, refCallback] = useRefCallback()
  const [calculate, setCalculate] = useState(false)
  const windowSize = useWindowSize()

  useMemo(() => {
    // step one – clear `minHeight` and `overflowX` and trigger `useLayoutEffect` (via `calculate`);
    // disabling `exhaustive-deps` to prevent infinite re-renders (due to `calculate`)

    if (!calculate) {
      dynamicSxRef.current = undefined

      setCalculate(true)
    }
  }, [analysisPaneColumns, windowSize]) // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    // step two – post-render, set `minHeight` and `overflowX` and re-render

    if (calculate && element) {
      dynamicSxRef.current = { minHeight: `${element.clientHeight}px`, overflowX: 'auto' }

      setCalculate(false)
    }
  }, [calculate, element])

  return (
    <Box ref={refCallback} sx={{ ...RESULT_TYPE_SX[ResultTypes.MARKDOWN], ...dynamicSxRef.current }}>
      <Markdown remarkPlugins={[remarkGfm]}>{markdown}</Markdown>
    </Box>
  )
}

const AnalysisOther: FC<_AnalysisOtherProps> = ({ query, type }) => (
  <Typography sx={RESULT_TYPE_SX[type]}>
    {(() => {
      switch (type) {
        case ResultTypes.EMPTY:
          return 'No analysis results found.'
        case ResultTypes.ERROR:
          return 'Error, please try again.'
        case ResultTypes.KLARITY:
          return (
            <>
              <AutoAwesomeIcon sx={{ color: deepPurple[500], fontSize: 18, position: 'relative', mr: 0.75, top: 1 }} />

              <>Klarity AI’s Response</>
            </>
          )
        case ResultTypes.LOADING:
          return <img alt="Loading…" height="12" src={`${process.env.PUBLIC_URL}/loading.gif`} />
        case ResultTypes.NEW_ASK:
          return <Divider component="span">New ask</Divider>
        case ResultTypes.QUERY_TEXT:
          return query
        case ResultTypes.YOU:
          return 'You'
      }
    })()}
  </Typography>
)

const AnalysisPane = forwardRef<HTMLDivElement, {}>((_, ref) => {
  const formik = useFormik<_AnalysisFormValues>({
    initialValues: { query: '' },
    onSubmit: values => {
      setAnalysisResults(current => [
        ...current,
        { type: ResultTypes.YOU },
        { query: values.query, type: ResultTypes.QUERY_TEXT },
        { type: ResultTypes.KLARITY },
        { type: ResultTypes.LOADING }
      ])

      setFieldValue('query', '')

      submitAnalysis({
        ...values,
        checklist_fields: checklistFields.filter(checklistField => checklistField !== SELECT_ALL),
        document_types: documentTypes.filter(documentType => documentType !== SELECT_ALL),
        semantic_search_query: semanticSearchQuery
      })

      setTimeout(() => {
        if (cardContentRef.current) {
          cardContentRef.current.scrollTop = cardContentRef.current.scrollHeight
        }
      }, 100)
    }
  })

  const {
    analysisPaneColumns,
    analysisResults,
    cardContentRef,
    checklistFields,
    documentTypes,
    isAnalysisPaneOpen,
    semanticSearchQuery,
    setAnalysisResults,
    submitAnalysis
  } = useLocalContext()

  const formBoxRef = useRef<HTMLDivElement>(null)
  const paneRef = useRef<HTMLDivElement>(null)
  const titleBoxRef = useRef<HTMLDivElement>(null)

  const [cardContentHeight, setCardContentHeight] = useState(0)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [paneHeight, setPaneHeight] = useState(0)

  // set headerHeight, initial cardContentHeight, initial paneHeight
  useEffect(() => {
    const main = document.getElementById('main')
    const nav = document.getElementById('nav')
    const tabs = document.getElementById('tabs')
    const tabBody = document.getElementById('tab-body')

    if (main && nav && tabBody?.firstElementChild && tabs) {
      const navHeight = nav.getBoundingClientRect().height
      const mainPaddingTop = parseInt(window.getComputedStyle(main).paddingTop, 10)
      const tabsHeight = tabs.getBoundingClientRect().height
      const tabBodyFirsChildMarginTop = parseInt(window.getComputedStyle(tabBody.firstElementChild).marginTop, 10)

      const paneHeight = window.innerHeight - navHeight - mainPaddingTop - tabsHeight - tabBodyFirsChildMarginTop - PINNED_SEARCH_BAR_OFFSET - 32

      if (formBoxRef.current && titleBoxRef.current) {
        setCardContentHeight(paneHeight - titleBoxRef.current.offsetHeight - formBoxRef.current.offsetHeight)
      }

      setHeaderHeight(navHeight + mainPaddingTop + tabsHeight + tabBodyFirsChildMarginTop)
      setPaneHeight(paneHeight)
    }
  }, [headerHeight])

  // update cardContentHeight, paneHeight based on scroll
  useEffect(() => {
    if (!headerHeight || !paneRef.current) return

    const initialPaneHeight = paneRef.current.offsetHeight
    let ticking = false

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const paneHeight =
            headerHeight > window.scrollY ? initialPaneHeight + (headerHeight * window.scrollY) / headerHeight : initialPaneHeight + headerHeight

          if (formBoxRef.current && titleBoxRef.current) {
            setCardContentHeight(paneHeight - titleBoxRef.current!.offsetHeight - formBoxRef.current!.offsetHeight)
          }

          setPaneHeight(paneHeight)

          ticking = false
        })

        ticking = true
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [headerHeight])

  if (!isAnalysisPaneOpen) return null

  const { getFieldProps, handleSubmit, setFieldValue, submitForm, values } = formik

  return (
    <Grid item xs={analysisPaneColumns}>
      <Card
        ref={paneRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: paneHeight,
          justifyContent: 'space-between',
          my: 2,
          overflow: 'visible',
          position: 'sticky',
          top: PINNED_SEARCH_BAR_OFFSET + 16
        }}
      >
        <ColumnResizeWidget />

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <AnalysisTitle ref={titleBoxRef} />

          <CardContent
            ref={ref}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: cardContentHeight, overflowY: 'auto', ':last-child': { pb: 2 } }}
          >
            {isEmpty(analysisResults) ? (
              <AnalysisInitialView setFieldValue={setFieldValue} submitForm={submitForm} />
            ) : (
              analysisResults.map(({ markdown, query, type }, index) =>
                markdown ? <AnalysisMarkdown key={index} markdown={markdown} /> : <AnalysisOther key={index} query={query} type={type} />
              )
            )}
          </CardContent>
        </Box>

        <AnalysisForm getFieldProps={getFieldProps} handleSubmit={handleSubmit} ref={formBoxRef} values={values} />
      </Card>
    </Grid>
  )
})

const AnalysisTitle = forwardRef<HTMLDivElement, {}>((_, ref) => {
  const { isAnalysisFetching, isSearchFetching, setIsAnalysisPaneOpen } = useLocalContext()

  return (
    <Box
      ref={ref}
      sx={{
        alignItems: 'center',
        bgcolor: grey[100],
        borderBottom: `1px solid ${grey[300]}`,
        display: 'flex',
        gap: 1,
        justifyContent: 'space-between',
        pl: 2,
        pr: 0.5,
        py: 0.5
      }}
    >
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Typography component="h2" sx={{ color: grey[800], fontSize: 14, fontWeight: 600 }}>
          <>Klarity AI Language Analysis</>
        </Typography>

        <Chip
          label="Beta"
          size="small"
          sx={{ bgcolor: deepPurple[50], borderRadius: 1, color: deepPurple[700], fontSize: 9, fontWeight: 700, textTransform: 'uppercase' }}
        />
      </Box>

      <IconButton aria-label="Close window" disabled={isAnalysisFetching || isSearchFetching} onClick={() => setIsAnalysisPaneOpen(false)} size="small">
        <CloseIcon />
      </IconButton>
    </Box>
  )
})

const ColumnResizeWidget: FC = () => {
  const bind = useDrag(({ down, movement: [mx] }) => {
    if (gridContainerRef.current) {
      const offset = Math.round(mx / (gridContainerRef.current.offsetWidth / GRID_COLUMNS))

      const analysisPaneColumns =
        mx > 0
          ? Math.max(MINIMUM_ANALYSIS_PANE_COLUMNS, initialAnalysisPaneColumns.current - offset)
          : GRID_COLUMNS - Math.max(MINIMUM_RESULTS_PANE_COLUMNS, GRID_COLUMNS - initialAnalysisPaneColumns.current + offset)

      setAnalysisPaneColumns(analysisPaneColumns)

      if (!down) {
        initialAnalysisPaneColumns.current = analysisPaneColumns
      }
    }
  })

  const { analysisPaneColumns, gridContainerRef, setAnalysisPaneColumns } = useLocalContext()
  const initialAnalysisPaneColumns = useRef(analysisPaneColumns)

  return (
    <Box
      {...bind()}
      sx={{
        bgcolor: grey[300],
        bottom: 4,
        position: 'absolute',
        left: -9,
        top: 4,
        transition: 'background-color 0.1s',
        width: '1px',
        '::before': {
          bottom: 0,
          content: '""',
          left: -3,
          position: 'absolute',
          right: -3,
          top: 0
        },
        ':hover': {
          bgcolor: grey[500],
          cursor: 'ew-resize',
          ...(analysisPaneColumns === MINIMUM_ANALYSIS_PANE_COLUMNS && { cursor: 'w-resize' }),
          ...(GRID_COLUMNS - analysisPaneColumns === MINIMUM_RESULTS_PANE_COLUMNS && { cursor: 'e-resize' })
        }
      }}
    />
  )
}

const InitialFetching: FC = () => (
  <Box sx={{ alignItems: 'center', display: 'flex', height: '100%', justifyContent: 'center', minHeight: MIN_HEIGHT }}>
    <Box sx={{ pb: 10, textAlign: 'center' }}>
      <CircularProgress sx={{ color: grey[500] }} />

      <Typography sx={{ color: grey[500], mt: 3.125 }}>Loading…</Typography>
    </Box>
  </Box>
)

const MultiSelectFilter: FC<_MultiSelectFilterProps> = ({
  fieldProps,
  id,
  isDisabled = false,
  isLoading,
  isNoneAllowed = false,
  items,
  label,
  selectedItems,
  setFieldValue
}) => {
  const filteredSelectedItems = selectedItems.filter(selectedItem => selectedItem !== SELECT_ALL)

  return (
    <FormControl fullWidth>
      <InputLabel id={`${id}-label`} shrink>
        {label}
      </InputLabel>

      <Select
        {...fieldProps}
        MenuProps={{ autoFocus: false, container: document.body, sx: { maxWidth: '341px', zIndex: Z_INDEX_HEADER } }} // Prevents the Menu from expanding beyond the width of the Select
        disabled={isDisabled}
        displayEmpty // Ensures "All" is displayed when no value is selected
        label={label}
        labelId={`${id}-label`}
        multiple
        onClose={() => {
          if (isEmpty(filteredSelectedItems) && !isNoneAllowed) {
            setFieldValue(id, items)
          }
        }}
        renderValue={() => (
          <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {isLoading ? '…' : isEmpty(filteredSelectedItems) ? 'None' : size(filteredSelectedItems) === size(items) ? 'All' : filteredSelectedItems.join(', ')}
          </Typography>
        )}
        size="small"
        sx={{ height: 40, '& legend': { maxWidth: 'none' } }} // Fixes bug where Select outline intersects with shrunken InputLabel if displayEmpty
      >
        <MenuItem
          key={SELECT_ALL}
          onMouseUp={() => setFieldValue(id, size(filteredSelectedItems) === size(items) ? [] : items)}
          sx={{
            '&.MuiButtonBase-root.MuiMenuItem-root:hover, &.MuiButtonBase-root.MuiMenuItem-root.Mui-selected:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' },
            '&.MuiButtonBase-root.MuiMenuItem-root.Mui-selected': { bgcolor: 'transparent' }
          }}
          value={SELECT_ALL}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography>Select all</Typography>

            <Switch checked={size(filteredSelectedItems) === size(items)} size="small" />
          </Box>
        </MenuItem>

        {!isEmpty(items) && <Divider />}

        {items
          .sort((a, b) => a.localeCompare(b))
          .map(item => (
            <MenuItem key={item} sx={MENU_ITEM_SX} value={item}>
              <Checkbox checked={selectedItems.includes(item)} size="small" />

              <ListItemText primary={item} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

export const SemanticSearchV2Tab: FC = () => {
  const currentUser = useCurrentUser()
  const { data: documentTypesData, loading: isDocumentTypesDataLoading } = useDocumentTypesQuery()
  const [submitAnalysis, { data: analysisData, isFetching: isAnalysisFetching }] = useLazySubmitAiLanguageAnalysisQuery()
  const [submitSearch, { data: searchData, isFetching: isSearchFetching, isUninitialized: isSearchUninitialized }] = useLazySubmitSemanticSearchQuery()
  const { data: checklistFieldsData, loading: isChecklistFieldsDataLoading } = useSemanticSearchFieldsQuery()

  const { getFieldProps, handleChange, handleSubmit, setFieldValue, values } = useFormik<_SearchFormValues>({
    initialValues: { checklistFields: [], documentTypes: [], query: '', sortBy: SortOptions.RELEVANCE },
    onSubmit: ({ checklistFields, documentTypes, ...rest }) => {
      setAnalysisResults([])

      submitSearch({
        checklist_fields: checklistFields.filter(checklistField => checklistField !== SELECT_ALL),
        document_types: documentTypes.filter(documentType => documentType !== SELECT_ALL),
        ...rest
      })
    }
  })

  const cardContentRef = useRef<HTMLDivElement>(null)
  const gridContainerRef = useRef<HTMLDivElement>(null)
  const resultsHeaderRef = useRef<HTMLDivElement>(null)
  const resultsRef = useRef<HTMLDivElement>(null)
  const searchBoxRef = useRef<HTMLDivElement>(null)
  const searchFieldRef = useRef<HTMLInputElement>(null)

  const [analysisPaneColumns, setAnalysisPaneColumns] = useState(MINIMUM_ANALYSIS_PANE_COLUMNS)
  const [analysisResults, setAnalysisResults] = useState<_AnalysisResult[]>([])
  const [checklistFieldSelectList, setChecklistFieldSelectList] = useState<string[]>([])
  const [documentTypeSelectList, setDocumentTypeSelectList] = useState<string[]>([])
  const [isAnalysisPaneOpen, setIsAnalysisPaneOpen] = useState(true)
  const [isInitialFetching, setIsInitialFetching] = useState(true)
  const [isSearchBarPinned, setIsSearchBarPinned] = useState(false)

  const customerName = useMemo(() => currentUser?.customers?.edges[0]?.node?.name, [currentUser])
  const searchResults = useMemo(() => parseQueryResponse(searchData), [searchData])

  const context = useMemo<_Context>(
    () => ({
      analysisPaneColumns,
      analysisResults,
      cardContentRef,
      checklistFields: values.checklistFields,
      documentTypes: values.documentTypes,
      gridContainerRef,
      isAnalysisPaneOpen,
      isAnalysisFetching,
      isSearchFetching,
      semanticSearchQuery: values.query,
      setAnalysisPaneColumns,
      setAnalysisResults,
      setIsAnalysisPaneOpen,
      submitAnalysis
    }),
    [
      analysisPaneColumns,
      analysisResults,
      isAnalysisFetching,
      isAnalysisPaneOpen,
      isSearchFetching,
      submitAnalysis,
      values.checklistFields,
      values.documentTypes,
      values.query
    ]
  )

  const sortedSearchResults = useMemo(() => {
    let newResults = [...searchResults]

    switch (values.sortBy) {
      case SortOptions.RELEVANCE:
        newResults = newResults.sort(
          (a, b) => (a.low_confidence ? 1 : 0) - (b.low_confidence ? 1 : 0) || b.match_score - a.match_score || a.document_name.localeCompare(b.document_name)
        )
        break

      case SortOptions.CREATED_AT:
        newResults = newResults.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime() ||
            (a.low_confidence ? 1 : 0) - (b.low_confidence ? 1 : 0) ||
            b.match_score - a.match_score
        )
        break

      case SortOptions.DOCUMENT_NAME:
        newResults = newResults.sort(
          (a, b) => a.document_name.localeCompare(b.document_name) || (a.low_confidence ? 1 : 0) - (b.low_confidence ? 1 : 0) || b.match_score - a.match_score
        )
        break
    }

    return newResults
  }, [searchResults, values.sortBy])

  useMemo(() => {
    const checkListFields = checklistFieldsData?.semantic_search_fields?.map(field => field!.field_name) || []

    setChecklistFieldSelectList(checkListFields)
  }, [checklistFieldsData?.semantic_search_fields])

  useMemo(() => {
    const documentTypes = documentTypesData?.document_types?.edges?.map(edge => edge!.node!.name) || []

    setDocumentTypeSelectList(documentTypes)
    setFieldValue('documentTypes', documentTypes)
  }, [documentTypesData?.document_types?.edges, setFieldValue])

  useMemo(() => {
    if (isInitialFetching && !isEmpty(searchData)) {
      setIsInitialFetching(false)
    }
  }, [isInitialFetching, searchData])

  useMemo(() => {
    if (isEmpty(searchData)) searchBoxRef.current?.focus()
  }, [searchData])

  useEffect(() => {
    setAnalysisResults(current => {
      const isEmpty = !analysisData?.response.text
      const isError = Boolean(analysisData?.response.error)

      return [
        ...current.slice(0, -1), // Remove the LOADING element
        ...[
          ...(isError
            ? [{ type: ResultTypes.ERROR }]
            : isEmpty
            ? [{ type: ResultTypes.EMPTY }]
            : [{ markdown: analysisData?.response.text, type: ResultTypes.MARKDOWN }]),
          { type: ResultTypes.NEW_ASK }
        ]
      ]
    })

    const timeoutId = setTimeout(() => {
      if (cardContentRef.current && cardContentRef.current.scrollTop) {
        cardContentRef.current.scrollTop += SCROLL_AMOUNT
      }
    }, 100)

    return () => clearTimeout(timeoutId)
  }, [analysisData])

  useIntersectionObserver(
    searchBoxRef,
    () => setIsSearchBarPinned(true),
    () => setIsSearchBarPinned(false),
    { threshold: 1 }
  )

  // Scroll to the top of the results when running a new query or changing the sort/filter options (only necessary when the search bar is pinned)
  useLayoutEffect(() => {
    if (isSearchBarPinned && resultsRef.current) {
      // Add 1px to account for negative `top` value on the search bar
      window.scrollTo({
        top: resultsRef.current.getBoundingClientRect().top + window.scrollY - PINNED_SEARCH_BAR_OFFSET - resultsHeaderRef.current!.offsetHeight + 1
      })
    }
  }, [isSearchBarPinned, searchData, values.checklistFields, values.documentTypes, values.sortBy])

  // functions

  const exportResults = () => {
    const options = {
      filename: `Search Results${customerName && ` - ${customerName}${values.query && ` - ${values.query}`}`}`,
      useKeysAsHeaders: true
    }

    const csvExporter = new ExportToCsv(options)

    const transformedResults = sortedSearchResults.map((result, index) => {
      const { counterparty, created_at, document_id, document_name, document_type, match_text } = result

      return {
        result_number: index + 1,
        match_text,
        document_name,
        document_url: `${window.location.origin}/documents/${document_id}`,
        document_type,
        counterparty,
        upload_date: new Intl.DateTimeFormat(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(created_at))
      }
    })

    csvExporter.generateCsv(transformedResults)
  }

  // render

  return (
    <Context.Provider value={context}>
      <KlarityCard>
        {/* Search Bar – Negative `top` required for sticky positioning with IntersectionObserver – https://stackoverflow.com/a/57991537/9027907 */}
        <Box ref={searchBoxRef} sx={{ background: 'white', borderBottom: '1px solid #eeeeee', position: 'sticky', py: 4, top: -1, zIndex: 1 }}>
          <FormControl autoComplete="off" component="form" fullWidth onSubmit={handleSubmit}>
            <Container maxWidth="xl" sx={{ display: 'flex', gap: 2 }}>
              <MultiSelectFilter
                fieldProps={{ ...getFieldProps('documentTypes') }}
                id="documentTypes"
                isDisabled={isAnalysisFetching || isDocumentTypesDataLoading || isSearchFetching}
                isLoading={isDocumentTypesDataLoading}
                items={documentTypeSelectList}
                label="Document types"
                selectedItems={values.documentTypes}
                setFieldValue={setFieldValue}
              />

              <MultiSelectFilter
                fieldProps={{ ...getFieldProps('checklistFields') }}
                id="checklistFields"
                isDisabled={isAnalysisFetching || isChecklistFieldsDataLoading || isSearchFetching}
                isLoading={isChecklistFieldsDataLoading}
                isNoneAllowed
                items={checklistFieldSelectList}
                label="Checklist fields"
                selectedItems={values.checklistFields}
                setFieldValue={setFieldValue}
              />

              <TextField
                autoFocus
                disabled={isAnalysisFetching || isSearchFetching}
                fullWidth
                label="Search"
                ref={searchFieldRef}
                size="small"
                variant="outlined"
                {...getFieldProps('query')}
              />

              <Button
                disableElevation
                disabled={isAnalysisFetching || isSearchFetching || !values.query}
                sx={{ flexShrink: 0 }}
                type="submit"
                variant="contained"
              >
                Search
              </Button>
            </Container>
          </FormControl>
        </Box>

        <Box sx={{ bgcolor: grey[50] }}>
          <Container maxWidth="xl">
            {isSearchUninitialized ? (
              <Uninitialized />
            ) : isInitialFetching ? (
              <InitialFetching />
            ) : (
              <Grid columnSpacing={2.125} columns={GRID_COLUMNS} container ref={gridContainerRef}>
                <Grid item xs={isAnalysisPaneOpen ? GRID_COLUMNS - analysisPaneColumns : GRID_COLUMNS}>
                  <Box ref={resultsHeaderRef} sx={{ bgcolor: grey[50], mx: -0.5, position: 'sticky', top: PINNED_SEARCH_BAR_OFFSET, zIndex: 1 }}>
                    <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', mx: 0.5, pb: 2, pt: 2 }}>
                      <Box sx={{ alignItems: 'center', display: 'flex', gap: 2, ml: 1.125 }}>
                        <Typography sx={{ fontSize: 14, opacity: !searchResults ? 0 : 1 }}>
                          {isSearchFetching ? 'Loading…' : `${size(searchResults)} result${size(searchResults) === 1 ? '' : 's'}`}
                        </Typography>

                        <FormControl>
                          <InputLabel id="sortBy-label">Sort by</InputLabel>

                          <Select
                            {...getFieldProps('sortBy')}
                            disabled={isSearchFetching}
                            label="Sort by"
                            labelId="sortBy-label"
                            onChange={handleChange}
                            size="small"
                            sx={{ fontSize: 14 }}
                          >
                            {Object.entries(SORT_OPTION_LABELS).map(([value, label]) => (
                              <MenuItem key={value} value={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>

                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <IconButton disabled={isSearchFetching || isEmpty(searchResults)} onClick={exportResults} size="small">
                          <FileDownloadOutlinedIcon />
                        </IconButton>

                        <IconButton
                          disabled={isAnalysisFetching || isSearchFetching || isEmpty(searchResults)}
                          onClick={() => setIsAnalysisPaneOpen(current => !current)}
                          size="small"
                          sx={{
                            bgcolor: deepPurple[50],
                            color: deepPurple[500],
                            p: 1
                          }}
                        >
                          <AutoAwesomeIcon sx={{ fontSize: 18, position: 'relative', right: 1 }} />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>

                  <Box ref={resultsRef}>
                    {!isSearchFetching && isEmpty(searchResults) ? (
                      <Typography sx={{ pt: 9, textAlign: 'center' }}>No results found</Typography>
                    ) : (
                      sortedSearchResults.map(({ counterparty, created_at, document_id, document_name, document_type, match_text }, index) => (
                        <Card key={index} sx={{ mb: 2, opacity: isSearchFetching ? 0.25 : 1, pointerEvents: isSearchFetching ? 'none' : 'auto' }}>
                          <CardContent sx={{ ':last-child': { pb: 2 } }}>
                            <Link to={`/documents/${document_id}`}>
                              <Typography
                                sx={{ color: 'primary.main', display: 'block', fontSize: 14, fontWeight: 600, mb: 1, '&:hover': { color: '#004fdb' } }}
                              >
                                {document_name}
                              </Typography>
                            </Link>

                            <Typography color="text.secondary" sx={{ lineHeight: 1 }} variant="overline">
                              {document_type} &nbsp;&nbsp;•&nbsp;&nbsp;
                              {counterparty} &nbsp;&nbsp;•&nbsp;&nbsp;
                              {new Intl.DateTimeFormat(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(created_at))}
                            </Typography>

                            <Typography color="text.primary" sx={{ fontSize: 14, mt: 1 }}>
                              … {match_text} …
                            </Typography>
                          </CardContent>
                        </Card>
                      ))
                    )}
                  </Box>
                </Grid>

                <AnalysisPane ref={cardContentRef} />
              </Grid>
            )}
          </Container>
        </Box>
      </KlarityCard>
    </Context.Provider>
  )
}

const Uninitialized: FC = () => (
  <Box sx={{ alignItems: 'center', display: 'flex', height: '100%', justifyContent: 'center', minHeight: MIN_HEIGHT }}>
    <Box sx={{ pb: 10, textAlign: 'center' }}>
      <StartSearchingImage />

      <Typography sx={{ color: grey[500] }}>Start searching</Typography>
    </Box>
  </Box>
)

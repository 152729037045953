import { ActiveComponents, useCciMainContext } from '../../../../../CCI_Main'
import { CREATE_FIELD_REFETCH_QUERIES } from '../../../../Modal_components/ChecklistGptTab/CreateNewField'
import { toast } from 'react-toastify'
import { useAppContext } from '../../../../../../../app'
import { useCallback, useMemo, useRef } from 'react'
import {
  useCreateFieldExtractionMethodInstanceMutation,
  useEditFieldExtractionMethodInstanceMutation,
  useExtractionMethodsQuery
} from '../../../../../../../graphql/codegen/hooks'

// types

export type _ExtractionConfigurationChanges = { extractionDescription?: string; extractionMethodId?: string; isEnabled?: boolean }

// hooks

export const useExtractionConfiguration = () => {
  const { activeComponent, closeModal, expandAllCallback, selectedItem, setActiveComponent, setSelectedItem } = useCciMainContext()
  const { data: extractionMethodsData } = useExtractionMethodsQuery()
  const { setErrorMessage } = useAppContext()

  // Use a ref, not state, to ensure the value is up-to-date when the edit mutation is called (regardless of the current render cycle).
  const extractionConfigurationChangesRef = useRef<_ExtractionConfigurationChanges | null>(null)

  const extractionMethodInstanceId = useMemo(
    () => JSON.parse(selectedItem?.extraction_method_instances_config?.[0] || '{}').extraction_method_instance_id,
    [selectedItem]
  )

  const extractionMethodList = useMemo(
    () => extractionMethodsData?.extraction_methods?.edges.map(item => ({ label: item?.node?.display_name || '', value: item?.node?.id || '' })) || [],
    [extractionMethodsData]
  )

  const [createFieldExtractionMethodInstanceMutation] = useCreateFieldExtractionMethodInstanceMutation({
    awaitRefetchQueries: true,
    onCompleted: data => {
      closeModal({ isForced: true })
      expandAllCallback?.()
      setActiveComponent(ActiveComponents.EDIT_FIELD)

      setSelectedItem({
        ...selectedItem,
        extraction_method_instances_config: [data.create_field_extraction_method_instance?.data_point_field?.extraction_method_instances_config || []],
        prompt_fragments: data.create_field_extraction_method_instance?.data_point_field?.prompt_fragments || []
      })

      setExtractionConfigurationChanges(null)

      toast.success(activeComponent === ActiveComponents.CREATE_FIELD ? 'Field successfully created' : 'Extraction configuration saved', { autoClose: 5000 })
    },
    onError: () => {
      closeModal({ isForced: true })

      setErrorMessage(`Error saving extraction configuration`)
    },
    refetchQueries: CREATE_FIELD_REFETCH_QUERIES
  })

  const [editFieldExtractionMethodInstanceMutation] = useEditFieldExtractionMethodInstanceMutation({
    onCompleted: () => {
      closeModal({ isForced: true })

      setExtractionConfigurationChanges(null)

      toast.success('Extraction configuration updated', { autoClose: 5000 })
    },
    onError: () => {
      closeModal({ isForced: true })

      setErrorMessage('Failed to update extraction configuration')
    },
    update: (cache, { data }) => {
      const updatedExtractionMethodConfig = data?.edit_field_extraction_method_instance?.data_point_field?.extraction_method_instances_config?.[0]

      cache.modify({
        id: cache.identify({ __typename: 'DataPointField', id: selectedItem.id }),
        fields: { extraction_method_instances_config: () => [updatedExtractionMethodConfig] }
      })

      setSelectedItem({ ...selectedItem, extraction_method_instances_config: [updatedExtractionMethodConfig] })
    }
  })

  // `dataPointFieldId` is passed from <CreateNewField> via `createDataPointFieldMutation.onCompleted` (before the ID is assigned to `selectedItem`).
  const createExtractionConfiguration = useCallback(
    (dataPointFieldId: string) => {
      createFieldExtractionMethodInstanceMutation({
        variables: {
          dataPointFieldId,
          extractionDescription: '',
          extractionMethodId: extractionConfigurationChangesRef.current?.extractionMethodId || '',
          isEnabled: Boolean(extractionConfigurationChangesRef.current?.isEnabled)
        }
      })
    },
    [createFieldExtractionMethodInstanceMutation]
  )

  const editExtractionConfiguration = useCallback(() => {
    editFieldExtractionMethodInstanceMutation({
      variables: {
        dataPointFieldId: selectedItem.id,
        extractionMethodInstanceId,
        ...(extractionConfigurationChangesRef.current &&
          (Object.keys(extractionConfigurationChangesRef.current) as Array<keyof _ExtractionConfigurationChanges>).reduce(
            (changes, key) => ({ ...changes, [key]: extractionConfigurationChangesRef.current![key] }),
            {} as Partial<_ExtractionConfigurationChanges>
          ))
      }
    })
  }, [editFieldExtractionMethodInstanceMutation, extractionMethodInstanceId, selectedItem])

  const setExtractionConfigurationChanges = useCallback((changes: _ExtractionConfigurationChanges | null) => {
    extractionConfigurationChangesRef.current = changes === null ? null : { ...extractionConfigurationChangesRef.current, ...changes }
  }, [])

  return {
    createExtractionConfiguration,
    editExtractionConfiguration,
    extractionMethodId: extractionConfigurationChangesRef.current?.extractionMethodId,
    extractionMethodList,
    setExtractionConfigurationChanges
  }
}

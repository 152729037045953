// constants

export const COLOR_DANGER_5 = '#fc3535'
export const COLOR_WARNING_TEXT = '#bf360c'

export const HEADER_HEIGHT = 72

export const KLARITY_BLUE = '#0064fa'

export const Z_INDEX_1 = 1
export const Z_INDEX_DRAG = 1000
export const Z_INDEX_FOOTER = 5000
export const Z_INDEX_HEADER = 6000
export const Z_INDEX_DROPDOWN = 7000
export const Z_INDEX_OVERLAY = 8000
export const Z_INDEX_MODAL = 9000

import { ConfigMenu } from './ConfigMenu'
import { Features, Permissions, useUserAccess } from '../../hooks/useUserAccess'
import { KLARITY_BLUE } from '../../utils/styleUtils'
import { Link, Typography } from '@mui/material'
import { ReactComponent as Logo } from '../../assets/k.svg'
import { MoreMenu, _MenuItem } from '../MoreMenu'
import { NavLink as RouterLink } from 'react-router-dom'
import { logOut } from '../../utils/sessionApiUtils'
import React, { useMemo } from 'react'
import css from './style.module.scss'
import useCurrentUser from '../../hooks/useCurrentUser'

// constants

const IMAGES = require.context('../../assets/customer-logos/', false, /\.(png|jpe?g|svg)$/)

const LOGO_MAP = IMAGES.keys().reduce((previous: { [k: string]: any }, current) => {
  const companyName = current.match(/.\/(.+)\.(png|jpe?g|svg)$/)?.[1]

  return companyName ? { ...previous, [companyName]: IMAGES(current) } : previous
}, {})

export const NAV_LINK_SX = { color: 'black', textDecoration: 'none', '&:hover, &.active': { color: KLARITY_BLUE } }

// components

export const NavBar = () => {
  const currentUser = useCurrentUser()
  const hasAnalyticsAccess = useUserAccess({ feature: Features.ANALYTICS, permission: Permissions.READ })
  const hasConfigAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.READ })

  const currentCustomerId = localStorage.getItem('customerId')
  const currentCustomerName = currentUser?.customers?.edges?.find(customerEdge => customerEdge?.node?.id === currentCustomerId)?.node?.name
  const customerLogoKey = currentCustomerName && currentCustomerName.toLowerCase().split(' ').join('_')
  const customerLogo = customerLogoKey && LOGO_MAP[customerLogoKey]

  const menuItemList: _MenuItem[] = useMemo(() => [{ label: 'Log out', onClick: logOut }], [])

  return (
    <>
      {currentUser?.is_super_admin && (
        <div className={css.superAdminHeader}>
          <Typography component="span" fontWeight={600} variant="body2">
            Workspace:{' '}
          </Typography>

          <Typography component="span" variant="body2">
            {currentCustomerName}
          </Typography>
        </div>
      )}

      <div className={css.nav} id="nav">
        <ul className={css.navMenu}>
          <li>
            <Logo />
          </li>

          <li>
            <Link component={RouterLink} exact sx={NAV_LINK_SX} to="/">
              Dashboard
            </Link>
          </li>

          {hasAnalyticsAccess && (
            <li>
              <Link component={RouterLink} sx={NAV_LINK_SX} to="/analytics">
                Analytics
              </Link>
            </li>
          )}

          {hasConfigAccess && (
            <li>
              <ConfigMenu />
            </li>
          )}

          {currentUser?.is_super_admin && (
            <li>
              <Link component={RouterLink} sx={NAV_LINK_SX} to="/workspaces">
                Workspaces
              </Link>
            </li>
          )}
        </ul>

        <ul className={css.navMenu}>
          {currentUser && (
            <li>
              <MoreMenu label={currentUser.first_name as string} menuItemList={menuItemList} />
            </li>
          )}

          {customerLogo && (
            <li>
              <img className={css.customerLogo} src={customerLogo} />
            </li>
          )}
        </ul>
      </div>
    </>
  )
}

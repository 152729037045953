import { useDocumentTypesQuery } from '../../graphql/codegen/hooks'
import React, { useMemo } from 'react'
import SelectInput from '../SelectInput'

// types

type _DocumentTypeSelectorProps = {
  isError?: boolean
  menuPlacement?: 'auto' | 'bottom' | 'top'
  onChange: (option: { label: string; value: string } | null) => void
}

// components

export const DocumentTypeSelector = ({ isError, menuPlacement, onChange }: _DocumentTypeSelectorProps) => {
  const { data, loading: isLoading } = useDocumentTypesQuery()

  const optionList = useMemo(() => data?.document_types?.edges?.map(edge => ({ label: edge?.node?.name, value: edge?.node?.id })), [data])

  return (
    <SelectInput
      isClearable
      isError={isError}
      isLoading={isLoading}
      menuPlacement={menuPlacement}
      onChange={onChange}
      options={optionList}
      placeholder="Select document type"
    />
  )
}

import {
  DealAttachmentsDocument,
  useChangeDealAttachmentAsDocumentMutation,
  useDealsAffectedByDocumentLevelChangeLazyQuery
} from '../../../graphql/codegen/hooks'
import { DocumentTypeSelector } from '../../DataPointPanel/DocumentTypeSelector'
import { Features, Permissions, useUserAccess } from '../../../hooks/useUserAccess'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from '../../Button'
import React, { useEffect, useState } from 'react'
import SelectInput from '../../SelectInput'
import TypeToConfirm from '../../TypeToConfirm'
import WithTooltip from '../../WithTooltip'
import css from './style.module.scss'

// types

type _AttachmentToDocumentProps = {
  alias?: string
  attachment?: any
  closeModal?: any
  dealId?: string
  name?: string
  setLoading?: any
  setLoadingMessage?: any
}

// components

export const AttachmentToDocument = ({ alias, attachment, closeModal, dealId, name, setLoading, setLoadingMessage }: _AttachmentToDocumentProps) => {
  const history = useHistory()
  const [attachmentType, setAttachmentType] = useState('') // When converting, select customer wide or attach to single deal
  const [confirmed, setConfirmed] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [disabledMessage, setDisabledMessage] = useState('')
  const [missingDocumentIdError, setMissingDocumentIdError] = useState(false)
  const [selectedDocumentTypeId, setSelectedDocumentTypeId] = useState<string | null>(null)
  const { t } = useTranslation()
  const hasAccountLevelDocumentAccess = useUserAccess({ feature: Features.DOCUMENT, permission: Permissions.ACCOUNT_LEVEL })

  const [getAffectedCounts, { data: countsData, error: countsError, loading: countsLoading }] = useDealsAffectedByDocumentLevelChangeLazyQuery()

  const handleClose = () => {
    setAttachmentType('')
    setConfirmed(false)
    setIsDisabled(false)
    setDisabledMessage('')
    setMissingDocumentIdError(false)
    closeModal()
  }

  const [changeDealAttachmentAsDocumentMutation, { error: mutationError, loading: mutationLoading }] = useChangeDealAttachmentAsDocumentMutation({
    refetchQueries: ['deals', { query: DealAttachmentsDocument, variables: { dealId } }],
    awaitRefetchQueries: true,
    onCompleted: () => history.push(history.location.pathname)
  })

  const handleConvert = async (attachment: any, dealId?: string) => {
    if (dealId && attachment && selectedDocumentTypeId) {
      changeDealAttachmentAsDocumentMutation({
        variables: {
          dealId,
          attachmentId: attachment.id,
          isAccountLevel: attachmentType === 'account_level',
          documentTypeId: selectedDocumentTypeId
        }
      })
    }
  }

  useEffect(() => {
    if (hasAccountLevelDocumentAccess && !attachmentType) {
      setIsDisabled(true)
      setDisabledMessage('Select document level')
    } else if (hasAccountLevelDocumentAccess && attachmentType === 'account_level') {
      if (confirmed) {
        setIsDisabled(!selectedDocumentTypeId)
        setDisabledMessage(selectedDocumentTypeId ? '' : 'Please select a document type')
      } else {
        setIsDisabled(true)
        setDisabledMessage('Please type Confirm into the box to proceed')
      }
    } else {
      setIsDisabled(!selectedDocumentTypeId)
      setDisabledMessage(selectedDocumentTypeId ? '' : 'Please select a document type')
    }
  }, [hasAccountLevelDocumentAccess, attachmentType, confirmed, selectedDocumentTypeId])

  useEffect(() => {
    if (attachment?.document_id) {
      getAffectedCounts({ variables: { document_id: attachment.document_id } })
    } else {
      setMissingDocumentIdError(true)
      setIsDisabled(true)
      setDisabledMessage('Attachment is missing document_id. Cannot be converted to a document.')
    }
    // eslint-disable-next-line
    }, [])

  useEffect(() => {
    if (countsLoading || mutationLoading) {
      setLoading(true)
      if (mutationLoading) {
        setLoadingMessage('Converting Attachment…')
      }
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line
    }, [countsLoading, mutationLoading])

  return (
    <>
      <h5 style={{ textAlign: 'center' }}>{alias || name}</h5>

      {hasAccountLevelDocumentAccess ? (
        <>
          <div className={css.inputRow}>
            <h4>Upload to:</h4>

            <SelectInput
              onChange={(v: any) => setAttachmentType(v.value)}
              options={[
                { value: 'deal_level', label: `This ${t('deal')} only` },
                { value: 'account_level', label: `All ${t('deals')} for this customer` }
              ]}
              placeholder="Select document level"
            />
          </div>

          {attachmentType && attachmentType === 'account_level' ? (
            <>
              {!countsError && !mutationError && (
                <>
                  <p style={{ margin: '8px auto 8px auto', textAlign: 'center', minWidth: '602px' }}>
                    Making this change will update field values for all active {t('deals')} with this customer.
                  </p>

                  <p style={{ margin: '0 auto 16px auto', textAlign: 'center', minWidth: '602px' }}>Completed {t('deals')} will be unaffected.</p>

                  <p style={{ textAlign: 'center' }}>
                    Active {t('deals')} to update: {countsData?.deals_affected_by_document_level_change?.active_deals}
                  </p>

                  <p style={{ marginTop: '16px', textAlign: 'center' }}>Are you sure you want to convert this attachment to a customer-level document?</p>
                </>
              )}

              <TypeToConfirm setConfirmed={setConfirmed} />
            </>
          ) : (
            attachmentType === 'deal_level' && (
              <>
                <p>The attachment will be sent to processing and will be unavailable until finished.</p>

                <p>Are you sure you want to convert this attachment to a document?</p>
              </>
            )
          )}

          {missingDocumentIdError && (
            <>
              <p>Error: Attachment is missing document_id, cannot convert it to a document.</p>

              <p>Upload the attachment using Manual Upload to proceed.</p>
            </>
          )}

          {countsError && <p>Error loading data: ${countsError?.message}</p>}

          {mutationError && <p>Error converting attachment: ${mutationError?.message}</p>}
        </>
      ) : (
        <>
          <p>The attachment will be sent to processing and will be unavailable until finished.</p>

          <p>Are you sure you want to convert this attachment to a document?</p>
        </>
      )}

      <div className={css.inputRow}>
        <h4>Document Type:</h4>

        <DocumentTypeSelector onChange={(option: any) => setSelectedDocumentTypeId(option?.value || null)} />
      </div>

      <div className={css.modalButtonRow}>
        <Button onClick={handleClose} variant="secondary">
          Cancel
        </Button>

        <WithTooltip content={disabledMessage}>
          <Button disabled={isDisabled} onClick={() => handleConvert(attachment, dealId)}>
            Submit
          </Button>
        </WithTooltip>
      </div>
    </>
  )
}

import useCurrentUser from './useCurrentUser'

// constants

const QA_WORKSPACE_WHITELIST = [
  'Q3VzdG9tZXI6Njc2NDIyOTNjODgwMWU3ODJjZDZkYzkz', // 1_Fully Automated
  'Q3VzdG9tZXI6NjY0NmY4OTA2OTczYmRmNTNiNjM4YjEy' // InstaLearn Klarity 3.0 Demo
]

// hooks

export const useHasDataTableSpreadsheetAccess = () => {
  const currentUser = useCurrentUser()
  const workspaceId = currentUser?.customers?.edges[0]?.node?.id

  const isColgateWorkspace = Boolean(currentUser?.customers?.edges[0]?.node?.name.match(/^colgate/i))
  const isWhitelistedQAWorkspace = workspaceId ? QA_WORKSPACE_WHITELIST.includes(workspaceId) : false

  return isColgateWorkspace || isWhitelistedQAWorkspace
}

import { Box, FormControlLabel, Switch, Typography } from '@mui/material'
import { Features, Permissions, useUserAccess } from '../../../../../../../hooks/useUserAccess'
import { TooltipInfo } from '../TooltipInfo'
import { grey } from '@mui/material/colors'
import { useCciMainContext } from '../../../../../CCI_Main'
import { useChecklistConfigContext } from '../../../../ChecklistConfigProvider'
import { useTranslation } from 'react-i18next'
import React, { FC, useEffect, useState } from 'react'

// components

export const BooleanInputs: FC = () => {
  const { selectedItem } = useCciMainContext()
  const { isCreateView, isEditView, updateFieldValue } = useChecklistConfigContext()

  const {
    default_field_on_dashboard: initialDefaultFieldOnDashboard,
    display_accounting_impact: initialDisplayAccountingImpact,
    display_annotations: initialDisplayAnnotations,
    display_if_empty: initialDisplayIfEmpty,
    display_on_checklist: initialDisplayOnChecklist
  } = selectedItem || {}

  const [defaultFieldOnDashboard, setDefaultFieldOnDashboard] = useState(isEditView ? initialDefaultFieldOnDashboard : false)
  const [displayAccountingImpact, setDisplayAccountingImpact] = useState(isEditView ? initialDisplayAccountingImpact : true)
  const [displayAnnotations, setDisplayAnnotations] = useState(isEditView ? initialDisplayAnnotations : true)
  const [displayIfEmpty, setDisplayIfEmpty] = useState(isEditView ? initialDisplayIfEmpty : true)
  const [displayOnChecklist, setDisplayOnChecklist] = useState(isEditView ? initialDisplayOnChecklist : true)
  const { t } = useTranslation()
  const hasFullEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD })

  useEffect(() => {
    if (isCreateView) {
      const timeoutId = setTimeout(() => {
        updateFieldValue('default_field_on_dashboard', defaultFieldOnDashboard)
        updateFieldValue('display_accounting_impact', displayAccountingImpact)
        updateFieldValue('display_annotations', displayAnnotations)
        updateFieldValue('display_if_empty', displayIfEmpty)
        updateFieldValue('display_on_checklist', displayOnChecklist)
      }, 200)

      return () => clearTimeout(timeoutId)
    }
  }, [isCreateView]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (key: string, value: boolean) => {
    switch (key) {
      case 'defaultFieldOnDashboard':
        updateFieldValue('default_field_on_dashboard', value)
        setDefaultFieldOnDashboard(value)
        break

      case 'displayAccountingImpact':
        updateFieldValue('display_accounting_impact', value)
        setDisplayAccountingImpact(value)
        break

      case 'displayAnnotations':
        updateFieldValue('display_annotations', value)
        setDisplayAnnotations(value)
        break

      case 'displayIfEmpty':
        updateFieldValue('display_if_empty', value)
        setDisplayIfEmpty(value)
        break

      case 'displayOnChecklist':
        updateFieldValue('display_on_checklist', value)
        setDisplayOnChecklist(value)

        if (value && !displayAnnotations) {
          updateFieldValue('display_annotations', true)
          setDisplayAnnotations(true)
        } else if (!value && displayAnnotations) {
          updateFieldValue('display_annotations', false)
          setDisplayAnnotations(false)
        }

        break

      default:
        console.error(`BooleanInputs handleChange error: Unsupported button name: ${key}`)
    }
  }

  return (
    <Box sx={{ pointerEvents: 'auto' }}>
      <Typography sx={{ color: grey[600], display: 'block', letterSpacing: 0.4, mt: 3 }} variant="overline">
        Display Settings
      </Typography>

      <Box>
        <FormControlLabel
          control={<Switch checked={!displayOnChecklist} onChange={event => handleChange('displayOnChecklist', !event.target.checked)} />}
          disabled={isEditView && !hasFullEditAccess}
          label={
            <Typography variant="body2">
              <>Always hide this field</>

              <TooltipInfo tooltipTitle="Toggle this option to hide this field from the checklist. This is useful for fields that are only used internally (e.g., as inputs for other fields)." />
            </Typography>
          }
        />
      </Box>

      <Box sx={{ ml: 6, my: 0.5 }}>
        {displayOnChecklist ? (
          <FormControlLabel
            control={<Switch checked={!displayIfEmpty} onChange={event => handleChange('displayIfEmpty', !event.target.checked)} />}
            label={<Typography variant="body2">Hide this field only when it has no value</Typography>}
          />
        ) : (
          <FormControlLabel
            control={<Switch checked={displayAnnotations} onChange={event => handleChange('displayAnnotations', event.target.checked)} />}
            disabled={isEditView && !hasFullEditAccess}
            label={
              <Typography variant="body2">
                <>Display annotation highlights</>

                <TooltipInfo tooltipTitle="When enabled, annotation highlights are visible to the user even if the field is hidden in the checklist. (Klarity staff can always see annotations for QA purposes.)" />
              </Typography>
            }
          />
        )}
      </Box>

      <Box sx={{ mb: 0.5 }}>
        <FormControlLabel
          control={<Switch checked={!displayAccountingImpact} onChange={event => handleChange('displayAccountingImpact', !event.target.checked)} />}
          disabled={isEditView && !hasFullEditAccess}
          label={<Typography variant="body2">Hide accounting impact button</Typography>}
        />
      </Box>

      <Box sx={{ mb: 0.5 }}>
        <FormControlLabel
          control={<Switch checked={defaultFieldOnDashboard} onChange={event => handleChange('defaultFieldOnDashboard', event.target.checked)} />}
          label={<Typography variant="body2">Display as default column in {t('Deals')} and Documents table views</Typography>}
        />
      </Box>
    </Box>
  )
}

import { Box } from '@mui/material'
import { ColumnHeader } from './ColumnHeader'
import { DocumentQueryAction, parseTemporaryColumnsFromQuery } from '../../reducers/dashboardQueryReducer'
import { Hooks, Row, useColumnOrder, useFlexLayout, useResizeColumns, useRowSelect, useTable } from 'react-table'
import { Pagination } from './Pagination'
import { captureError } from '../../utils/sentry'
import { getStickyColumnWidths } from '../../hooks/useStickyColumnWidths'
import { insertColumn, removeEmptyObjects } from './utils'
import { useDealsDashboardFieldsQuery } from '../../graphql/codegen/hooks'
import { useLocation } from 'react-router-dom'
import EmptyState from './EmptyState'
import IndeterminateCheckbox from './IndeterminateCheckbox'
import React, { Dispatch, FC, ReactNode, SetStateAction, useEffect, useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import clsx from 'clsx'
import css from './style.module.scss'
import useDashboardQString from '../../hooks/useDashboardQString'
import useGetExcludedFields from '../../hooks/useGetExcludedFields'

// types

type _RenderSelectionActions = (selectedFlatRows: Row<{}>[], rows?: Row<{}>[]) => ReactNode

type _TableProps = {
  columns: any
  dispatch?: Dispatch<DocumentQueryAction>
  isLoading?: boolean
  pageLength?: number
  renderSelectionActions?: _RenderSelectionActions
  rowCount?: number
  setClearAll?: Dispatch<SetStateAction<boolean>>
  tableData: any
  variant?: 'table' | 'inbox'
}

// hooks

const useSelectionColumn = (renderSelectionActions: _RenderSelectionActions | undefined, isLoading: boolean) => (hooks: Hooks) => {
  if (renderSelectionActions) {
    hooks.visibleColumns.push((columns: any) => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }: { getToggleAllRowsSelectedProps: any }) => (
          <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} loading={isLoading} />
        ),
        Cell: ({ row }: { row: any }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} loading={isLoading} />,
        width: 50,
        minWidth: 50,
        disableResizing: true
      },
      ...columns
    ])
  }
}

// components

export const Table: FC<_TableProps> = ({
  columns,
  dispatch,
  isLoading = false,
  pageLength,
  renderSelectionActions,
  rowCount,
  setClearAll,
  tableData,
  variant,
  ...pageLengthControls
}) => {
  const { pathname } = useLocation()

  const tableColumns = useMemo(() => {
    const persistentColumnWidths = getStickyColumnWidths(pathname)

    return columns.map((column: any) => ({
      ...column,
      width: persistentColumnWidths[column.accessor] || 200,
      Cell: isLoading ? (
        <Box sx={{ px: 2, py: 1.25 }}>
          <Skeleton />
        </Box>
      ) : (
        column.Cell
      )
    }))
  }, [columns, isLoading, pathname])

  const formattedTableData = useMemo(() => (isLoading ? Array(10).fill({}) : tableData), [tableData, isLoading]) // Use empty rows for skeleton loader

  const { getTableBodyProps, getTableProps, headerGroups, prepareRow, rows, selectedFlatRows, visibleColumns } = useTable(
    { columns: tableColumns, data: formattedTableData },
    useRowSelect,
    useFlexLayout,
    useResizeColumns,
    useColumnOrder,
    useSelectionColumn(renderSelectionActions, isLoading)
  )

  const [activeDragColumnIndices, setActiveDragColumnIndices] = useState<number[]>([])
  const [isEmpty, setIsEmpty] = useState<boolean>(false)
  const visibleColumnIds = visibleColumns.map(c => c.id)
  const { data } = useDealsDashboardFieldsQuery()
  const { queryColumns } = useDashboardQString()
  const formattedQueryColumns = typeof queryColumns === 'string' ? queryColumns.split(',') : queryColumns
  let fieldsToExclude = useGetExcludedFields('Deals', 'FieldSelector')
  fieldsToExclude = [...fieldsToExclude, 'assignee']

  const handleColumnSwap = () => {
    const swapped = insertColumn(visibleColumnIds, activeDragColumnIndices)

    try {
      const temporaryColumns = parseTemporaryColumnsFromQuery(data, formattedQueryColumns, fieldsToExclude)

      dispatch?.({ type: temporaryColumns ? 'REORDER_TEMPORARY_COLUMNS' : 'REORDER_COLUMNS', newColumnOrderById: swapped })
    } catch (error) {
      captureError(error, 'Table.tsx handleColumnSwap')
    }
  }

  const isTableDataEmpty = (array: Array<any>) => !removeEmptyObjects(array).length

  useEffect(() => {
    setIsEmpty(isTableDataEmpty(tableData) && !isLoading)
  }, [tableData, isLoading])

  return (
    <div className={clsx(css.wrapperWithBottomRow, isEmpty && css.isEmpty)}>
      <div className={css.wrapper}>
        {renderSelectionActions && (
          <div className={clsx(css.selectionOverlay, selectedFlatRows.length && css.visible)}>{renderSelectionActions(selectedFlatRows, rows)}</div>
        )}

        <div className={clsx(css.table, renderSelectionActions && css.selectable, isEmpty && css.isEmpty)} {...getTableProps()}>
          {headerGroups.map(headerGroup => {
            const { key, ...rest } = headerGroup.getHeaderGroupProps()

            return (
              <div key={key} {...rest} className={css.tr}>
                {headerGroup.headers.map((column, idx) => {
                  const { key, ...rest } = column.getHeaderProps()

                  return (
                    <div key={key} {...rest} className={css.th}>
                      <ColumnHeader
                        {...column}
                        handleColumnSwap={handleColumnSwap}
                        hasCheckboxRow={Boolean(renderSelectionActions)}
                        idx={idx}
                        loading={isLoading}
                        setActiveDragColumnIdxs={setActiveDragColumnIndices}
                      >
                        {column.render('Header')}
                      </ColumnHeader>

                      {!isLoading && column.getResizerProps && (
                        <div {...column.getResizerProps()} className={clsx(css.resizer, column.isResizing && css.isResizing)} />
                      )}
                    </div>
                  )
                })}
              </div>
            )
          })}

          <div {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)

              const { key, ...rest } = row.getRowProps()

              return (
                <div key={key} {...rest} className={css.tr}>
                  {row.cells.map(cell => {
                    const { key, ...rest } = cell.getCellProps()

                    return (
                      <div key={key} {...rest} className={css.td}>
                        {cell.render('Cell')}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <Pagination pageLength={pageLength} {...pageLengthControls} rowCount={rowCount} />

      {isEmpty && <EmptyState setClearAll={setClearAll} variant={variant} />}
    </div>
  )
}

import { AdvancedDashboardDocument, useDocumentsLazyQuery, usePreAnnotationFormMutation } from '../../graphql/codegen/hooks'
import { Box } from '@mui/system'
import { DocumentTypeSelector } from './DocumentTypeSelector'
import { FiInfo } from 'react-icons/fi'
import { RequeuedDocumentAlert } from '../RequeuedDocumentAlert'
import { getAssignedToMeFilters } from '../../hooks/useAssignedToMe'
import { useFormik } from 'formik'
import { useHistory, useParams } from 'react-router'
import { useIsAnnotator } from '../../hooks/useUserAccess'
import Button from '../Button'
import DocumentPayload from '../DocumentPayload'
import React, { ReactElement, ReactNode, useEffect, useMemo } from 'react'
import WithTooltip from '../WithTooltip'
import css from './style.module.scss'
import useCurrentUser from '../../hooks/useCurrentUser'

export const PreAnnotationChecklist = () => {
  const history = useHistory()
  const { documentId } = useParams<{ documentId: string }>()
  const currentUser = useCurrentUser()
  const [getDocumentData, { data: documentData, loading: isDocumentDataLoading }] = useDocumentsLazyQuery()

  useEffect(() => {
    if (documentId) {
      getDocumentData({ variables: { documentId } })
    }
  }, [documentId, getDocumentData])

  const isAnnotator = useIsAnnotator()

  const isRequeuedForProccessing = useMemo(
    () =>
      isAnnotator &&
      documentData?.documents?.edges?.[0]?.node?.requeued_for_processing &&
      documentData.documents.edges[0].node.internal_status === 'PROCESSING',
    [documentData, isAnnotator]
  )

  const initialValues = { isAttachment: false, isSignature: null, signatureBoundingBox: null, documentTypeId: null }
  const formikProps = useFormik({
    initialValues,
    onSubmit: async values => {
      const { isAttachment, isSignature } = values

      let shouldSubmit = true

      if (isAttachment || isSignature === false) {
        shouldSubmit = await confirm('Are you sure? This will discard the document from the document list.')
      }

      if (!loading && shouldSubmit) {
        preAnnotationFormMutation({ variables: { documentId, ...values } })
      }
    }
  })

  const { handleSubmit, setFieldValue, values } = formikProps
  const { documentTypeId, isAttachment, isSignature } = values
  const canSubmit = isAttachment || isSignature === false || documentTypeId

  useEffect(() => {
    if (isRequeuedForProccessing) {
      setFieldValue('isAttachment', false)
      setFieldValue('isSignature', true)
    }
  }, [isRequeuedForProccessing, setFieldValue])

  const [preAnnotationFormMutation, { loading }] = usePreAnnotationFormMutation({
    onCompleted: ({ pre_annotation_form }) => {
      const mutationSuccessful = pre_annotation_form?.__typename === 'PreAnnotationFormSuccess' // @ts-ignore
      const isDeleted = pre_annotation_form?.document?.is_deleted || undefined // @ts-ignore
      const internalAssignee = pre_annotation_form?.document?.internal_assignee?.id || undefined
      if (mutationSuccessful && (isDeleted || internalAssignee?.id !== currentUser?.id)) {
        history.push('/')
      }
    },
    refetchQueries: [
      {
        query: AdvancedDashboardDocument,
        variables: getAssignedToMeFilters({ currentUser })
      }
    ]
  })

  return (
    <div className={css.panel} style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
      <div style={{ display: 'flex', flexGrow: 1 }}>
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div style={{ padding: 16 }}>
            <h2>{`Pre-annotation checklist`}</h2>

            {isRequeuedForProccessing && (
              <Box mb={1.5} mt={1}>
                <RequeuedDocumentAlert />
              </Box>
            )}

            <p>{`Before annotating this document, please complete the following review.`}</p>

            <DocumentPayload documentId={documentId} />
          </div>

          <ul style={{ padding: 16 }}>
            <Box height={isRequeuedForProccessing ? 0 : 'auto'} visibility={isRequeuedForProccessing ? 'hidden' : 'visible'}>
              <Item
                description="Should this document just be included in the review process, or should it be marked as an attachment?"
                icon={<FiInfo />}
                label="Attachment"
              >
                <div style={{ display: 'flex', alignItems: 'center', margin: '12px 0' }}>
                  <input
                    checked={isAttachment === false}
                    id="isNoAttachment"
                    name="isAttachment"
                    onChange={() => setFieldValue('isAttachment', false)}
                    type="radio"
                    value="isNoAttachment"
                  />

                  <label htmlFor="isNoAttachment" style={{ marginLeft: 8 }}>{`Document is not an attachment`}</label>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', margin: '12px 0' }}>
                  <input
                    checked={isAttachment}
                    id="isAttachment"
                    name="isAttachment"
                    onChange={() => setFieldValue('isAttachment', true)}
                    type="radio"
                    value="isAttachment"
                  />

                  <label htmlFor="isAttachment" style={{ marginLeft: 8 }}>{`Discard as attachment`}</label>
                </div>
              </Item>
            </Box>

            <div
              style={{
                maxHeight: isAttachment === false ? 260 : 0,
                overflow: isAttachment === false ? 'visible' : 'hidden',
                transition: 'all 200ms ease-in-out',
                opacity: isAttachment === false ? 1 : 0
              }}
            >
              <Box height={isRequeuedForProccessing ? 0 : 'auto'} visibility={isRequeuedForProccessing ? 'hidden' : 'visible'}>
                <Item label="Signature">
                  <div style={{ display: 'flex', alignItems: 'center', margin: '12px 0' }}>
                    <input
                      checked={isSignature === true}
                      id="isSignature"
                      name="isSignature"
                      onChange={() => setFieldValue('isSignature', true)}
                      type="radio"
                      value="isSignature"
                    />

                    <label htmlFor="isSignature" style={{ marginLeft: 8 }}>{`Signature is present`}</label>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', margin: '12px 0' }}>
                    <input
                      checked={isSignature === false}
                      id="isNoSignature"
                      name="isSignature"
                      onChange={() => setFieldValue('isSignature', false)}
                      type="radio"
                      value="isNoSignature"
                    />

                    <label htmlFor="isNoSignature" style={{ marginLeft: 8 }}>{`Discard as unsigned`}</label>
                  </div>
                </Item>
              </Box>

              <div
                style={{
                  maxHeight: isSignature ? 260 : 0,
                  overflow: isSignature ? 'visible' : 'hidden',
                  transition: 'all 200ms ease-in-out',
                  opacity: isSignature ? 1 : 0
                }}
              >
                <Item label="Document type">
                  <DocumentTypeSelector
                    menuPlacement="top"
                    onChange={(option: { value: string } | null) => setFieldValue('documentTypeId', option?.value || null)}
                  />
                </Item>
              </div>
            </div>
          </ul>

          <div style={{ padding: '16px' }}>
            <Button
              disabled={!canSubmit || isDocumentDataLoading}
              loading={loading}
              style={{ width: '100%' }}
              variant={(isAttachment || !isSignature) && 'danger'}
            >
              {isAttachment ? 'Discard as attachment' : !isSignature ? 'Discard as unsigned' : 'Submit'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

const Item = ({ children, description, icon, label }: { children: ReactNode; description?: string; icon?: ReactElement; label: string }) => {
  return (
    <li className={css.preAnnotationItem}>
      <div className={css.preAnnotationTitleContainer}>
        <p className={css.preAnnotationItemLabel}>{label}</p>

        {description && (
          <WithTooltip content={description}>
            <>{icon}</>
          </WithTooltip>
        )}
      </div>

      <div className={css.preAnnotationItemContent}>{children}</div>
    </li>
  )
}

Item.defaultProps = {
  icon: <FiInfo />
}

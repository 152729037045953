import { KLARITY_BLUE } from '../../utils/styleUtils'
import { OverridesStyleRules } from '@mui/material/styles/overrides'

// constants

export const COMPONENTS: OverridesStyleRules = {
  MuiButton: {
    styleOverrides: {
      root: ({ ownerState: { color, variant } }: any) => ({
        boxShadow: 'none',
        textTransform: 'none',
        '&:active, &:focus, &:hover': { boxShadow: 'none' },
        '&:focus-visible': { outline: 'revert' },

        ...(variant === 'contained' &&
          color === 'primary' && {
            '&:hover': { backgroundColor: '#004fdb' }
          }),

        ...(variant === 'outlined' && {
          '&': { fontWeight: 500 }
        }),

        ...(variant === 'outlined' &&
          color === 'tertiary' && {
            '&': { borderColor: '#bdbec2' },
            '&:hover': { backgroundColor: '#f3f4f8', borderColor: '#bdbec2' }
          }),

        ...(variant === 'outlined' &&
          color === 'success' && {
            '&': { fontWeight: 500 }
          }),

        ...(variant === 'outlined' &&
          color === 'error' && {
            '&': { fontWeight: 500 }
          })
      })
    }
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true
    }
  },
  MuiChip: {
    defaultProps: {
      size: 'small'
    },
    styleOverrides: {
      root: { borderRadius: 4, px: 0.5 }
    }
  },
  MuiDialogActions: {
    styleOverrides: {
      root: { gap: '4px', margin: '18px 24px 18px 0', padding: 0 }
    }
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: { borderBottom: '1px solid rgb(0, 0, 0, 0.12)', fontWeight: 700, margin: '18px 24px', padding: '0 0 12px 0' }
    }
  },
  MuiIconButton: {
    styleOverrides: {
      root: { outline: 'revert' }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        paddingLeft: 9
      },
      root: {
        minHeight: 38
      }
    }
  },
  MuiScopedCssBaseline: {
    styleOverrides: {
      root: { WebkitFontSmoothing: 'auto' }
    }
  },
  MuiTab: {
    defaultProps: {
      iconPosition: 'start'
    },
    styleOverrides: {
      root: {
        fontWeight: 600,
        height: 48,
        minHeight: 48,
        textTransform: 'none',
        '&:focus-visible': { outline: 'revert' },
        '&.Mui-selected': { color: KLARITY_BLUE },
        '& .MuiTab-iconWrapper': { color: 'inherit', height: 16, marginRight: 8, width: 16 }
      }
    }
  },
  MuiTabs: {
    defaultProps: {
      variant: 'scrollable'
    },
    styleOverrides: {
      root: {
        '& .MuiTabs-flexContainer': { alignItems: 'flex-end', maxHeight: 48 },
        '& .MuiTabs-indicator': { backgroundColor: KLARITY_BLUE }
      },
      scrollButtons: {
        opacity: 1,
        width: 16,
        zIndex: 1,
        '&:first-of-type > svg': { marginLeft: 8, opacity: 0.8 },
        '&:first-of-type::before': {
          background: 'linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 50%, #fff 100%)',
          content: '""',
          height: 'calc(100% - 2px)',
          position: 'absolute',
          right: -16,
          top: 0,
          width: 16
        },
        '&:last-of-type > svg': { marginRight: 8, opacity: 0.8 },
        '&:last-of-type::before': {
          background: 'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 50%, #fff 100%)',
          content: '""',
          height: 'calc(100% - 2px)',
          left: -16,
          position: 'absolute',
          top: 0,
          width: 16
        }
      }
    }
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        fontSize: 13,
        padding: '3px 8px',
        textTransform: 'none',
        '&:focus-visible': { outline: 'revert' }
      }
    }
  },
  MuiTooltip: {
    defaultProps: {
      enterDelay: 150,
      enterNextDelay: 50
    }
  }
}

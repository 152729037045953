import { ActionTypeInput, ActionTypes, getActionTypeId } from './inputs/ActionTypeInput'
import { AdvancedSettings } from './AdvancedSettings'
import { Alert, Box } from '@mui/material'
import { ChecklistGptButton } from './inputs/ChecklistGptButton'
import { ConfigurationDetailsFooter } from './ConfigurationDetailsFooter'
import { ConfigurationSteps, Steps } from './ConfigurationSteps'
import { DataTableMatchRules, MatchRule, MatchRules } from '../ChecklistTab/SharedInputs/MatchRule'
import { EmptyStateMessage } from '../../../../../../components/EmptyStateMessage'
import { ExternalExtractionConfigurationAccordion } from '../ChecklistTab/Accordions/ExternalExtractionConfigurationAccordion'
import { ExternalSourceInput } from './inputs/ExternalSourceInput'
import { ExtractionFieldTypes } from '../ChecklistTab/SharedInputs/ExtractionFieldTypeInput'
import { Features, Permissions, useUserAccess } from '../../../../../../hooks/useUserAccess'
import { FieldOptions } from './inputs/FieldOptions'
import { FieldSemanticsInput } from './inputs/FieldSemanticsInput'
import { FieldTypeInput, FieldTypes } from './inputs/FieldTypeInput'
import { GroupInput } from './inputs/GroupInput'
import { MatchFields } from '../ChecklistTab/SharedInputs/MatchFields'
import { MatchPrompt } from '../ChecklistTab/SharedInputs/MatchPrompt'
import { NameInput } from './inputs/NameInput'
import { Sources } from '../ChecklistTab/CreateInputs/SourceInput'
import { TableCompositionInput } from './inputs/TableCompositionInput'
import { VerifiedSamples } from './VerifiedSamples'
import { isEmpty } from 'lodash'
import { useCciMainContext } from '../../../../CCI_Main'
import { useChecklistConfigContext } from '../../../ChecklistConfigProvider'
import { useMatchingChildrenDataPointFieldsQuery } from '../../../../../../graphql/codegen/hooks'
import React, { FC, useEffect, useMemo, useRef } from 'react'
import css from './style.module.scss'

// hooks

const useIsMatchRuleReadOnly = () => {
  const { fieldValues, isEditView } = useChecklistConfigContext()
  const { match_children_fields: matchChildrenFields, source } = fieldValues || {}

  const { data: matchingChildrenData } = useMatchingChildrenDataPointFieldsQuery({ skip: isEditView })

  const isMatchRuleReadOnly = useMemo(() => {
    if (!matchingChildrenData || !matchChildrenFields || source !== Sources.MATCHING) return false

    const fieldType = matchingChildrenData.matching_children_data_point_fields?.edges.find(
      dataPointField => matchChildrenFields[0]?.data_point_field_id === dataPointField?.node?.id
    )?.node?.field_type

    return fieldType !== FieldTypes.TEXT && fieldType !== FieldTypes.DATA_TABLE
  }, [matchingChildrenData, matchChildrenFields, source])

  return isMatchRuleReadOnly
}

// components

export const ConfigureField: FC = () => {
  const { selectedItem, setSelectedItem } = useCciMainContext()
  const { actionTypeMap, activeStep, fieldValues, isCreateView, isEditView, setActiveStep, setFieldValues, updateFieldValue } = useChecklistConfigContext()

  const {
    action_type_id: actionTypeId,
    field_semantics: fieldSemantics,
    field_type: fieldType,
    match_fields: matchFields,
    match_rule: matchRule,
    matchFieldType,
    source
  } = fieldValues || {}

  const hasCCIExtractionConfigurationAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_EXTRACTION_CONFIGURATION })
  const isExternalSource = selectedItem?.source === 'EXTERNAL' && selectedItem?.external_source !== 'CALCULATION'
  const isIntegrationActionType = actionTypeId === getActionTypeId(actionTypeMap, ActionTypes.INTEGRATION)
  const isInternalSource = selectedItem?.source === 'INTERNAL'
  const isMatchRuleReadOnly = useIsMatchRuleReadOnly()
  const isPreAnnotationExtractionType = Boolean(selectedItem?.extraction_field_type?.includes(ExtractionFieldTypes['PRE-ANNOTATION']))
  const isPreviewWithSamplesDisabled = isCreateView || !isInternalSource || isPreAnnotationExtractionType

  const isTableComposition =
    actionTypeId === getActionTypeId(actionTypeMap, ActionTypes.TABLE_COMPOSITION) ||
    selectedItem?.action_type?.extraction_field_type === ExtractionFieldTypes['TABLE-COMPOSITION']

  const configurationDetailsRef = useRef<HTMLDivElement>(null)

  // effects – create view

  useEffect(() => {
    if (isCreateView && source !== Sources.MATCHING) {
      updateFieldValue('match_children_fields', null)
      updateFieldValue('match_rule', null)
    }
  }, [isCreateView, source, updateFieldValue])

  useEffect(() => {
    if (isCreateView && source === Sources.MATCHING && fieldType !== FieldTypes.BOOLEAN) {
      updateFieldValue('field_type', FieldTypes.BOOLEAN)
    }
  }, [fieldType, isCreateView, source, updateFieldValue])

  useEffect(() => {
    if (isCreateView) {
      updateFieldValue('match_rule', isMatchRuleReadOnly ? MatchRules.STRICT_EQUALITY : null)
    }
  }, [isCreateView, isMatchRuleReadOnly, updateFieldValue])

  // effects – edit view

  useEffect(() => {
    if (isEditView) {
      setFieldValues(undefined)
    }
  }, [isEditView, selectedItem?.id, setFieldValues])

  useEffect(() => {
    if (isEditView) {
      setActiveStep(isPreviewWithSamplesDisabled ? Steps.CONFIGURATION_DETAILS : Steps.PREVIEW_WITH_SAMPLES)
    }
  }, [selectedItem?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  // effects – shared

  useEffect(() => {
    if (isCreateView) {
      setActiveStep(Steps.CONFIGURATION_DETAILS)
      setSelectedItem('')
    }

    setFieldValues(undefined)
  }, [isCreateView, setActiveStep, setFieldValues, setSelectedItem])

  return (
    <>
      {isCreateView || Boolean(selectedItem) ? (
        <Box className={css.editField} key={isCreateView + selectedItem?.id + activeStep} sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <ConfigurationSteps />

          {activeStep === Steps.CONFIGURATION_DETAILS ? (
            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', mt: 3 }}>
              {isEditView && (
                <Alert severity="info" sx={{ mb: 3 }}>
                  Some field configuration settings cannot be edited because they are used to generate samples for review. To make changes, please create a new
                  field.
                </Alert>
              )}

              <Box ref={configurationDetailsRef} sx={{ display: 'flex', flex: 1, flexDirection: 'column', pb: 2.5, position: 'relative' }}>
                <NameInput />

                <ActionTypeInput />

                {isCreateView && isIntegrationActionType && !isEmpty(actionTypeMap) && <ExternalSourceInput />}

                <GroupInput />

                {isCreateView && <ChecklistGptButton />}

                {(source || selectedItem?.source) && (
                  <>
                    {(source === Sources.INTERNAL || selectedItem?.source === Sources.INTERNAL) && (
                      <FieldSemanticsInput handleData={updateFieldValue} value={fieldSemantics || selectedItem.field_semantics || ''} />
                    )}

                    <FieldTypeInput key={actionTypeId} />

                    <FieldOptions />

                    {(source === Sources.MATCHING || selectedItem?.source === Sources.MATCHING) && (
                      <>
                        {isCreateView ? (
                          <>
                            <MatchFields handleData={updateFieldValue} list={matchFields?.edges} />

                            <MatchRule fieldType={matchFieldType} handleData={updateFieldValue} isCreateViewReadOnly={isMatchRuleReadOnly} rule={matchRule} />
                          </>
                        ) : (
                          <>
                            <MatchFields
                              isReadOnly
                              key={JSON.stringify(selectedItem.match_config.match_fields.edges)}
                              list={selectedItem.match_config.match_fields.edges}
                            />

                            <MatchRule isEditView rule={selectedItem.match_config.rule} />

                            {[
                              DataTableMatchRules.TABLE_MATCHING,
                              DataTableMatchRules.TABLE_MATCHING_ALPHA,
                              DataTableMatchRules.TABLE_MATCHING_BETA,
                              DataTableMatchRules.TABLE_MATCHING_GAMMA,
                              DataTableMatchRules.TABLE_MATCHING_O1_2024_12_17,
                              DataTableMatchRules.TABLE_MATCHING_SONNET_V2_OCT_2024
                            ].includes(selectedItem.match_config.rule) && (
                              <MatchPrompt handleData={updateFieldValue} ruleConfig={selectedItem.match_config.rule_config} />
                            )}
                          </>
                        )}
                      </>
                    )}

                    {selectedItem && hasCCIExtractionConfigurationAccess && isExternalSource && (
                      <ExternalExtractionConfigurationAccordion handleData={updateFieldValue} isChecklistGpt />
                    )}

                    {isTableComposition && <TableCompositionInput />}

                    <AdvancedSettings />
                  </>
                )}
              </Box>

              <ConfigurationDetailsFooter />
            </Box>
          ) : (
            <VerifiedSamples />
          )}
        </Box>
      ) : (
        <EmptyStateMessage message="Select an item from the left panel." />
      )}
    </>
  )
}

import { Tooltip } from '@mui/material'
import { grey } from '@mui/material/colors'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import React, { FC, ReactNode } from 'react'

// types

type _TooltipInfoProps = { tooltipTitle: NonNullable<ReactNode> }

// components

export const TooltipInfo: FC<_TooltipInfoProps> = ({ tooltipTitle }) => (
  <Tooltip arrow placement="right" title={tooltipTitle}>
    <InfoOutlinedIcon sx={{ color: grey[500], fontSize: 16, ml: 0.5, mt: 0.25, position: 'absolute' }} />
  </Tooltip>
)

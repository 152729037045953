import { Box } from '@mui/material'
import React, { FC } from 'react'

// types

type _InReviewCellProps = { inReviewSamples: number }

// components

export const InReviewCell: FC<_InReviewCellProps> = ({ inReviewSamples }) => <Box>{inReviewSamples}</Box>

import { Box } from '@mui/material'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { useIsGoogleTenant } from '../../../hooks/useIsGoogleTenant'
import React, { FC, useMemo } from 'react'
import clsx from 'clsx'
import css from './style.module.scss'

// types

type _PaginationProps = {
  currentPage?: number
  jumpToPage?: (p: number) => void
  nextPage?: () => void
  pageCount?: number
  pageLength?: number
  prevPage?: () => void
  rowCount?: number
  setPageLength?: (pl: number) => void
}

// constants

const BASE_PAGE_LENGTHS = [10, 25, 50]
export const PAGE_LENGTH_PREFERENCE_KEY = 'table_pageLength_preference'

// hooks

export const usePageLengths = () => {
  const isGoogleTenant = useIsGoogleTenant()

  return useMemo(() => [...BASE_PAGE_LENGTHS, ...(isGoogleTenant ? [100] : [])], [isGoogleTenant])
}

// components

export const Pagination: FC<_PaginationProps> = ({ currentPage, jumpToPage, nextPage, pageCount, pageLength, prevPage, rowCount, setPageLength }) => {
  const PAGE_LENGTHS = usePageLengths()

  const pages = Array.from(new Array(pageCount)).map((_, index) => ++index)
  const pageNumbers = getPageNumbers(pages, currentPage)

  const pageLengthOptions = PAGE_LENGTHS.filter((_, index) => !PAGE_LENGTHS[index - 1] || (rowCount || 0) > PAGE_LENGTHS[index - 1])

  const handlePageLength = (newPageLength: number) => {
    setPageLength && setPageLength(newPageLength)
    localStorage.setItem(PAGE_LENGTH_PREFERENCE_KEY, newPageLength.toString())
  }

  if (!currentPage || !pageCount || !rowCount) {
    return <Box sx={{ minHeight: 48 }} />
  }

  return (
    <div className={css.pagination}>
      {pageLengthOptions.length > 0 && (
        <div className={css.pageLengthInput}>
          <span>View</span>

          {pageLengthOptions.map((option: number) => {
            const isSelected = option === pageLengthOptions.findLast(option => option <= (pageLength || 0))

            return (
              <button className={clsx(isSelected && css.selected)} key={String(option)} onClick={() => handlePageLength(option)}>
                {String(Math.min(option, rowCount || option))}
              </button>
            )
          })}

          {!!rowCount && <span>{`of ${rowCount} results`}</span>}
        </div>
      )}

      <div className={css.pageNumberInput}>
        <button aria-label="Previous page" onClick={prevPage} style={{ visibility: currentPage > 1 ? 'visible' : 'hidden' }}>
          <ChevronLeft />
        </button>

        {jumpToPage &&
          pageNumbers.length > 1 &&
          pageNumbers.map(n => (
            <button className={clsx(currentPage === n && css.selected)} key={n} onClick={() => jumpToPage(n)}>
              {n}
            </button>
          ))}

        <button aria-label="Next page" onClick={nextPage} style={{ visibility: currentPage < pageCount ? 'visible' : 'hidden' }}>
          <ChevronRight />
        </button>
      </div>
    </div>
  )
}

const getPageNumbers = (pages: number[], currentPage: number = 1, sliceLength: number = 5): number[] => {
  if (pages.length <= sliceLength) {
    return pages.slice()
  }
  // Ensure an even number for the sides as we’re looking to have a odd center in the slice
  const half = Math.floor(sliceLength / 2)
  // Convert to 0 based
  const index = --currentPage
  let start = index - half
  let end = index + half
  // Offset start when necessary
  if (start < 0) {
    end += 0 - start
    start = 0
  }
  // Offset end when necessary
  const lastIndex = pages.length - 1
  if (end > lastIndex) {
    start -= end - lastIndex
    end = lastIndex
  }
  // End must be inclusive
  return pages.slice(start, ++end)
}

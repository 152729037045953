import { Chip } from '@mui/material'
import { STATUS_VALUE_LABEL_MAP, Statuses } from '../../../../../utils/cci'
import { common, lightGreen, purple, red, yellow } from '@mui/material/colors'
import React, { FC, useMemo } from 'react'

// types

type _StatusCellProps = { mainStatus: Statuses | string }

// components

export const StatusCell: FC<_StatusCellProps> = ({ mainStatus }) => {
  const chipProps = useMemo(() => {
    switch (mainStatus) {
      case Statuses.DRAFT:
        return { bgcolor: purple[50], color: common.black }
      case Statuses.IN_REVIEW:
        return { bgcolor: yellow[200], color: common.black }
      case Statuses.PUBLISHED:
        return { bgcolor: lightGreen[100], color: common.black }
      default:
        return { bgcolor: red[50], color: common.black }
    }
  }, [mainStatus])

  const { bgcolor, color } = chipProps

  if (!mainStatus) return null

  return <Chip label={STATUS_VALUE_LABEL_MAP.get(mainStatus)} sx={{ bgcolor, color, fontSize: 12 }} />
}

import { DocumentPageWrapper } from '../../components/DocumentPageWrapper'
import { useDocumentsQuery } from '../../graphql/codegen/hooks'
import { useIsAnnotator, useIsSuperAnnotator } from '../../hooks/useUserAccess'
import { usePageLoadingQueue } from '../../hooks/usePageLoadingQueue'
import { useParams } from 'react-router-dom'
import DataPointPanelContainer from '../../containers/DocumentDataPointPanelContainer'
import DocumentPageHeader from './DocumentPageHeader'
import ErrorPage from '../ErrorPage/ErrorPage'
import Loader from '../../components/Loader'
import React, { useCallback } from 'react'
import useCurrentUser from '../../hooks/useCurrentUser'
import useIsPreAnnot from '../../hooks/useIsPreAnnot'

export default function DocumentPage() {
  const { isPreAnnot, loading } = useIsPreAnnot()
  const isAnnotator = useIsAnnotator()
  const isSuperAnnot = useIsSuperAnnotator()
  const currentUser = useCurrentUser()
  const { documentId } = useParams<{ documentId: string }>()

  const [shouldLoadPage, setShouldLoadNextPage] = usePageLoadingQueue()
  const { data, error, loading: documentLoading } = useDocumentsQuery({ skip: !documentId || !shouldLoadPage, variables: { documentId } })

  const document = data?.documents?.edges?.[0]?.node
  const { alias, counter_party, created_at, created_by, integration_type, internal_assignee, internal_state, internal_status, name, state } = document || {}

  const handleChecklistDataLoaded = useCallback(() => setShouldLoadNextPage(true), [setShouldLoadNextPage])

  if (!shouldLoadPage || loading || documentLoading) return <Loader />

  if (error?.message) return <ErrorPage message={`Document not found.\n\n${error.message}`} />

  if (!document) return <ErrorPage message="You do not have access to this document." />

  return (
    <DocumentPageWrapper
      dataPointPanel={<DataPointPanelContainer isPreAnnot={isPreAnnot} onChecklistDataLoaded={handleChecklistDataLoaded} />}
      documents={[document]}
      headerComponent={
        <DocumentPageHeader
          alias={alias || undefined}
          counter_party={counter_party}
          created_at={created_at}
          internal_assignee={internal_assignee}
          internal_state={internal_state}
          name={name}
          sourceInfo={{ integration_type, created_at, created_by }}
          state={state}
        />
      }
      loading={documentLoading}
      name={alias || name}
      noChecklist={internal_status === 'COMPLETE' ? false : isAnnotator && !isSuperAnnot && internal_assignee?.id !== currentUser?.id}
    />
  )
}

import { Source } from '../../../../../components/DatapointField/Source'
import { grey } from '@mui/material/colors'
import PersonIcon from '@mui/icons-material/Person'
import React, { FC } from 'react'

// types

type _SystemSourceCellProps = { externalSource?: string; source: string }

// components

export const SystemSourceCell: FC<_SystemSourceCellProps> = ({ externalSource, source }) => (
  <>
    {[externalSource, source].includes('MANUAL') ? (
      <PersonIcon sx={{ bgcolor: grey[200], borderRadius: 1, display: 'block' }} />
    ) : (
      <Source external_source={externalSource} source={source} style={{ margin: 0 }} />
    )}
  </>
)

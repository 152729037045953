import { ActiveComponents, useCciMainContext } from '../CCI_Main'
import { Box, Button } from '@mui/material'
import { ConfigNavLinkLabels } from '../../../components/NavBar/ConfigMenu'
import { Features, Permissions, useUserAccess } from '../../../hooks/useUserAccess'
import { Search } from '../../../components/Search'
import { useLocation } from 'react-router-dom'
import React, { FC, useCallback, useMemo } from 'react'

// constants

const HEIGHT = 70
const MARGIN_TOP = -2

export const CONFIG_HEADER_HEIGHT = HEIGHT + MARGIN_TOP * 8

// components

export const Header: FC = () => {
  const location = useLocation()
  const { fieldOverlay, setActiveComponent } = useCciMainContext()
  const hasChecklistCreateAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.CREATE_FIELD })
  const hasLimitedChecklistCreateAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.CREATE_FIELD_LIMITED })

  const buttonSx = useMemo(
    () => ({ visibility: hasChecklistCreateAccess || hasLimitedChecklistCreateAccess ? 'visible' : 'hidden' }),
    [hasChecklistCreateAccess, hasLimitedChecklistCreateAccess]
  )

  const handleClick = useCallback(() => {
    fieldOverlay.open()
    setActiveComponent(ActiveComponents.CREATE_FIELD)
  }, [fieldOverlay, setActiveComponent])

  const currentConfigLabel = ConfigNavLinkLabels[location.pathname] || 'Configuration'
  const showAddButton = location.pathname === '/config'

  return (
    <Box
      sx={{
        alignItems: 'center',
        borderBottom: 1,
        borderColor: 'divider',
        display: 'flex',
        height: HEIGHT,
        mt: MARGIN_TOP,
        whiteSpace: 'nowrap'
      }}
    >
      <Box sx={{ display: 'flex', flex: '1 1 0', fontSize: 30, fontWeight: 600, justifyContent: 'flex-start', pl: 2 }}>{currentConfigLabel}</Box>

      <Box sx={{ display: 'flex', flex: '0 1 600px', justifyContent: 'center' }}>
        <Search sx={{ width: '100%' }} />
      </Box>

      <Box sx={{ display: 'flex', flex: '1 1 0', justifyContent: 'flex-end', pr: 2 }}>
        {showAddButton && (
          <Button onClick={handleClick} sx={buttonSx} variant="contained">
            Add field
          </Button>
        )}
      </Box>
    </Box>
  )
}

import { ActionType } from '../../../graphql/codegen/schemas'
import { ActiveComponents, useCciMainContext } from '../CCI_Main'
import { CciDealDataPointFieldsQuery } from '../../../graphql/codegen/operations'
import { Features, Permissions, useUserAccess } from '../../../hooks/useUserAccess'
import { Row } from 'react-table'
import { Statuses } from '../../../utils/cci'
import { Steps } from './RightPanel/RightPanel_components/ChecklistGptTab/ConfigurationSteps'
import {
  _ExtractionConfigurationChanges,
  useExtractionConfiguration
} from './RightPanel/RightPanel_components/ChecklistGptTab/hooks/useExtractionConfiguration'
import { size } from 'lodash'
import {
  useActionsToExtractionMethodMappingQuery,
  useCciDealDataPointFieldsQuery,
  useCciDocumentTypesQuery,
  useCciGroupsQuery,
  useReorderChecklistItemMutation,
  useReorderGroupMutation
} from '../../../graphql/codegen/hooks'
import { useAppContext } from '../../../app'
import { useContextInit } from '../../../hooks/useContextInit'
import React, { Dispatch, FC, ReactNode, SetStateAction, createContext, useCallback, useEffect, useMemo, useState } from 'react'

// types

type _ActionTypeMap = { [key: string]: ActionType }

type _ChecklistConfigContext = {
  actionTypeMap: _ActionTypeMap
  actionTypeMapLoading?: boolean
  activeStep?: number
  areAllGroupsCollapsed: boolean
  createExtractionConfiguration: (dataPointFieldId: string) => void
  docTypesLoading: boolean
  documentTypes?: any
  editExtractionConfiguration: () => void
  extractionMethodId?: string
  extractionMethodList: { label: string; value: string }[]
  fieldValues?: { [key: string]: any }
  fieldsData?: CciDealDataPointFieldsQuery
  filterGroup: (row: Row<_Row>, groupName: string) => boolean
  filterSearchTerm: (row: Row<_Row>) => boolean
  groupOptions?: any
  isBusy: boolean
  isCreateView: boolean
  isEditView?: boolean
  isGroupCollapsedMap: _IsCollapsedGroupMap
  isGroupsDataLoading: boolean
  isReorderingEnabled: boolean
  isSingleGroupExpanded: boolean
  reorderField: (_: { variables: { data_point_field_id: string; move_after_data_point_field_id?: string; move_to_top_group?: string } }) => void
  reorderGroup: (_: { variables: { group_name: string; move_after_group_name: string | null } }) => void
  setActiveStep: Dispatch<SetStateAction<number | undefined>>
  setExtractionConfigurationChanges: (changes: _ExtractionConfigurationChanges | null) => void
  setFieldValues: Dispatch<SetStateAction<{ [key: string]: any } | undefined>>
  setIsGroupCollapsedMap: Dispatch<SetStateAction<_IsCollapsedGroupMap>>
  updateFieldValue: (fieldName: string, value: any) => void
}

type _ChecklistConfigProviderProps = { children: ReactNode }
type _IsCollapsedGroupMap = { [key: string]: boolean }

export type _Row = {
  actionTypeColumn: { actionTypeId: string }
  correctColumn: { correctSamples: number }
  fieldNameColumn: { description: string; id: string; isDeleted: boolean; name: string; priority: number; status: string }
  fieldTypeColumn: { fieldType: string }
  group: string
  id: string
  inReviewColumn: { inReviewSamples: number }
  name: string
  priority: number
  samplesColumn: { totalSamples: number }
  statusColumn: { mainStatus: Statuses | string }
  systemSourceColumn: { externalSource: string; source: string }
}

// context

export const ChecklistConfigContext = createContext<_ChecklistConfigContext | null>(null)

// hooks

export const useChecklistConfigContext = () => useContextInit(ChecklistConfigContext)

// components

export const ChecklistConfigProvider: FC<_ChecklistConfigProviderProps> = ({ children }) => {
  const { data: actionsToExtractionMethodMappingData } = useActionsToExtractionMethodMappingQuery()
  const { searchTerm, setIsSearchDataLoading, setSearchPlaceholder } = useAppContext()
  const { data: fieldsData, loading: isFieldsDataLoading } = useCciDealDataPointFieldsQuery()
  const { activeComponent, selectedItem } = useCciMainContext()

  const { createExtractionConfiguration, editExtractionConfiguration, extractionMethodId, extractionMethodList, setExtractionConfigurationChanges } =
    useExtractionConfiguration()

  const [reorderField, { loading: isReorderFieldPending }] = useReorderChecklistItemMutation()
  const [reorderGroup, { loading: isReorderGroupPending }] = useReorderGroupMutation()
  const [activeStep, setActiveStep] = useState<number | undefined>(Steps.CONFIGURATION_DETAILS)
  const [documentTypes, setDocumentTypes] = useState<any>(false)
  const [fieldValues, setFieldValues] = useState<{ [key: string]: any } | undefined>(undefined)
  const [groupOptions, setGroupOptions] = useState<any>(false)
  const [isGroupCollapsedMap, setIsGroupCollapsedMap] = useState<_IsCollapsedGroupMap>({})
  const [isSearching, setIsSearching] = useState(false)

  const hasFullEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD })
  const hasLimitedEditAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.EDIT_FIELD_LIMITED })
  const hasReorderingAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.REORDER })

  const isCreateView = activeComponent === ActiveComponents.CREATE_FIELD
  const isEditView = !isCreateView

  const { data: docTypesData, loading: docTypesLoading } = useCciDocumentTypesQuery({ skip: !hasFullEditAccess && !hasLimitedEditAccess })
  const { data: groupsData, loading: isGroupsDataLoading } = useCciGroupsQuery({ skip: !hasFullEditAccess && !hasLimitedEditAccess })

  const actionTypeMap = useMemo<_ActionTypeMap>(
    () =>
      actionsToExtractionMethodMappingData?.actions_to_extraction_method_mapping?.reduce(
        (previous, current) => (current ? { ...previous, [current.id]: current } : previous),
        {}
      ) || {},
    [actionsToExtractionMethodMappingData]
  )

  const areAllGroupsCollapsed = useMemo(() => Object.values(isGroupCollapsedMap).every(value => value), [isGroupCollapsedMap])
  const filterGroup = useCallback(({ original: { group } }: Row<_Row>, groupName: string) => group === groupName, [])

  const filterSearchTerm = useCallback(
    ({ original: { name } }: Row<_Row>) => !searchTerm || name.toLowerCase().includes(searchTerm.toLowerCase()),
    [searchTerm]
  )

  const isBusy = useMemo(
    () => isFieldsDataLoading || isGroupsDataLoading || isReorderFieldPending || isReorderGroupPending,
    [isFieldsDataLoading, isGroupsDataLoading, isReorderFieldPending, isReorderGroupPending]
  )

  const isReorderingEnabled = useMemo(() => hasReorderingAccess && !searchTerm, [hasReorderingAccess, searchTerm])
  const isSingleGroupExpanded = useMemo(() => size(Object.values(isGroupCollapsedMap).filter(value => !value)) === 1, [isGroupCollapsedMap])

  useMemo(() => {
    if (!isSearching && searchTerm) {
      setIsGroupCollapsedMap({})

      setIsSearching(true)
    } else if (!searchTerm) {
      setIsSearching(false)
    }
  }, [searchTerm]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => setIsSearchDataLoading(isFieldsDataLoading), [isFieldsDataLoading, setIsSearchDataLoading])
  useEffect(() => setSearchPlaceholder(isFieldsDataLoading ? 'Loading checklist…' : 'Search checklist…'), [isFieldsDataLoading, setSearchPlaceholder])

  const updateFieldValue = useCallback((fieldName: string, value: any) => {
    if (fieldName && !value && value !== false) {
      setFieldValues(previous => {
        const updatedObject = { ...previous }

        delete updatedObject[fieldName]

        return { ...updatedObject }
      })
    } else {
      switch (fieldName) {
        case '':
        case undefined:
        case null:
          console.error('ConfigureField updateFieldValue error: Must provide a valid option')
          return
        case 'deleted_options':
          setFieldValues(previous => ({ ...previous, deleted_options: [...value] }))
          return
        case 'options':
          setFieldValues(previous => ({ ...previous, options: [...value] }))
          return
        default:
          setFieldValues(previous => ({ ...previous, [fieldName]: value }))
      }
    }
  }, [])

  const context = useMemo<_ChecklistConfigContext>(
    () => ({
      actionTypeMap,
      activeStep,
      areAllGroupsCollapsed,
      createExtractionConfiguration,
      docTypesLoading,
      documentTypes,
      editExtractionConfiguration,
      extractionMethodId,
      extractionMethodList,
      fieldValues,
      fieldsData,
      filterGroup,
      filterSearchTerm,
      groupOptions,
      isBusy,
      isCreateView,
      isEditView,
      isGroupCollapsedMap,
      isGroupsDataLoading,
      isReorderingEnabled,
      isSingleGroupExpanded,
      reorderField,
      reorderGroup,
      setActiveStep,
      setExtractionConfigurationChanges,
      setFieldValues,
      setIsGroupCollapsedMap,
      updateFieldValue
    }),
    [
      actionTypeMap,
      activeStep,
      areAllGroupsCollapsed,
      createExtractionConfiguration,
      docTypesLoading,
      documentTypes,
      editExtractionConfiguration,
      extractionMethodId,
      extractionMethodList,
      fieldValues,
      fieldsData,
      filterGroup,
      filterSearchTerm,
      groupOptions,
      isBusy,
      isCreateView,
      isEditView,
      isGroupCollapsedMap,
      isGroupsDataLoading,
      isReorderingEnabled,
      isSingleGroupExpanded,
      reorderField,
      reorderGroup,
      setExtractionConfigurationChanges,
      updateFieldValue
    ]
  )

  useEffect(() => setDocumentTypes(docTypesData?.cci_document_types?.map(item => ({ label: item?.name, value: item?.name }))), [docTypesData])
  useEffect(() => setFieldValues(undefined), [activeComponent, selectedItem])
  useEffect(() => setGroupOptions(groupsData?.cci_groups?.map(item => ({ value: item, label: item }))), [groupsData])

  return <ChecklistConfigContext.Provider value={context}>{children}</ChecklistConfigContext.Provider>
}

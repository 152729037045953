import { Box } from '@mui/material'
import React, { FC } from 'react'

// types

type _SamplesCellProps = { totalSamples: number }

// components

export const SamplesCell: FC<_SamplesCellProps> = ({ totalSamples }) => <Box>{totalSamples}</Box>

import '@mescius/spread-sheets-charts'
import '@mescius/spread-sheets-io'
import '@mescius/spread-sheets-shapes'
import '@mescius/spread-sheets/styles/gc.spread.sheets.excel2013white.css'
import 'react-resizable/css/styles.css'
import { Box, Typography } from '@mui/material'
import { Cursors } from '../Resizable/Resizable'
import { Resizable, ResizeCallbackData } from 'react-resizable'
import { SPREADJS_LICENSE_KEY } from './spreadJsLicenseKey'
import { SpreadSheets, Worksheet } from '@mescius/spread-sheets-react'
import { SpreadsheetErrorBoundary } from './SpreadsheetErrorBoundary'
import { Z_INDEX_1 } from '../../utils/styleUtils'
import { captureError } from '../../utils/sentry'
import { grey } from '@mui/material/colors'
import { useAppContext } from '../../app'
import GC from '@mescius/spread-sheets'
import Loader from '../Loader/Loader'
import React, { FC, MutableRefObject, SyntheticEvent, forwardRef, useCallback, useEffect, useRef, useState } from 'react'

GC.Spread.Sheets.LicenseKey = SPREADJS_LICENSE_KEY

// types

type _SpreadJsImportError = { errorCode: number; errorMessage: string }

type _SpreadsheetFileViewerProps = { file: File; id: string }

type _ToolbarHandleProps = { cursor: Cursors; handleAxis?: unknown }

type _ToolbarProps = {
  cellInfo: { formula?: string; value?: string } | null
  setToolbarHeight: (height: number) => void
  toolbarHeight: number
  workbookRef: MutableRefObject<GC.Spread.Sheets.Workbook | null>
}

// constants

const MAX_TOOLBAR_HEIGHT = 300

const MIN_TOOLBAR_HEIGHT = 48

const RESIZABLE_HANDLE_HEIGHT = 4

const STATUS_BAR_HEIGHT = 25

const WORKBOOK_OPTION_OVERRIDES = {
  allowCopyPasteExcelStyle: false,
  allowSheetReorder: false,
  allowUserDragDrop: false,
  allowUserDragFill: false,
  allowUserDragMerge: false,
  allowUserEditFormula: false,
  newTabVisible: false,
  tabEditable: false
}

const WORKSHEET_OPTION_OVERRIDES = { isProtected: true, protectionOptions: { allowResizeColumns: true, allowResizeRows: true } }

// functions

const getCellInfo = (sheet: GC.Spread.Sheets.Worksheet, row = 0, col = 0) => {
  const formula = sheet.getFormula(row, col)
  const value = sheet.getValue(row, col)

  return { formula: formula ? `=${formula}` : undefined, value: value?.toString() }
}

// components

export const SpreadsheetFileViewer: FC<_SpreadsheetFileViewerProps> = ({ file, id }) => {
  const { setErrorMessage } = useAppContext()

  const [cellInfo, setCellInfo] = useState<{ formula?: string; value?: string } | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [toolbarHeight, setToolbarHeight] = useState(MIN_TOOLBAR_HEIGHT)

  const workbookRef = useRef<GC.Spread.Sheets.Workbook | null>(null)

  const handleImportError = useCallback(
    (error: _SpreadJsImportError) => {
      captureError(error)

      setErrorMessage(error.errorMessage)
    },
    [setErrorMessage]
  )

  const handleImportSuccess = useCallback(() => {
    if (!workbookRef.current) return

    workbookRef.current.options = { ...workbookRef.current.options, ...WORKBOOK_OPTION_OVERRIDES }

    const sheetCount = workbookRef.current.getSheetCount()

    for (let i = 0; i < sheetCount; i++) {
      const worksheet = workbookRef.current.getSheet(i)

      worksheet.options = { ...worksheet.options, ...WORKSHEET_OPTION_OVERRIDES }

      // Set `cellInfo` to the first cell on the first sheet on initialization.
      if (i === 0) setCellInfo(getCellInfo(worksheet))

      // Set `cellInfo` to the selected cell when the selection changes.
      worksheet.bind(GC.Spread.Sheets.Events.SelectionChanged, (_: unknown, args: GC.Spread.Sheets.ISelectionChangedEventArgs) => {
        const sheet = args.sheet
        const selection = args.newSelections[0]

        if (selection) {
          const { col, row } = selection

          setCellInfo(getCellInfo(sheet, row, col))
        }
      })
    }

    // Set `cellInfo` to the first cell on the active sheet when the active sheet changes.
    workbookRef.current.bind(GC.Spread.Sheets.Events.ActiveSheetChanged, () => {
      const activeSheet = workbookRef.current?.getActiveSheet()

      if (activeSheet) setCellInfo(getCellInfo(activeSheet))
    })

    // Remove all context menu items except Copy.
    const contextMenu = workbookRef.current.contextMenu
    contextMenu.menuData = contextMenu.menuData.filter(item => item?.text === 'Copy' || item?.name === 'copy')
  }, [])

  const handleWorkbookInit = (workbookInstance: GC.Spread.Sheets.Workbook) => {
    workbookRef.current = workbookInstance

    const statusBarId = `status-bar-${id}`
    const statusBar = new GC.Spread.Sheets.StatusBar.StatusBar(window.document.getElementById(statusBarId)!)

    statusBar.bind(workbookInstance)
  }

  useEffect(() => {
    if (!workbookRef.current || !file) return

    workbookRef.current.import(file, handleImportSuccess, handleImportError)

    setIsLoading(false)
  }, [file, handleImportError, handleImportSuccess])

  return (
    <SpreadsheetErrorBoundary>
      <Box sx={{ borderTop: `1px solid ${grey[300]}`, display: 'flex', flexDirection: 'column', height: '100%', textAlign: 'initial', position: 'relative' }}>
        <Toolbar cellInfo={cellInfo} setToolbarHeight={setToolbarHeight} toolbarHeight={toolbarHeight} workbookRef={workbookRef} />

        <Box sx={{ height: '100%', marginTop: `${toolbarHeight}px`, maxHeight: `calc(100% - ${toolbarHeight + STATUS_BAR_HEIGHT}px)` }}>
          {isLoading && (
            <Box sx={{ background: 'white', display: 'flex', height: '-webkit-fill-available', position: 'absolute', zIndex: Z_INDEX_1 }}>
              <Loader />
            </Box>
          )}

          <SpreadSheets {...WORKBOOK_OPTION_OVERRIDES} workbookInitialized={handleWorkbookInit}>
            <Worksheet />
          </SpreadSheets>
        </Box>

        <Box id={`status-bar-${id}`} sx={{ height: STATUS_BAR_HEIGHT, '& .gc-statusbar': { background: grey[700] } }} />
      </Box>
    </SpreadsheetErrorBoundary>
  )
}

const Toolbar: FC<_ToolbarProps> = ({ cellInfo, setToolbarHeight, toolbarHeight, workbookRef }) => {
  const [isResizing, setIsResizing] = useState(false)

  const cursor = toolbarHeight >= MAX_TOOLBAR_HEIGHT ? Cursors.MINIMUM : toolbarHeight <= MIN_TOOLBAR_HEIGHT ? Cursors.MAXIMUM : Cursors.BETWEEN

  const handleResize = (_: SyntheticEvent, { size }: ResizeCallbackData) => {
    setToolbarHeight(size.height)

    workbookRef.current?.refresh()
  }

  return (
    <Resizable
      axis="y"
      handle={<ToolbarHandle cursor={cursor} />}
      height={toolbarHeight}
      maxConstraints={[Infinity, MAX_TOOLBAR_HEIGHT]}
      minConstraints={[Infinity, MIN_TOOLBAR_HEIGHT]}
      onResize={handleResize}
      onResizeStart={() => setIsResizing(true)}
      onResizeStop={() => setIsResizing(false)}
      width={Infinity}
    >
      <Box
        sx={{
          background: 'white',
          height: toolbarHeight,
          left: 0,
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: Z_INDEX_1,
          pointerEvents: isResizing ? 'none' : 'auto'
        }}
      >
        <Typography
          sx={{ border: `1px solid ${grey[300]}`, m: '7px', height: toolbarHeight - 14 - RESIZABLE_HANDLE_HEIGHT, overflow: 'scroll', px: 1, py: 0.5 }}
          variant="body2"
        >
          {cellInfo?.formula || cellInfo?.value || ''}
        </Typography>
      </Box>
    </Resizable>
  )
}

// See: https://github.com/react-grid-layout/react-resizable/issues/175#issuecomment-1101142158
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ToolbarHandle = forwardRef<HTMLDivElement, _ToolbarHandleProps>(({ cursor, handleAxis, ...rest }, ref) => (
  <Box
    ref={ref}
    sx={{ backgroundColor: grey[300], bottom: 0, cursor, height: RESIZABLE_HANDLE_HEIGHT, left: 0, position: 'sticky', width: '100%' }}
    {...rest}
  />
))

import { Box } from '@mui/material'
import { FIELD_TYPE_LABEL_MAP } from '../../../../../utils/cci'
import { FieldTypes } from '../../RightPanel/RightPanel_components/ChecklistGptTab/inputs/FieldTypeInput'
import React, { FC } from 'react'

// types

type _FieldTypeCellProps = { fieldType: FieldTypes | string }

// components

export const FieldTypeCell: FC<_FieldTypeCellProps> = ({ fieldType }) => (
  <Box sx={{ whiteSpace: 'nowrap' }}>{FIELD_TYPE_LABEL_MAP.get(fieldType) || fieldType}</Box>
)

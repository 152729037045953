import { Alert, Snackbar } from '@mui/material'
import {
  Confidence,
  ConfidenceGlobalContext,
  _ConfidenceGlobalContext,
  _IsConfidenceMutationBusyMap,
  _IsConfidenceVisibleMap,
  _IsReviewingNovelSegmentsMap,
  _NovelSegmentIndexMap
} from '../Confidence'
import { DataTable, DataTableSpreadsheet } from './DataTable'
import { DataTableMatching } from '../dialogs/DataTableMatching'
import { Document } from '../../graphql/codegen/schemas'
import { DocumentPendingProcessingFragment } from '../../graphql/codegen/operations'
import { DocumentSelector } from './DocumentSelector'
import { Features, Permissions, useUserAccess } from '../../hooks/useUserAccess'
import { NestedChildDataPointsPanel } from '../dialogs/NestedChildDataPointsPanel'
import { PDFDoc } from '../PDFDoc'
import { SPREADSHEET_FILE_EXTENSIONS, TEXT_FILE_EXTENSIONS } from '../../utils/fileUtils'
import { SpreadsheetDocumentWrapper } from '../SpreadsheetViewer'
import { TableStatuses, useGetTableStatusQuery, useGetTablesQuery } from '../../app/restApi'
import { TextFileViewer } from '../TextFileViewer'
import { isEmpty } from 'lodash'
import { useDocumentPageWrapperContext } from '../DocumentPageWrapper'
import { useDocumentsQuery } from '../../graphql/codegen/hooks'
import { useHasDataTableSpreadsheetAccess } from '../../hooks/useHasDataTableSpreadsheetAccess'
import { useOpening } from '@hoologic/use-opening'
import DocumentPlaceholder from '../DocumentPlaceholder'
import PDFHighlighterDoc from '../PDFHighlighterDoc'
import React, { useMemo, useRef, useState } from 'react'
import SummaryTab from './SummaryTab'
import Toolbar from './Toolbar'
import UploadTab from './UploadTab/UploadTab'
import clsx from 'clsx'
import css from './style.module.scss'
import useIsPreAnnot from '../../hooks/useIsPreAnnot'
import type { _DataTableList } from '../dialogs/DataTableEditor'

// types

type _DocumentPanelProps = {
  counterparty?: any
  dealIsFinalized?: boolean
  documents: Document[]
  documentsPendingProcessing?: DocumentPendingProcessingFragment[]
  loading: boolean
  parentName?: string
  withSelector?: boolean
}

export type _PdfDocumentFormat = 'PDF' | 'ORIGINAL_PDF' // `PDF`: Processed document with annotations. `ORIGINAL_PDF`: Document without annotations.

export type _PdfDocumentProps = {
  documentFormat: _PdfDocumentFormat
  documentId: string | null
  loading?: boolean
}

// enums

enum DocumentTypes {
  PDF,
  SPREADSHEET,
  TEXT
}

// functions

export const getDocumentType = (documentName: string): DocumentTypes =>
  documentName.match(SPREADSHEET_FILE_EXTENSIONS)
    ? DocumentTypes.SPREADSHEET
    : documentName.match(TEXT_FILE_EXTENSIONS)
    ? DocumentTypes.TEXT
    : DocumentTypes.PDF

// components

function DocumentPanel({ counterparty, dealIsFinalized, documents, documentsPendingProcessing, loading, parentName, withSelector }: _DocumentPanelProps) {
  const { activeDocumentId, activeParentDataPoint, activeTableId, dataTableMatchingOpening, isDataTableMatchingDemoEnabled, setActiveTableId } =
    useDocumentPageWrapperContext()
  const { isPreAnnot } = useIsPreAnnot()
  const savedOpening = useOpening()
  const documentWrapperRef = useRef<HTMLDivElement | null>(null)
  const scrollToPageAfterFormatChange = useRef<number>()
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [pdfDocumentFormat, setPdfDocumentFormat] = useState<_PdfDocumentFormat>(isPreAnnot ? 'ORIGINAL_PDF' : 'PDF')
  const [isConfidenceMutationBusyMap, setIsConfidenceMutationBusyMap] = useState<_IsConfidenceMutationBusyMap>({})

  const [isConfidenceVisibleMap, setIsConfidenceVisibleMap] = useState(() =>
    documents?.reduce<_IsConfidenceVisibleMap>((acc, doc) => ({ ...acc, [doc.id]: true }), {})
  )

  const hasDataTableSpreadsheetAccess = useHasDataTableSpreadsheetAccess()
  const [isDataTableSelectedList, setIsDataTableSelectedList] = useState<boolean[][]>([])

  const [isReviewingNovelSegmentsMap, setIsReviewingNovelSegmentsMap] = useState(() =>
    documents?.reduce<_IsReviewingNovelSegmentsMap>((acc, doc) => ({ ...acc, [doc.id]: false }), {})
  )

  const [novelSegmentIndexMap, setNovelSegmentIndexMap] = useState<_NovelSegmentIndexMap>({})
  const [scale, setScale] = useState<'auto' | number>('auto')
  const [showAllTags, setShowAllTags] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const hasDealUpdateAccess = useUserAccess({ feature: Features.DEAL, permission: Permissions.UPDATE })

  const { data: documentsData } = useDocumentsQuery({ skip: !activeDocumentId, variables: { documentId: activeDocumentId } })
  const activeDocument = useMemo(() => documentsData?.documents?.edges[0]?.node as Document | undefined, [documentsData?.documents?.edges])
  const areNovelSegmentsPresent = useMemo(() => !isEmpty(activeDocument?.novel_para_annotations), [activeDocument?.novel_para_annotations])

  const documentType = useMemo(() => (activeDocument ? getDocumentType(activeDocument.name) : undefined), [activeDocument])

  const isPdfToolbarShown = useMemo(
    () => !['summary', 'upload'].includes(activeDocumentId as string) && documentType === DocumentTypes.PDF,
    [activeDocumentId, documentType]
  )

  const isGetTableStatus = Boolean(activeDocumentId && !isPreAnnot && isPdfToolbarShown)

  const {
    data: tableStatusData,
    isError: isTableStatusError,
    isFetching: isTableStatusFetching
  } = useGetTableStatusQuery({ documentId: activeDocumentId! }, { skip: !isGetTableStatus })

  const tableStatus = isTableStatusFetching ? undefined : tableStatusData?.status
  const isGetTables = isGetTableStatus && tableStatus === TableStatuses.TABLES_PROCESSED

  const {
    data: tablesData,
    isError: isTablesDataError,
    isFetching: isTablesDataFetching
  } = useGetTablesQuery({ documentId: activeDocumentId! }, { skip: !isGetTables })

  const confidenceGlobalContext: _ConfidenceGlobalContext = useMemo(
    () => ({
      isConfidenceVisibleMap,
      isReviewingNovelSegmentsMap,
      novelSegmentIndexMap,
      setIsConfidenceVisibleMap,
      setIsReviewingNovelSegmentsMap,
      setNovelSegmentIndexMap
    }),
    [isConfidenceVisibleMap, isReviewingNovelSegmentsMap, novelSegmentIndexMap]
  )

  const tables = useMemo(
    () => (isGetTables && tablesData ? [...tablesData].sort((a, b) => a.page_number - b.page_number).map(item => item.tables) : []),
    [isGetTables, tablesData]
  )

  const dataTableList = useMemo<_DataTableList>(() => {
    let previousPageNumber = -1
    let tableNumber = 0

    return tables
      .flat()
      .filter((_, index) => isDataTableSelectedList.flat()[index])
      .map(table => {
        const pageNumber = table[0][0].bbox_xy.page_number

        if (pageNumber !== previousPageNumber) {
          tableNumber = 0
        }

        previousPageNumber = pageNumber
        tableNumber++

        return table.map(row => row.map(({ bbox_xy: { page_number: pageNumber }, text }) => ({ pageNumber, tableNumber, text })))
      })
  }, [isDataTableSelectedList, tables])

  const { alias, name, visualizer_version } = documents[0] || {}
  const v2 = visualizer_version === 2

  // functions

  const handleDocumentReady = () => {
    // If we stored a previous page number while switching document format, immediately scroll to that position on load
    const viewer = window.PdfViewer?.viewer
    const pageNumber = scrollToPageAfterFormatChange.current

    if (viewer && pageNumber) {
      viewer.scrollPageIntoView({ pageNumber })
    }

    setTotalPages(window.PdfViewer?.viewer?._pages?.length)
  }

  const togglePdfDocumentFormat = () => {
    // Get current page number if PDFJSViewer exists
    scrollToPageAfterFormatChange.current = window.PdfViewer?.viewer.currentPageNumber

    setPdfDocumentFormat(prevFormat => (prevFormat === 'PDF' ? 'ORIGINAL_PDF' : 'PDF'))
  }

  const zoom = (factor: number) => {
    // TODO: This side effect is a hacky way to ensure that we don't have highlights that are duplicated every time the document resizes. As it stands, the popper component either stays in place or jumps to the 0,0 coordinates on the page instead of being removed, so we remove it here.
    const highlightRoot = document.getElementById('popper-root')
    if (highlightRoot) {
      highlightRoot.innerHTML = ''
    }

    setScale(previous => {
      if (previous === 'auto') {
        const { currentScale } = window.PdfViewer?.viewer || {}

        return isNaN(currentScale) ? 1 : currentScale * factor
      }

      return previous * factor
    })
  }

  // render

  return (
    <ConfidenceGlobalContext.Provider value={confidenceGlobalContext}>
      <div className={css.documentPanel}>
        {isPdfToolbarShown && (
          <Toolbar
            activeDocumentId={activeDocumentId}
            areNovelSegmentsPresent={areNovelSegmentsPresent}
            currentPage={currentPage}
            dataTableList={dataTableList}
            documentFormat={pdfDocumentFormat}
            name={alias || name}
            savedOpening={savedOpening}
            setCurrentPage={setCurrentPage}
            setShowAllTags={setShowAllTags}
            showAllTags={showAllTags}
            tablesQueryResult={{ isError: isTableStatusError || isTablesDataError, isTableStatusFetching, isTablesDataFetching, tableStatus, tables }}
            toggleDocumentFormat={togglePdfDocumentFormat}
            totalPages={totalPages}
            v2={v2}
            zoom={zoom}
          />
        )}
        <div
          className={clsx(css.documentWrapper, documentType === DocumentTypes.PDF && css.pdfDocument, withSelector && css.withSelector, v2 ? css.v2 : css.v1)}
          ref={documentWrapperRef}
          // this is a fix for the height of the upload tab add doc to deal height
          style={{
            ...(!loading && hasDealUpdateAccess && (!activeDocumentId || activeDocumentId === 'upload') && { height: 'calc(100% - 16px - 60px)' })
          }}
        >
          {!loading && (!activeDocumentId || activeDocumentId === 'upload') ? (
            <UploadTab
              counterparty={counterparty}
              dealIsFinalized={dealIsFinalized || false}
              dealName={parentName}
              hasDealUpdateAccess={hasDealUpdateAccess}
              parentRef={documentWrapperRef}
            />
          ) : activeDocumentId === 'summary' ? (
            <SummaryTab dealIsFinalized={dealIsFinalized || false} />
          ) : (
            <>
              {activeDocument && !isEmpty(activeDocument.novel_para_annotations) && <Confidence activeDocument={activeDocument} />}

              {documentType === undefined && <DocumentPlaceholder />}

              {documentType === DocumentTypes.PDF &&
                (v2 ? (
                  <PDFHighlighterDoc
                    dealIsFinalized={dealIsFinalized || false}
                    documentFormat={pdfDocumentFormat}
                    documentId={activeDocumentId}
                    isConfidenceMutationBusyMap={isConfidenceMutationBusyMap}
                    isDataTableSelectedList={isDataTableSelectedList}
                    onDocumentReady={handleDocumentReady}
                    onPageChange={setCurrentPage}
                    scale={scale}
                    setIsConfidenceMutationBusyMap={setIsConfidenceMutationBusyMap}
                    setIsDataTableSelectedList={setIsDataTableSelectedList}
                    showAllTags={showAllTags}
                    showHighlights={pdfDocumentFormat !== 'ORIGINAL_PDF'}
                    tables={tables}
                  />
                ) : (
                  <PDFDoc documentFormat={pdfDocumentFormat} documentId={activeDocumentId} loading={loading} />
                ))}

              {documentType === DocumentTypes.SPREADSHEET && activeDocument && (
                <SpreadsheetDocumentWrapper documentId={activeDocument.id} documentName={activeDocument.name} key={activeDocument.id} />
              )}

              {documentType === DocumentTypes.TEXT && activeDocument && <TextFileViewer documentId={activeDocument.id} key={activeDocument.id} />}
            </>
          )}

          {activeDocumentId && isDataTableMatchingDemoEnabled && <DataTableMatching opening={dataTableMatchingOpening} />}

          {activeParentDataPoint && (
            <NestedChildDataPointsPanel
              activeDocumentId={activeDocumentId}
              dealIsFinalized={dealIsFinalized || false}
              parentDataPoint={activeParentDataPoint}
            />
          )}
        </div>

        {activeTableId &&
          (hasDataTableSpreadsheetAccess ? (
            <DataTableSpreadsheet dealIsFinalized={dealIsFinalized || false} setActiveTableId={setActiveTableId} tableId={activeTableId} />
          ) : (
            <DataTable dealIsFinalized={dealIsFinalized || false} setActiveTableId={setActiveTableId} tableId={activeTableId} />
          ))}

        {!loading && withSelector && (
          <DocumentSelector
            currentDocumentId={activeDocumentId || ''}
            dealCounterparty={counterparty}
            dealIsFinalized={dealIsFinalized || false}
            documents={documents}
            documentsPendingProcessing={documentsPendingProcessing}
            hasDealUpdateAccess={hasDealUpdateAccess}
          />
        )}

        <Snackbar autoHideDuration={3000} onClose={savedOpening.close} open={savedOpening.isOpen}>
          <Alert severity="success">Table saved.</Alert>
        </Snackbar>
      </div>
    </ConfidenceGlobalContext.Provider>
  )
}

export default DocumentPanel

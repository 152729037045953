import { Box, FormControlLabel, Switch, Typography } from '@mui/material'
import { TooltipInfo } from '../TooltipInfo'
import { isEmpty } from 'lodash'
import { useCciMainContext } from '../../../../../CCI_Main'
import { useChecklistConfigContext } from '../../../../ChecklistConfigProvider'
import React, { FC, useEffect, useMemo, useState } from 'react'
import css from './style.module.scss'

// components

export const ExtractionAutomationSwitch: FC = () => {
  const { activeComponent, isModalOpen, selectedItem, setIsModalOpen, setModalLoading, setModalLoadingMessage } = useCciMainContext()
  const { createExtractionConfiguration, editExtractionConfiguration, isCreateView, isEditView, setExtractionConfigurationChanges } =
    useChecklistConfigContext()

  const initialExtractionConfiguration = useMemo(() => JSON.parse(selectedItem?.extraction_method_instances_config?.[0] || '{}'), [selectedItem])

  const [isSwitchChecked, setIsSwitchChecked] = useState(Boolean((isCreateView && !isModalOpen) || initialExtractionConfiguration.is_enabled))

  const isSwitchDisabled = isEditView && isEmpty(initialExtractionConfiguration)

  const tooltipTitle = isSwitchDisabled
    ? 'Select an AI Provider to enable extraction automation.'
    : 'Changes are saved automatically when toggling this option.'

  const handleChange = (_: unknown, isEnabled: boolean) => {
    setExtractionConfigurationChanges({ isEnabled })
    setIsSwitchChecked(isEnabled)

    if (isEditView) {
      setIsModalOpen(true)
      setModalLoading(true)
      setModalLoadingMessage(isEnabled ? 'Enabling extraction automation…' : 'Disabling extraction automation…')

      isEmpty(initialExtractionConfiguration) ? createExtractionConfiguration(selectedItem.id) : editExtractionConfiguration()
    }
  }

  useEffect(() => {
    // Initialize configuration changes in "Create" view on mount. Only perform this initialization when the loading modal is not open.
    // Otherwise, this action will overwrite user changes during the re-render which occurs during the 2-stage field-creation process:
    // (1) creating the data point field, and (2) creating its extraction method instance (after `selectedItem` is updated).
    if (isCreateView && !isModalOpen) setExtractionConfigurationChanges({ isEnabled: true })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isEditView) {
      setExtractionConfigurationChanges(null)
      setIsSwitchChecked(Boolean(initialExtractionConfiguration.is_enabled))
    }
  }, [activeComponent, initialExtractionConfiguration, isEditView, selectedItem, setExtractionConfigurationChanges])

  return (
    <Box className={css.inputWrapper}>
      <FormControlLabel
        control={<Switch checked={isSwitchChecked} disabled={isSwitchDisabled} onChange={handleChange} />}
        label={
          <Typography variant="body2">
            <>Enable extraction automation</>

            {isEditView && <TooltipInfo tooltipTitle={tooltipTitle} />}
          </Typography>
        }
      />
    </Box>
  )
}

import 'rc-collapse/assets/index.css'
import { CciLeftPanelChecklistList } from './ChecklistTab/CCI_LeftPanel_ChecklistList'
import { Features, Permissions, useUserAccess } from '../../../../../hooks/useUserAccess'
import { Redirect, Route, Switch } from 'react-router-dom'
import CCI_LeftPanel_IntegrationList from './IntegrationsTab/CCI_LeftPanel_IntegrationList'
import CCI_LeftPanel_RoleList from './RolesTab/CCI_LeftPanel_RoleList'
import CCI_LeftPanel_UserList from './UsersTab/CCI_LeftPanel_UserList'
import React from 'react'

export default function CCI_LeftPanel_Content() {
  const hasAdminAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.ADMIN })
  const hasChecklistTabAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.CHECKLIST_TAB })
  const hasIntegrationsTabAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.INTEGRATIONS_TAB })
  const hasRolesTabAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.ROLES_TAB })
  const hasUsersTabAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.USERS_TAB })

  return (
    <Switch>
      <Route exact path="/config">
        {(hasAdminAccess || hasChecklistTabAccess) && <CciLeftPanelChecklistList />}
      </Route>

      <Route exact path="/config/integrations">
        {(hasAdminAccess || hasIntegrationsTabAccess) && <CCI_LeftPanel_IntegrationList />}
      </Route>

      <Route exact path="/config/roles">
        {(hasAdminAccess || hasRolesTabAccess) && <CCI_LeftPanel_RoleList />}
      </Route>

      <Route exact path="/config/users">
        {(hasAdminAccess || hasUsersTabAccess) && <CCI_LeftPanel_UserList />}
      </Route>

      <Redirect from="/config/checklist" to="/config" />
    </Switch>
  )
}

import { useCallback, useEffect, useState } from 'react'
import { useRefCallback } from './useRefCallback'

// types

type _UseScrollabilityParams = {
  offset?: number
}

type _UseScrollabilityResult = {
  element: HTMLElement | null
  isScrollableDown: boolean
  isScrollableUp: boolean
  refCallback: (node: HTMLElement | null) => void
}

// hook

export const useScrollability = ({ offset = 0 }: _UseScrollabilityParams = {}): _UseScrollabilityResult => {
  const [element, refCallback] = useRefCallback()
  const [isScrollableUp, setIsScrollableUp] = useState(false)
  const [isScrollableDown, setIsScrollableDown] = useState(false)

  const updateScrollability = useCallback(() => {
    if (element) {
      const { clientHeight, scrollHeight, scrollTop } = element

      setIsScrollableUp(scrollTop > offset)
      setIsScrollableDown(scrollTop + clientHeight < scrollHeight - offset)
    }
  }, [element, offset])

  useEffect(() => {
    if (element) {
      const mutationObserver = new MutationObserver(updateScrollability) // Update when the element's value changes.
      mutationObserver.observe(element, { attributes: true })

      const resizeObserver = new ResizeObserver(updateScrollability) // Update when the element's size changes.
      resizeObserver.observe(element)

      element.addEventListener('scroll', updateScrollability)

      updateScrollability()

      return () => {
        mutationObserver.disconnect()
        resizeObserver.disconnect()
        element.removeEventListener('scroll', updateScrollability)
      }
    }
  }, [element, updateScrollability])

  return { element, isScrollableDown, isScrollableUp, refCallback }
}

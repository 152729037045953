import 'react-toastify/dist/ReactToastify.css'
import { ApolloProvider, DocumentNode } from '@apollo/client'
import { AppSetupUtils } from './AppSetupUtils'
import { COMPONENTS } from '../new/theme/components'
import { DashboardPreviewOverlay } from '../components/DashboardPreviewOverlay'
import { I18nextProvider } from 'react-i18next'
import { Router as KlarityRouter } from './router'
import { PALETTE } from '../new/theme/palette'
import { Router } from 'react-router'
import { SHAPE } from '../new/theme/shape'
import { Provider as StoreProvider } from 'react-redux'
import { TYPOGRAPHY } from '../new/theme/typography'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { ToastContainer } from 'react-toastify'
import { User } from '../graphql/codegen/schemas'
import { configureStore } from '@reduxjs/toolkit'
import { createI18n } from '../utils/i18Utils'
import { errorReportingMiddleware, restApi } from './restApi'
import { getApolloClient } from '../utils/apolloClient'
import { setupListeners as setUpListeners } from '@reduxjs/toolkit/query'
import { useContextInit } from '../hooks/useContextInit'
import ComponentLoadingOverlay from '../components/ComponentLoadingOverlay'
import ModalOptions from '../components/ModalOptions'
import RModal from 'react-modal'
import React, { Dispatch, FC, ReactNode, SetStateAction, createContext, useEffect, useMemo, useState } from 'react'
import css from './style.module.scss'
import history from './history'

// types

type _AppContext = {
  currentUserQuery: _CurrentUserQuery
  errorMessage: string
  extendedErrorMessage: string
  isAnnotationAdded: boolean
  isAnnotationDeleted: boolean
  isSearchDataLoading: boolean
  pendingMutationCount: number
  searchPlaceholder: string
  searchTerm: string
  setErrorMessage: Dispatch<SetStateAction<string>>
  setExtendedErrorMessage: Dispatch<SetStateAction<string>>
  setIsAnnotationAdded: Dispatch<SetStateAction<boolean>>
  setIsAnnotationDeleted: Dispatch<SetStateAction<boolean>>
  setIsSearchDataLoading: Dispatch<SetStateAction<boolean>>
  setPendingMutationCount: Dispatch<SetStateAction<number>>
  setSearchPlaceholder: Dispatch<SetStateAction<string>>
  setSearchTerm: Dispatch<SetStateAction<string>>
}

type _AppProps = {
  currentUserQuery: _CurrentUserQuery
}

type _CurrentUserQuery = { data: { current_user?: User }; query: DocumentNode }

interface LocalApolloProviderProps {
  children: ReactNode
}

interface LocalStoreProviderProps {
  children: ReactNode
}

type _ModalContext = {
  closeModal: () => void
  isModalOpen: boolean
  isPreviewOpen: boolean
  modalContent: any
  modalError?: { message?: string; messageNode?: ReactNode; title: string } | undefined
  modalOption: string
  modalSuccess?: { message?: string; messageNode?: ReactNode; title: string } | undefined
  openModal: any
  openPreview: any
  setModalError: Dispatch<SetStateAction<{ message?: string; messageNode?: ReactNode; title: string } | undefined>>
  setModalLoading: Dispatch<SetStateAction<boolean>>
  setModalLoadingMessage: Dispatch<SetStateAction<string>>
  setModalOption: Dispatch<SetStateAction<string>>
  setModalSuccess: Dispatch<SetStateAction<{ message?: string; messageNode?: ReactNode; title: string } | undefined>>
  setModalTitle: Dispatch<SetStateAction<string>>
}

// context

const AppContext = createContext<_AppContext | null>(null)
const ModalContext = createContext<_ModalContext | null>(null)

// theme

export const theme = createTheme({
  components: COMPONENTS,
  palette: PALETTE,
  shape: SHAPE,
  typography: TYPOGRAPHY
})

// hooks

export const useAppContext = () => useContextInit(AppContext)
export const useModalContext = () => useContextInit(ModalContext)

// components

export const App: FC<_AppProps> = ({ currentUserQuery }) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [extendedErrorMessage, setExtendedErrorMessage] = useState('')
  const [isAnnotationAdded, setIsAnnotationAdded] = useState(false)
  const [isAnnotationDeleted, setIsAnnotationDeleted] = useState(false)
  const [isSearchDataLoading, setIsSearchDataLoading] = useState(false)
  const [pendingMutationCount, setPendingMutationCount] = useState(0) // Use a number (not a boolean) to track pending mutations and accurately handle multiple concurrent mutations.
  const [searchPlaceholder, setSearchPlaceholder] = useState('')
  const [searchTerm, setSearchTerm] = useState('')

  // these two booleans are one-time signals to child components that an annotation was added or deleted, and are reset back to `false` here
  useEffect(() => setIsAnnotationAdded(false), [isAnnotationAdded])
  useEffect(() => setIsAnnotationDeleted(false), [isAnnotationDeleted])

  const context = useMemo(
    () => ({
      currentUserQuery,
      errorMessage,
      extendedErrorMessage,
      isAnnotationAdded,
      isAnnotationDeleted,
      isSearchDataLoading,
      pendingMutationCount,
      searchPlaceholder,
      searchTerm,
      setErrorMessage,
      setExtendedErrorMessage,
      setIsAnnotationAdded,
      setIsAnnotationDeleted,
      setIsSearchDataLoading,
      setPendingMutationCount,
      setSearchPlaceholder,
      setSearchTerm
    }),
    [
      currentUserQuery,
      errorMessage,
      extendedErrorMessage,
      isAnnotationAdded,
      isAnnotationDeleted,
      isSearchDataLoading,
      pendingMutationCount,
      searchPlaceholder,
      searchTerm
    ]
  )

  const i18n = useMemo(() => createI18n(currentUserQuery.data.current_user?.customers?.edges[0]?.node?.metadata?.business_workflow), [currentUserQuery])

  return (
    <AppContext.Provider value={context}>
      <LocalApolloProvider>
        <LocalStoreProvider>
          <ThemeProvider theme={theme}>
            <I18nextProvider i18n={i18n}>
              <InnerApp />
            </I18nextProvider>
          </ThemeProvider>
        </LocalStoreProvider>
      </LocalApolloProvider>
    </AppContext.Provider>
  )
}

const InnerApp: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const [modalContent, setModalContent] = useState<any>(undefined)
  const [modalError, setModalError] = useState<{ message?: string; messageNode?: ReactNode; title: string } | undefined>(undefined)
  const [modalLoading, setModalLoading] = useState(false)
  const [modalLoadingMessage, setModalLoadingMessage] = useState('')
  const [modalOption, setModalOption] = useState('')
  const [modalSuccess, setModalSuccess] = useState<{ message?: string; messageNode?: ReactNode; title: string } | undefined>(undefined)
  const [modalTitle, setModalTitle] = useState('')
  const [previewContent, setPreviewContent] = useState<{ fileId: string; fileName: string; isDocument: boolean }>()

  const closeModal = () => {
    setIsModalOpen(false)
    setModalContent(undefined)
    setModalError(undefined)
    setModalLoading(false)
    setModalLoadingMessage('')
    setModalOption('')
    setModalSuccess(undefined)
    setModalTitle('')
  }

  const closePreview = () => {
    setIsPreviewOpen(false)
    setPreviewContent({ fileId: '', fileName: '', isDocument: false })
  }

  const openModal = (option: string, title: string, modalContent: any) => {
    setIsModalOpen(true)
    setModalContent(modalContent ? { ...modalContent } : undefined)
    setModalOption(option)
    setModalTitle(title)
  }

  const openPreview = (fileId: string, isDocument: boolean, fileName?: string) => {
    setIsPreviewOpen(true)
    setPreviewContent({ fileId, fileName: fileName || '', isDocument })
  }

  const modalContext = useMemo(
    () => ({
      closeModal,
      isModalOpen,
      isPreviewOpen,
      modalContent,
      modalError,
      modalOption,
      modalSuccess,
      openModal,
      openPreview,
      setModalError,
      setModalLoading,
      setModalLoadingMessage,
      setModalOption,
      setModalSuccess,
      setModalTitle
    }),
    [isModalOpen, isPreviewOpen, modalContent, modalError, modalOption, modalSuccess]
  )

  const handleClickOutside = () => {
    if (modalOption !== 'WrongCustomer') {
      closeModal()
    }
  }

  return (
    <Router history={history}>
      <ModalContext.Provider value={modalContext}>
        <AppSetupUtils />

        <KlarityRouter />

        <ToastContainer autoClose={false} position="bottom-right" />

        {isPreviewOpen && (
          <DashboardPreviewOverlay
            fileId={previewContent?.fileId}
            fileName={previewContent?.fileName}
            handleClose={closePreview}
            isDocument={previewContent?.isDocument}
          />
        )}

        <RModal className={css.modal} isOpen={isModalOpen} onRequestClose={handleClickOutside} overlayClassName={css.overlay}>
          {modalLoading && <ComponentLoadingOverlay loading={modalLoading} message={modalLoadingMessage} />}

          {modalTitle && !modalSuccess && !modalError && <h2 className={css.modalTitle}>{modalTitle}</h2>}

          <ModalOptions />
        </RModal>
      </ModalContext.Provider>
    </Router>
  )
}

const LocalApolloProvider: FC<LocalApolloProviderProps> = ({ children }) => {
  const { currentUserQuery, setErrorMessage, setExtendedErrorMessage, setPendingMutationCount } = useAppContext()

  const client = useMemo(() => {
    const client = getApolloClient(setErrorMessage, setExtendedErrorMessage, setPendingMutationCount)

    client.writeQuery(currentUserQuery)

    return client
  }, [currentUserQuery, setErrorMessage, setExtendedErrorMessage, setPendingMutationCount])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

const LocalStoreProvider: FC<LocalStoreProviderProps> = ({ children }) => {
  const { setErrorMessage, setExtendedErrorMessage } = useAppContext()

  const store = useMemo(() => {
    const store = configureStore({
      middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(restApi.middleware, api => errorReportingMiddleware(api, setErrorMessage, setExtendedErrorMessage)),
      reducer: { [restApi.reducerPath]: restApi.reducer }
    })

    setUpListeners(store.dispatch)

    return store
  }, [setErrorMessage, setExtendedErrorMessage])

  return <StoreProvider store={store}>{children}</StoreProvider>
}

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from '@mui/material'
import { ErrorBoundary } from '../../components/ErrorBoundary'
import { InnerLayout } from '../../new/components/InnerLayout'
import { NavBar } from '../NavBar'
import { Opening, useOpening } from '@hoologic/use-opening'
import { SkeletonTheme } from 'react-loading-skeleton'
import { Z_INDEX_MODAL } from '../../utils/styleUtils'
import { useAppContext, useModalContext } from '../../app'
import { useIsKlarityEmployee } from '../../hooks/useIsKlarityEmployee'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import React, { FC, ReactNode, SyntheticEvent, useMemo } from 'react'
import copy from 'copy-to-clipboard'
import css from './style.module.scss'

// types

type _ExtendedErrorDialogProps = {
  opening: Opening
}

type _LayoutProps = {
  children: ReactNode
  isMaterialUi?: boolean
}

// components

export const ErrorMessage: FC = () => {
  const { errorMessage, extendedErrorMessage, setErrorMessage } = useAppContext()
  const isKlarityEmployee = useIsKlarityEmployee()
  const extendedErrorOpening = useOpening()
  const snackbarOpening = useOpening()

  useMemo(() => snackbarOpening.open(), []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setErrorMessage('')

    snackbarOpening.close()
  }

  const openExtendedError = () => {
    extendedErrorOpening.open()

    snackbarOpening.close()
  }

  return (
    <>
      {extendedErrorOpening.isOpen && <ExtendedErrorDialog opening={extendedErrorOpening} />}

      <Snackbar anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} onClose={handleClose} open={snackbarOpening.isOpen} sx={{ zIndex: Z_INDEX_MODAL }}>
        <Alert severity="error" sx={{ alignItems: 'center', display: 'flex' }} variant="filled">
          {errorMessage}

          {extendedErrorMessage && isKlarityEmployee && (
            <Button
              disableElevation
              onClick={openExtendedError}
              sx={{ background: '#fff', color: '#000', ml: 2, '&:hover': { background: '#f4f4f4' } }}
              variant="contained"
            >
              Details
            </Button>
          )}
        </Alert>
      </Snackbar>
    </>
  )
}

const ExtendedErrorDialog: FC<_ExtendedErrorDialogProps> = ({ opening }) => {
  const { extendedErrorMessage, setErrorMessage, setExtendedErrorMessage } = useAppContext()
  const copiedOpening = useOpening()

  const handleClose = () => {
    setErrorMessage('')
    setExtendedErrorMessage('')

    opening.close()
  }

  const handleCopy = (event: SyntheticEvent) => {
    event.stopPropagation()

    copy(extendedErrorMessage, { format: 'text/plain' })

    copiedOpening.open()
  }

  return (
    <>
      <Dialog PaperProps={{ sx: { maxHeight: '60vh' } }} disablePortal maxWidth="xl" onClose={handleClose} open={opening.isOpen} sx={{ zIndex: Z_INDEX_MODAL }}>
        <DialogTitle>Please copy the following and share with the tech team:</DialogTitle>

        <DialogContent>
          <pre>{extendedErrorMessage}</pre>
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={handleCopy} startIcon={<ContentCopyIcon />} variant="outlined">
            Copy Error Message
          </Button>

          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar autoHideDuration={3000} onClose={copiedOpening.close} open={copiedOpening.isOpen}>
        <Alert severity="success">Error message copied.</Alert>
      </Snackbar>
    </>
  )
}

export default function Layout({ children, isMaterialUi }: _LayoutProps) {
  const { errorMessage } = useAppContext()
  const { isModalOpen, isPreviewOpen } = useModalContext()

  return (
    <div className={css.layout} style={isModalOpen || isPreviewOpen ? { width: '100vw', height: '100vh', overflow: 'hidden' } : {}}>
      <SkeletonTheme color="#f3f4f8" highlightColor="#f9f9f9">
        <NavBar />

        <ErrorBoundary>
          <>
            {errorMessage && <ErrorMessage />}

            {isMaterialUi ? <InnerLayout>{children}</InnerLayout> : children}
          </>
        </ErrorBoundary>
      </SkeletonTheme>
    </div>
  )
}

import { Alert, Box, Button, CircularProgress, Dialog, DialogContent, IconButton, Tooltip, Typography } from '@mui/material'
import { DataPointDataTable } from '../../../graphql/codegen/schemas'
import { ExportToCsv } from 'export-to-csv'
import { Resizable } from '../../Resizable'
import { SpreadsheetEditor, _SpreadsheetEditorRef } from './SpreadsheetEditor'
import { Toast } from '../../Toast'
import { Z_INDEX_OVERLAY } from '../../../utils/styleUtils'
import { captureError } from '../../../utils/sentry'
import { isEmpty, size } from 'lodash'
import { useAppContext } from '../../../app'
import { useContextInit } from '../../../hooks/useContextInit'
import { useDataPointsQuery, useEditDataTableDataPointValueMutation } from '../../../graphql/codegen/hooks'
import { useParams } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import Loader from '../../Loader'
import React, { Dispatch, FC, SetStateAction, createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import copy from 'copy-to-clipboard'
import usePromptOnUnmount from '../../../hooks/usePromptOnUnmount'

// types

type _CopyTableProps = { color?: 'default' | 'inherit' }
type _DataTableContext = { columns: string[]; handleCopy: () => void; handleDownload: () => void; hasUnsavedChanges: boolean; rows?: _Row[] }
type _DataTableSpreadsheetProps = { dealIsFinalized: boolean; setActiveTableId: Dispatch<SetStateAction<string | null>>; tableId: string }
type _DownloadCsvProps = { color?: 'default' | 'inherit' }
export type _Row = { [k: string]: string }

// enums

enum ValidationErrorType {
  Duplicate = 'duplicate',
  Extra = 'extra',
  Missing = 'missing',
  NoHeader = 'noHeader'
}

// context

const DataTableContext = createContext<_DataTableContext | null>(null)

// hooks

export const useDataTableContext = () => useContextInit(DataTableContext)

// functions

const copyDataTable = (dataTable: DataPointDataTable, setToast: Dispatch<SetStateAction<string>>) => {
  const [columns, rows] = getDataTableColumnsAndRows(dataTable)

  let counter = 1

  copy(
    [
      ...(columns ? [columns.map(column => column || `<Untitled ${counter++}>`)] : []),
      ...(columns ? rows : [rows[0].map(row => row || `<Untitled ${counter++}>`), ...rows.slice(1)])
    ]
      .map(row => row.join('\t'))
      .join('\n'),
    {
      format: 'text/plain'
    }
  )

  setToast('Table copied to clipboard.')
}

const downloadDataTableCsvFile = (dataTable: DataPointDataTable) => {
  const [columns, rows] = getDataTableColumnsAndRows(dataTable)

  let counter = 1

  const options = {
    filename: 'table',
    ...(columns && { headers: columns.map(column => `"${column || `<Untitled ${counter++}>`}"`) }),
    showLabels: true
  }

  new ExportToCsv(options).generateCsv(columns ? rows : [rows[0].map(row => row || `<Untitled ${counter++}>`), ...rows.slice(1)])
}

const getDataTableColumnsAndRows = (dataTable: DataPointDataTable): [string[] | undefined, string[][]] => {
  const columns = dataTable.columns as string[] | undefined
  const rows = (JSON.parse(dataTable.rows!) as string[]).map(row => Object.values(row))

  return [columns, rows]
}

const getValidationError = (type: ValidationErrorType, columns: string[] | number[]) => {
  const count = size(columns)
  const isSingular = count === 1

  const messages = {
    duplicate: {
      columns: columns.map(column => `"${column}"`),
      prefix: `Duplicate column name${isSingular ? '' : 's'} detected:`,
      suffix: 'Please ensure all column names are unique before saving.'
    },
    extra: {
      columns: columns.map(column => `"${column}"`),
      prefix: `Extra column${isSingular ? '' : 's'} detected:`,
      suffix: `Please remove ${isSingular ? 'this column' : 'these columns'} before saving.`
    },
    missing: {
      columns: columns.map(column => `"${column}"`),
      prefix: `Missing required column${isSingular ? '' : 's'}:`,
      suffix: `Please add ${isSingular ? 'this column' : 'these columns'} before saving.`
    },
    noHeader: {
      columns: (columns as number[]).map(column => `"${String.fromCharCode(65 + column)}"`),
      prefix: `Column${isSingular ? '' : 's'} without headers detected:`,
      suffix: `Please add ${isSingular ? 'a column header' : 'column headers'} before saving.`
    }
  }

  const { columns: formattedColumns, prefix, suffix } = messages[type]

  return `${prefix} ${formattedColumns.join(', ')}. ${suffix}`
}

const validateSpreadsheetData = (
  newColumns: string[],
  initialColumns: string[],
  emptyColumnHeaderIndices: number[],
  hasTableCompositionConfig: boolean,
  setValidationError: (error: string) => void
): boolean => {
  const initialColumnset = new Set(initialColumns)
  const newColumnSet = new Set(newColumns)

  const duplicateColumns = newColumns.filter((column, index) => newColumns.slice(0, index).some(_column => _column.toLowerCase() === column.toLowerCase()))
  const missingColumns = initialColumns.filter(column => !newColumnSet.has(column))
  const extraColumns = newColumns.filter(column => !initialColumnset.has(column))

  let validationError = ''

  if (!isEmpty(emptyColumnHeaderIndices)) {
    validationError = getValidationError(ValidationErrorType.NoHeader, emptyColumnHeaderIndices)
  }

  if (!isEmpty(duplicateColumns)) {
    validationError = getValidationError(ValidationErrorType.Duplicate, duplicateColumns)
  }

  if (hasTableCompositionConfig) {
    if (!isEmpty(missingColumns)) {
      validationError = getValidationError(ValidationErrorType.Missing, missingColumns)
    }

    if (!isEmpty(extraColumns)) {
      validationError = getValidationError(ValidationErrorType.Extra, extraColumns)
    }
  }

  setValidationError(validationError)

  return !validationError
}

// components

const CopyTable: FC<_CopyTableProps> = ({ color = 'default' }) => {
  const { handleCopy } = useDataTableContext()

  return (
    <Tooltip PopperProps={{ sx: { zIndex: Z_INDEX_OVERLAY } }} arrow title="Copy table">
      <IconButton color={color} onClick={handleCopy} size="small">
        <ContentCopyIcon />
      </IconButton>
    </Tooltip>
  )
}

export const DataTableSpreadsheet: FC<_DataTableSpreadsheetProps> = ({ dealIsFinalized, setActiveTableId, tableId }) => {
  const { dealId } = useParams<{ dealId?: string }>()
  const { setErrorMessage, setExtendedErrorMessage } = useAppContext()

  const [absoluteHeight, setAbsoluteHeight] = useState(512)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [initialColumns, setInitialColumns] = useState<string[]>([])
  const [initialRows, setInitialRows] = useState<_Row[] | undefined>(undefined)
  const [isRefetching, setIsRefetching] = useState(false)
  const [toast, setToast] = useState('')
  const [validationError, setValidationError] = useState<string>('')

  const spreadsheetRef = useRef<_SpreadsheetEditorRef>(null)

  const {
    data,
    loading: areDataPointsLoading,
    refetch
  } = useDataPointsQuery({
    variables: { id: tableId },
    onCompleted: ({ data_points }) => {
      const dataPoint = data_points?.edges[0]?.node
      const dataTable = dataPoint?.value_data_table
      const tableCompositionConfig = dataPoint?.data_point_field?.table_composition_config?.edges || []

      const tableCompositionConfigColumns = tableCompositionConfig
        .filter((edge): edge is NonNullable<typeof edge> => edge !== null)
        .map(edge => edge.node?.column_name)
        .filter((name): name is string => name !== null && name !== undefined)

      if (isEmpty(tableCompositionConfigColumns) && isEmpty(dataTable?.columns)) {
        setInitialColumns([])
        setInitialRows([])

        return
      }

      // If `dataTable` exists, use its columns as the base and append any missing `tableCompositionConfig` columns.
      if (dataTable?.columns && dataTable?.rows) {
        try {
          const dataTableColumns = dataTable.columns as string[]

          // Find columns that are in `tableCompositionConfigColumns` but not in `dataTableColumns`.
          const missingColumns = tableCompositionConfigColumns.filter(column => !dataTableColumns.some(existing => existing.trim() === column.trim()))

          if (!initialRows || !hasUnsavedChanges) {
            setInitialColumns([...dataTableColumns, ...missingColumns])
            setInitialRows(JSON.parse(dataTable.rows))
          }

          return
        } catch (error) {
          captureError(error)
        }
      }

      // If `dataTable` is null or parsing failed, use allowed columns from `tableCompositionConfig`.
      if (!initialRows || !hasUnsavedChanges) {
        setInitialColumns(tableCompositionConfigColumns)
        setInitialRows([])
      }
    }
  })

  const [editDataTableDataPointValue, { loading: isLoading }] = useEditDataTableDataPointValueMutation({
    onCompleted: () => {
      setToast('Table saved.')
      setHasUnsavedChanges(false)

      if (spreadsheetRef.current) {
        const spreadsheetData = spreadsheetRef.current.getSpreadsheetData()

        if (spreadsheetData) {
          setInitialColumns(spreadsheetData.columns)
          setInitialRows(spreadsheetData.rows)
        }
      }
    }
  })

  const dataTable = useMemo(() => data?.data_points?.edges[0]?.node?.value_data_table, [data?.data_points?.edges])

  const tableCompositionConfig = useMemo(
    () => data?.data_points?.edges[0]?.node?.data_point_field?.table_composition_config?.edges || [],
    [data?.data_points?.edges]
  )

  const columns = useMemo<string[]>(() => {
    const dataPoint = data?.data_points?.edges[0]?.node
    const tableCompositionConfig = dataPoint?.data_point_field?.table_composition_config?.edges || []

    if (dataTable?.columns) {
      const dataTableColumns = dataTable.columns as string[]

      if (!isEmpty(tableCompositionConfig)) {
        const tableCompositionConfigColumns = tableCompositionConfig
          .filter((edge): edge is NonNullable<typeof edge> => edge !== null)
          .map(edge => edge.node?.column_name)
          .filter((name): name is string => name !== null && name !== undefined)

        const missingColumns = tableCompositionConfigColumns.filter(column => !dataTableColumns.some(existing => existing.trim() === column.trim()))
        return [...dataTableColumns, ...missingColumns]
      }

      return dataTableColumns
    }

    if (!isEmpty(tableCompositionConfig)) {
      return tableCompositionConfig
        .filter((edge): edge is NonNullable<typeof edge> => edge !== null)
        .map(edge => edge.node?.column_name)
        .filter((name): name is string => name !== null && name !== undefined)
    }

    return []
  }, [data?.data_points?.edges, dataTable?.columns])

  const rows = useMemo(() => {
    const rowData = dataTable?.rows

    if (!rowData) return initialRows

    try {
      return JSON.parse(rowData)
    } catch (error) {
      captureError(error)
      return initialRows
    }
  }, [dataTable?.rows, initialRows])

  // functions

  const handleCopy = useCallback(() => {
    if (dataTable) copyDataTable(dataTable, setToast)
  }, [dataTable])

  const handleDownload = useCallback(() => {
    if (dataTable) downloadDataTableCsvFile(dataTable)
  }, [dataTable])

  const handleRevert = useCallback(() => {
    if (!spreadsheetRef.current || !initialRows) return

    const workbook = spreadsheetRef.current.getWorkbook()

    if (!workbook) return

    spreadsheetRef.current.initializeSheet(initialColumns, initialRows)

    setHasUnsavedChanges(false)
  }, [initialColumns, initialRows])

  const handleSave = useCallback(() => {
    if (!hasUnsavedChanges || !spreadsheetRef.current) return

    const spreadsheetData = spreadsheetRef.current.getSpreadsheetData()

    if (!spreadsheetData) return

    const { columns: newColumns, emptyColumnHeaderIndices, rows: newRows } = spreadsheetData

    const isSpreadsheetDataValid = validateSpreadsheetData(
      newColumns,
      initialColumns,
      emptyColumnHeaderIndices,
      !isEmpty(tableCompositionConfig),
      setValidationError
    )

    if (!isSpreadsheetDataValid) return

    setValidationError('')

    const dataPointFieldId = data?.data_points?.edges[0]?.node?.data_point_field?.id!
    const documentId = data?.data_points?.edges[0]?.node?.document?.id || ''

    editDataTableDataPointValue({
      onError: error => {
        setErrorMessage(error.message === 'Failed to fetch' ? 'Failed to save table changes.' : error.message)
        setExtendedErrorMessage(error.stack || error.message)
      },
      variables: {
        dataPointFieldId,
        dealId: documentId ? '' : dealId!,
        documentId,
        tableColumns: newColumns,
        tableRows: JSON.stringify(newRows)
      }
    })
  }, [
    data?.data_points?.edges,
    dealId,
    editDataTableDataPointValue,
    hasUnsavedChanges,
    initialColumns,
    setErrorMessage,
    setExtendedErrorMessage,
    tableCompositionConfig
  ])

  const handleSpreadsheetChange = useCallback(() => {
    if (!dealIsFinalized) {
      const spreadsheetData = spreadsheetRef.current?.getSpreadsheetData()

      if (spreadsheetData) {
        setHasUnsavedChanges(!spreadsheetData.isUnchanged)

        setValidationError('')
      }
    }
  }, [dealIsFinalized])

  const handleTableClose = useCallback(() => {
    if (hasUnsavedChanges) {
      if (window.confirm('You have unsaved changes in this table. Are you sure you want to close it? Click "OK" to close or "Cancel" to stay on this page.')) {
        setActiveTableId(null)
      }
    } else {
      setActiveTableId(null)
    }
  }, [hasUnsavedChanges, setActiveTableId])

  // context

  const context = useMemo<_DataTableContext>(
    () => ({ columns, handleCopy, handleDownload, hasUnsavedChanges, rows }),
    [columns, handleCopy, handleDownload, hasUnsavedChanges, rows]
  )

  // effects

  usePromptOnUnmount(hasUnsavedChanges, 'You have unsaved changes in this table. Are you sure you want to leave? Click "Cancel" to stay on this page.')

  useEffect(() => {
    const handleTableSwitch = (_event: Event) => {
      const event = _event as CustomEvent<{ nextTableId: string }>

      if (hasUnsavedChanges) {
        const proceed = window.confirm(
          'You have unsaved changes in this table. Are you sure you want to switch tables? Click "OK" to switch or "Cancel" to stay on this page.'
        )

        if (!proceed) event.preventDefault()
      }
    }

    window.addEventListener('checkUnsavedTableChanges', handleTableSwitch)

    return () => window.removeEventListener('checkUnsavedTableChanges', handleTableSwitch)
  }, [hasUnsavedChanges])

  useEffect(() => {
    setHasUnsavedChanges(false)
    setValidationError('')

    setIsRefetching(true)

    refetch().finally(() => setIsRefetching(false))
  }, [refetch, tableId])

  // render

  console.log({ columns, rows })

  return (
    <DataTableContext.Provider value={context}>
      <Resizable
        height={absoluteHeight}
        isDealDataTable={Boolean(dealId)}
        onResize={() => spreadsheetRef.current?.getWorkbook()?.refresh()}
        setHeight={setAbsoluteHeight}
      >
        <Box sx={{ height: absoluteHeight }}>
          {areDataPointsLoading || isRefetching ? (
            <Loader />
          ) : (
            <>
              {validationError && (
                <Alert
                  onClose={() => setValidationError('')}
                  severity="warning"
                  sx={{ borderRadius: 0, left: 0, padding: '3px 16px', position: 'absolute', top: '4px', width: 'calc(100% - 230px)', zIndex: Z_INDEX_OVERLAY }}
                >
                  {validationError}
                </Alert>
              )}

              <Box
                sx={{
                  alignItems: 'center',
                  bgcolor: '#f3f4f8',
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  justifyContent: 'space-between',
                  padding: '8px 4px 4px'
                }}
              >
                <Box sx={{ display: 'flex', gap: 0.5, mr: 0.5 }}>
                  {!dealIsFinalized && (
                    <>
                      <Button color="inherit" disabled={!hasUnsavedChanges || isLoading} onClick={handleRevert} size="small" variant="outlined">
                        Revert
                      </Button>

                      <Button color="primary" disabled={!hasUnsavedChanges || isLoading} onClick={handleSave} size="small" variant="contained">
                        Save Changes
                      </Button>
                    </>
                  )}

                  <Tooltip arrow title="Close table">
                    <IconButton onClick={handleTableClose} size="small">
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </Box>

                {Boolean(dataTable) && (
                  <Box display="flex" gap={0.5} ml={0.5}>
                    <CopyTable />

                    <DownloadCsv />
                  </Box>
                )}
              </Box>

              <SpreadsheetEditor
                columns={columns}
                id={tableId}
                isReadOnly={dealIsFinalized}
                onChange={handleSpreadsheetChange}
                ref={spreadsheetRef}
                rows={rows}
              />
            </>
          )}
        </Box>
      </Resizable>

      <Dialog disableEscapeKeyDown open={isLoading} sx={{ zIndex: Z_INDEX_OVERLAY }}>
        <DialogContent sx={{ alignItems: 'center', display: 'flex', gap: 3, p: 6 }}>
          <CircularProgress size={24} />

          <Typography variant="body1">Saving table changes…</Typography>
        </DialogContent>
      </Dialog>

      <Toast message={toast} setMessage={setToast} />
    </DataTableContext.Provider>
  )
}

const DownloadCsv: FC<_DownloadCsvProps> = ({ color = 'default' }) => {
  const { handleDownload } = useDataTableContext()

  return (
    <Tooltip PopperProps={{ sx: { zIndex: Z_INDEX_OVERLAY } }} arrow title="Download CSV">
      <IconButton color={color} onClick={handleDownload} size="small">
        <FileDownloadOutlinedIcon />
      </IconButton>
    </Tooltip>
  )
}

import { ActiveComponents, useCciMainContext } from '../../../CCI_Main'
import { CciDealDataPointFieldsDocument, MatchingChildrenDataPointFieldsDocument, useCreateDataPointFieldMutation } from '../../../../../graphql/codegen/hooks'
import { FC, useCallback, useEffect } from 'react'
import { formatMentions } from '../../../../../utils/commentUtils'
import { isEmpty, snakeCase } from 'lodash'
import { toast } from 'react-toastify'

// types

type _CreateNewFieldProps = { closeModal?: (options?: { isForced?: boolean }) => void; modalContent: _ModalContent }

type _ModalContent = { createExtractionConfiguration?: (dataPointFieldId: string) => Promise<void>; extractionMethodId?: string; values?: any }

// constants

export const CREATE_FIELD_REFETCH_QUERIES = [{ query: CciDealDataPointFieldsDocument }, { query: MatchingChildrenDataPointFieldsDocument }]

// components

export const CreateNewField: FC<_CreateNewFieldProps> = ({ closeModal, modalContent }) => {
  const { createExtractionConfiguration, extractionMethodId, values } = modalContent || {}
  const { expandAllCallback, fieldOverlay, setActiveComponent, setMessageTitle, setModalError, setModalLoading, setModalLoadingMessage, setSelectedItem } =
    useCciMainContext()

  const [createDataPointFieldMutation, { error: mutationError, loading: isMutationLoading }] = useCreateDataPointFieldMutation({
    awaitRefetchQueries: true,
    onCompleted: response => {
      const dataPointField = response.create_data_point_field!.data_point_field!

      fieldOverlay.setFieldId(dataPointField.id)
      setSelectedItem(dataPointField)

      if (extractionMethodId) {
        createExtractionConfiguration?.(dataPointField.id)
      } else {
        closeModal?.({ isForced: true })
        expandAllCallback?.()
        setActiveComponent(ActiveComponents.EDIT_FIELD)

        toast.success('Field successfully created', { autoClose: 5000 })
      }
    },
    refetchQueries: extractionMethodId ? undefined : CREATE_FIELD_REFETCH_QUERIES
  })

  const formatOptions = useCallback(() => {
    if (!values?.options?.length) return undefined

    // Strip option level neutral tags if tag type is field level.
    // TODO: Reevaluate whether this is necessary – it was copied from the original implementation.
    return JSON.stringify(values?.tag_type !== 'option_level' ? values?.options?.map((item: any) => ({ option: item?.option })) : values?.options)
  }, [values])

  useEffect(() => {
    if (isMutationLoading) {
      setModalLoading(true)
      setModalLoadingMessage('Creating Field…')
    }
  }, [isMutationLoading, setModalLoading, setModalLoadingMessage])

  useEffect(() => {
    if (mutationError) {
      setMessageTitle('Error creating field:')
      setModalError(mutationError)
      setModalLoading(false)
      setModalLoadingMessage('')
    }
  }, [mutationError, setMessageTitle, setModalError, setModalLoading, setModalLoadingMessage])

  useEffect(() => {
    const localValues = { ...values }

    if (isEmpty(localValues)) return

    if (localValues.field_semantics) {
      localValues.field_semantics = formatMentions(localValues.field_semantics)
    }

    if (localValues.source === 'INTERNAL' && !localValues?.neutral_tag) {
      localValues.neutral_tag = snakeCase(localValues.name)
    }

    const options = formatOptions()

    delete localValues.matchFieldType
    delete localValues.matchFieldLabelList
    delete localValues.options
    delete localValues.tag_type

    createDataPointFieldMutation({ variables: { options, ...localValues } })
  }, [createDataPointFieldMutation, formatOptions, values])

  return null // Rendering not required – this component is used solely for executing the mutation.
}

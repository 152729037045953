import { useCallback, useState } from 'react'

// types

export type FieldOverlay = {
  close: () => void
  fieldId?: string
  handleExit: () => void
  isClosing: boolean
  isOpen: boolean
  open: (id?: string) => void
  setFieldId: (id?: string) => void
}

// hooks

export const useFieldOverlay = (): FieldOverlay => {
  const [fieldId, setFieldId] = useState<string>()
  const [isClosing, setIsClosing] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const close = useCallback(() => {
    if (isClosing || !isOpen) return

    setIsClosing(true)
  }, [isClosing, isOpen])

  const handleExit = useCallback(() => {
    if (!isClosing || !isOpen) return

    setFieldId(undefined)
    setIsClosing(false)
    setIsOpen(false)
  }, [isClosing, isOpen])

  const open = useCallback(
    (id?: string) => {
      if (isClosing || isOpen) return

      setFieldId(id)
      setIsOpen(true)
    },
    [isClosing, isOpen]
  )

  return { close, fieldId, handleExit, isClosing, isOpen: isOpen || isClosing, open, setFieldId }
}

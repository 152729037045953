import { Button, Link, Menu, MenuItem } from '@mui/material'
import { Features, Permissions, useUserAccess } from '../../hooks/useUserAccess'
import { KLARITY_BLUE, Z_INDEX_OVERLAY } from '../../utils/styleUtils'
import { NAV_LINK_SX } from './NavBar'
import { NavLink as RouterLink, useLocation, useRouteMatch } from 'react-router-dom'
import { useOpening } from '@hoologic/use-opening'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import React, { FC, useMemo } from 'react'

// constants

export enum ConfigNavLinks {
  CHECKLIST = '/config',
  INTEGRATIONS = '/config/integrations',
  ROLES = '/config/roles',
  USERS = '/config/users'
}

export const ConfigNavLinkLabels: { [key: string]: string } = {
  [ConfigNavLinks.CHECKLIST]: 'Checklist',
  [ConfigNavLinks.INTEGRATIONS]: 'Integrations',
  [ConfigNavLinks.ROLES]: 'Roles',
  [ConfigNavLinks.USERS]: 'Users'
}

// components

export const ConfigMenu: FC = () => {
  const location = useLocation()
  const opening = useOpening()
  const isConfigRoute = useRouteMatch({ path: '/config' })
  const hasConfigAdminAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.ADMIN })
  const hasConfigChecklistAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.CHECKLIST_TAB }) || hasConfigAdminAccess
  const hasConfigIntegrationsAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.INTEGRATIONS_TAB }) || hasConfigAdminAccess
  const hasConfigRolesAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.ROLES_TAB }) || hasConfigAdminAccess
  const hasConfigUsersAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.USERS_TAB }) || hasConfigAdminAccess

  const value = useMemo(() => ConfigNavLinkLabels[location.pathname] || '', [location.pathname])

  return (
    <>
      <Button
        color="inherit"
        endIcon={<KeyboardArrowDownIcon sx={{ ml: -0.75 }} />}
        onClick={opening.open}
        sx={{
          color: isConfigRoute ? KLARITY_BLUE : 'inherit',
          fontSize: 16,
          mx: -1,
          transition: 'none',
          whiteSpace: 'nowrap',
          '&:hover': { bgcolor: 'transparent', color: KLARITY_BLUE }
        }}
        variant="text"
      >
        Configuration{value ? ` : ${value}` : ''}
      </Button>

      <Menu
        anchorEl={opening.anchor}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={opening.close}
        open={opening.isOpen}
        sx={{ zIndex: Z_INDEX_OVERLAY }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {hasConfigChecklistAccess && (
          <Link component={RouterLink} exact sx={NAV_LINK_SX} to={ConfigNavLinks.CHECKLIST}>
            <MenuItem onClick={opening.close}>{ConfigNavLinkLabels[ConfigNavLinks.CHECKLIST]}</MenuItem>
          </Link>
        )}

        {hasConfigIntegrationsAccess && (
          <Link component={RouterLink} sx={NAV_LINK_SX} to={ConfigNavLinks.INTEGRATIONS}>
            <MenuItem onClick={opening.close}>{ConfigNavLinkLabels[ConfigNavLinks.INTEGRATIONS]}</MenuItem>
          </Link>
        )}

        {hasConfigRolesAccess && (
          <Link component={RouterLink} sx={NAV_LINK_SX} to={ConfigNavLinks.ROLES}>
            <MenuItem onClick={opening.close}>{ConfigNavLinkLabels[ConfigNavLinks.ROLES]}</MenuItem>
          </Link>
        )}

        {hasConfigUsersAccess && (
          <Link component={RouterLink} sx={NAV_LINK_SX} to={ConfigNavLinks.USERS}>
            <MenuItem onClick={opening.close}>{ConfigNavLinkLabels[ConfigNavLinks.USERS]}</MenuItem>
          </Link>
        )}
      </Menu>
    </>
  )
}

import { CCI_RightPanel_ChecklistGptTab } from './RightPanel_components/ChecklistGptTab/CCI_RightPanel_ChecklistGptTab'
import { CCI_RightPanel_ChecklistTab_Main } from './RightPanel_components/ChecklistTab/CCI_RightPanel_ChecklistTab_Main'
import { EmptyStateMessage } from '../../../../components/EmptyStateMessage'
import { Features, Permissions, useHasChecklistGptAccess, useUserAccess } from '../../../../hooks/useUserAccess'
import { Route, Switch } from 'react-router-dom'
import CCI_RightPanel_IntegrationsTab_Main from './RightPanel_components/IntegrationsTab/CCI_RightPanel_IntegrationsTab_Main'
import CCI_RightPanel_UsersTab_Main from './RightPanel_components/UsersTab/CCI_RightPanel_UsersTab_Main'
import React from 'react'
import css from './style.module.scss'

export default function CCI_RightPanel_Content() {
  const hasAdminAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.ADMIN })
  const hasChecklistGptAccess = useHasChecklistGptAccess()
  const hasChecklistTabAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.CHECKLIST_TAB })
  const hasIntegrationsTabAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.INTEGRATIONS_TAB })
  const hasRolesTabAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.ROLES_TAB })
  const hasUsersTabAccess = useUserAccess({ feature: Features.CCI, permission: Permissions.USERS_TAB })

  return (
    <div className={css.card}>
      <div className={css.initial}>
        <Switch>
          <Route exact path="/config">
            {(hasAdminAccess || hasChecklistTabAccess) && (hasChecklistGptAccess ? <CCI_RightPanel_ChecklistGptTab /> : <CCI_RightPanel_ChecklistTab_Main />)}
          </Route>

          <Route exact path="/config/integrations">
            {(hasAdminAccess || hasIntegrationsTabAccess) && <CCI_RightPanel_IntegrationsTab_Main />}
          </Route>

          <Route exact path="/config/roles">
            {(hasAdminAccess || hasRolesTabAccess) && <EmptyStateMessage message="View role assignments in the left panel" />}
          </Route>

          <Route exact path="/config/users">
            {(hasAdminAccess || hasUsersTabAccess) && <CCI_RightPanel_UsersTab_Main />}
          </Route>
        </Switch>
      </div>
    </div>
  )
}

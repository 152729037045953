import { Box, IconButton, Typography } from '@mui/material'
import { FiXSquare } from 'react-icons/fi'
import { useCciMainContext } from '../../../../../CCI_Main'
import { useChecklistConfigContext } from '../../../../ChecklistConfigProvider'
import FreeText from '../../../../../../../components/DatapointInput/FreeText'
import React, { FC, KeyboardEvent, SyntheticEvent, useCallback, useMemo, useRef, useState } from 'react'
import css from './style.module.scss'

// constants

const FIELD = 'name'

const LABEL = 'Field Name'

const PLACEHOLDER = 'Name the field'

// components

export const NameInput: FC = () => {
  const { selectedItem } = useCciMainContext()
  const { fieldValues, isEditView, updateFieldValue } = useChecklistConfigContext()
  const { name } = fieldValues || {}

  const initialValue = useMemo(() => selectedItem?.name, [selectedItem?.name])
  const isControlledByUser = useRef(false)

  const [inputValue, setInputValue] = useState('')
  const [isFocused, setFocused] = useState(false)

  const handleBlur = useCallback(() => {
    // This field is required, so the initial value should be restored if the user blurs on an empty field.
    if (isEditView && !inputValue) setInputValue(initialValue)
  }, [initialValue, inputValue, isEditView])

  const handleChange = useCallback(
    (event: SyntheticEvent<HTMLTextAreaElement, Event>) => {
      isControlledByUser.current = true

      const newValue = event.currentTarget.value

      setInputValue(newValue)
      updateFieldValue(FIELD, newValue)
    },
    [updateFieldValue]
  )

  const handleDelete = useCallback(() => {
    isControlledByUser.current = true

    if (isEditView) setFocused(true)

    setInputValue('')
    updateFieldValue(FIELD, '')
  }, [isEditView, updateFieldValue])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      if (isFocused && event.key === 'Enter') {
        setFocused(false)
        ;(event.target as HTMLElement).blur()
      }
    },
    [isFocused]
  )

  useMemo(() => {
    // Allow an empty string when manually editing the field (rather than having `selectedItem.name` override the empty string).
    setInputValue(isControlledByUser.current && !name ? '' : name || initialValue || '')
  }, [initialValue, name])

  return (
    <Box className={css.inputWrapper}>
      <Typography component="label" htmlFor="name-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        {LABEL}
      </Typography>

      <FreeText
        handleBlur={handleBlur}
        id="name-input"
        isFocused={isFocused}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={PLACEHOLDER}
        setFocused={setFocused}
        value={inputValue}
      />

      {inputValue && (
        <IconButton onClick={handleDelete} size="small" sx={{ borderRadius: 0.5, fontSize: 20, height: 28, ml: 1, mt: 0.25, padding: 0.5, width: 28 }}>
          <FiXSquare />
        </IconButton>
      )}
    </Box>
  )
}

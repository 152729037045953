import { useCallback, useEffect, useMemo, useRef } from 'react'

// types

type Message = any

type OnMessageCallback = (message: Message) => void

// hooks

export const useBroadcastChannel = (channelName: string, onMessage?: OnMessageCallback) => {
  const broadcastChannel = useMemo(() => new BroadcastChannel(channelName), [channelName])
  const onMessageRef = useRef(onMessage)

  useEffect(() => {
    onMessageRef.current = onMessage
  }, [onMessage])

  useEffect(() => {
    const messageHandler = (event: MessageEvent) => onMessageRef.current?.(event.data)

    broadcastChannel.addEventListener('message', messageHandler)

    return () => {
      broadcastChannel.removeEventListener('message', messageHandler)
      broadcastChannel.close()
    }
  }, [broadcastChannel])

  const postMessage = useCallback((message: Message) => broadcastChannel.postMessage(message), [broadcastChannel])

  return { postMessage }
}

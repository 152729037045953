import { Alert } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'
import { captureError } from '../../utils/sentry'
import { useAppContext } from '../../app'
import React, { FC, PropsWithChildren, useEffect } from 'react'

export const SpreadsheetErrorBoundary: FC<PropsWithChildren<unknown>> = ({ children }) => (
  // SpreadJS may encounter errors other than file import errors (e.g., when the license key is invalid), hence the need for a custom error boundary.
  <ErrorBoundary FallbackComponent={SpreadsheetErrorFallback}>{children}</ErrorBoundary>
)

const SpreadsheetErrorFallback: FC<{ error: Error }> = ({ error }) => {
  const { setErrorMessage } = useAppContext()

  useEffect(() => {
    captureError(error)

    setErrorMessage(error.message)
  }, [error, setErrorMessage])

  return (
    <Alert severity="error" sx={{ borderRadius: 0 }} variant="filled">
      Unable to load spreadsheet viewer. Please contact support if this issue persists.
    </Alert>
  )
}

import { useHasChecklistGptAccess } from './useUserAccess'
import { useIsProductionTenant } from './useTenantType'

// hooks

export const useIsExtractionRerunEnabled = () => {
  const hasChecklistGptAccess = useHasChecklistGptAccess()
  const isProductionTenant = useIsProductionTenant()

  return hasChecklistGptAccess && !isProductionTenant
}

import { AiOutlineTable } from 'react-icons/ai'
import { IconButton, Tooltip } from '@mui/material'
import { useDocumentPageWrapperContext } from '../DocumentPageWrapper'
import React, { FC } from 'react'
import type { DataPoint } from '../../graphql/codegen/schemas'

// types

type _DataTableIconProps = { dataPoint: DataPoint }

// components

export const DataTableIcon: FC<_DataTableIconProps> = ({ dataPoint }) => {
  const { activeTableId, dataTableMatchingOpening, setActiveTableId } = useDocumentPageWrapperContext()

  const handleTableOpen = () => {
    const newTableId = dataPoint.resolved_data_points?.edges?.[0]?.node?.id || dataPoint.id

    // Prompt the user to save if there are unsaved changes.
    if (activeTableId && activeTableId !== newTableId) {
      const canProceed = window.dispatchEvent(new CustomEvent('checkUnsavedTableChanges', { cancelable: true, detail: { nextTableId: newTableId } }))

      if (!canProceed) return
    }

    dataTableMatchingOpening.close()

    setActiveTableId(newTableId)
  }

  return (
    <Tooltip arrow placement="top" title="Open data table">
      <IconButton aria-label="Open data table" onClick={handleTableOpen} sx={{ mr: 0.25 }}>
        <AiOutlineTable />
      </IconButton>
    </Tooltip>
  )
}

import { useEffect, useState } from 'react'

export const useHasPageBeenViewed = () => {
  const [hasBeenViewed, setHasBeenViewed] = useState(document.visibilityState === 'visible')

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') setHasBeenViewed(true)
    }

    handleVisibilityChange()

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => document.removeEventListener('visibilitychange', handleVisibilityChange)
  }, [])

  return hasBeenViewed
}

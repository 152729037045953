import { Box, Typography } from '@mui/material'
import { Features, Permissions, useUserAccess } from '../../../../../../../hooks/useUserAccess'
import { OptionProps, OptionTypeBase, SingleValueProps, components } from 'react-select'
import { grey } from '@mui/material/colors'
import { useCciMainContext } from '../../../../../CCI_Main'
import { useChecklistConfigContext } from '../../../../ChecklistConfigProvider'
import { usePrevious } from '../../../../../../../hooks/usePrevious'
import React, { FC, useEffect, useMemo } from 'react'
import SelectInput from '../../../../../../../components/SelectInput'
import css from './style.module.scss'

// types

interface _ActionTypeOption extends OptionTypeBase {
  label: string
  value: string
}

// enums

export enum ActionTypes {
  FORMULA_ACROSS_ACTIVE_FILES = 'Static Formula',
  FORMULA_WITHIN_A_SINGLE_FILE = 'Formula',
  FREE_TEXT_AND_TABULAR_EXTRACTION = 'Free-Text and tabular extraction',
  FREE_TEXT_EXTRACTION = 'Free-text Extraction',
  INTEGRATION = 'Integration',
  MANUAL = 'Manual',
  MATCHING = 'Matching',
  TABLE_COMPOSITION = 'Table Composition',
  TABLE_MATCHING = 'Table Matching',
  TABULAR_EXTRACTION = 'Tabular Extraction'
}

// constants

const FIELD = 'action_type_id'

const LABEL = 'Action Type'

const PLACEHOLDER = 'Select the type of action to configure'

// functions

export const getActionTypeId = (actionTypeMap: { [key: string]: any }, actionType: ActionTypes) =>
  Object.keys(actionTypeMap).find(id => actionTypeMap[id].name === actionType)

// components

const ActionTypeDisplayValue: FC<{ children: string }> = ({ children, ...rest }) => {
  const [preColon, postColon] = children.split(':')

  return (
    <Typography component="span" sx={{ fontWeight: 600 }} variant="subtitle2" {...rest}>
      {preColon}: <span style={{ fontWeight: 'normal' }}>{postColon}</span>
    </Typography>
  )
}

export const ActionTypeInput: FC = () => {
  const { isModalOpen, selectedItem } = useCciMainContext()
  const { actionTypeMap, fieldValues, isCreateView, setExtractionConfigurationChanges, updateFieldValue } = useChecklistConfigContext()

  const hasFullCreateAccess = useUserAccess({ feature: Features.CCI_CHECKLIST_TAB, permission: Permissions.CREATE_FIELD })

  const optionList = useMemo(() => Object.entries(actionTypeMap).map(([id, actionType]) => ({ label: actionType.description, value: id })), [actionTypeMap])

  const selectedActionTypeId = fieldValues?.action_type_id
  const previousActionTypeId = usePrevious(selectedActionTypeId)

  useEffect(() => {
    if (!hasFullCreateAccess) {
      setTimeout(() => updateFieldValue(FIELD, getActionTypeId(actionTypeMap, ActionTypes.MANUAL)))
    }
  }, [actionTypeMap, hasFullCreateAccess, updateFieldValue])

  useEffect(() => {
    // Reset dependent field values when the action type changes.
    if (selectedActionTypeId && selectedActionTypeId !== previousActionTypeId) {
      const selectedActionType = actionTypeMap[selectedActionTypeId]

      if (selectedActionType) {
        updateFieldValue('field_type', null)
        updateFieldValue('extraction_field_type', selectedActionType.extraction_field_type ? [selectedActionType.extraction_field_type] : null)
        updateFieldValue('source', (selectedActionType.source !== 'EXTERNAL' && selectedActionType.source) || null)

        // Check `!isModalOpen` to avoid overwriting user changes in re-renders during 2-stage field-creation process. See: ExtractionAutomationSwitch.tsx
        if (!isModalOpen) setExtractionConfigurationChanges(null)
      }
    }
  }, [actionTypeMap, isModalOpen, previousActionTypeId, selectedActionTypeId, setExtractionConfigurationChanges, updateFieldValue])

  return isCreateView ? (
    <Box className={css.inputWrapper}>
      <Typography component="label" htmlFor="action-type-input" sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        {LABEL}
      </Typography>

      <SelectInput
        components={{ Option: ActionTypeOption, SingleValue: ActionTypeSingleValue }}
        id="action-type-input"
        isDisabled={!hasFullCreateAccess}
        onChange={(option: _ActionTypeOption) => updateFieldValue(FIELD, option.value)}
        options={optionList}
        placeholder={selectedActionTypeId || PLACEHOLDER}
        value={optionList.find(option => option.value === selectedActionTypeId)}
      />
    </Box>
  ) : actionTypeMap[selectedItem?.action_type?.id] ? (
    <Box sx={{ alignItems: 'center', display: 'flex', height: 38, mb: 1 }}>
      <Typography sx={{ fontWeight: 600, mr: 2, width: 164 }} variant="body2">
        {LABEL}
      </Typography>

      <Typography sx={{ backgroundColor: grey[100], border: `1px solid ${grey[300]}`, borderRadius: 1, flex: 1, p: 1 }} variant="body2">
        {actionTypeMap[selectedItem.action_type.id].name}
      </Typography>
    </Box>
  ) : null
}

const ActionTypeOption: FC<OptionProps<_ActionTypeOption, false>> = props => (
  <components.Option {...props}>
    <ActionTypeDisplayValue>{props.data.label}</ActionTypeDisplayValue>
  </components.Option>
)

const ActionTypeSingleValue: FC<SingleValueProps<_ActionTypeOption>> = props => (
  <components.SingleValue {...props}>
    <ActionTypeDisplayValue>{props.data.label}</ActionTypeDisplayValue>
  </components.SingleValue>
)

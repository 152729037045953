import { Document } from '../graphql/codegen/schemas'
import { useDealAttachmentsQuery, useNotesTabCountQuery } from '../graphql/codegen/hooks'
import { useParams } from 'react-router-dom'
import DataPointPanel from '../components/DataPointPanel'
import React from 'react'

// types

type DealDataPointPanelContainerProps = {
  dealIsFinalized: boolean
  documents?: Document[]
  onChecklistDataLoaded: () => void
}

// components

const DealDataPointPanelContainer = ({ dealIsFinalized, documents, onChecklistDataLoaded }: DealDataPointPanelContainerProps) => {
  const { dealId } = useParams<{ dealId: string }>()

  const { data: dealAttachmentsData } = useDealAttachmentsQuery({ variables: { dealId } })
  const { data: notesTabCountData } = useNotesTabCountQuery({ variables: { resourceId: dealId } })

  const attachmentCount = dealAttachmentsData?.deal_attachments?.filter(item => !item?.is_deleted).length || 0
  const noteCount = notesTabCountData?.notes_tab_count || 0

  return (
    <DataPointPanel
      attachmentCount={attachmentCount}
      dealDocuments={documents}
      dealId={dealId}
      dealIsFinalized={dealIsFinalized}
      noteCount={noteCount}
      onChecklistDataLoaded={onChecklistDataLoaded}
    />
  )
}

export default DealDataPointPanelContainer

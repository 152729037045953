import { isValid, parseISO } from 'date-fns'
import { useRefCallback } from '../../hooks/useRefCallback'
import React, { FC, useEffect } from 'react'
import ReactDatePicker from 'react-datepicker'
import css from './style.module.scss'

// types

type _DatePickerProps = { label?: string; onChange: (datestring: Date | null) => void; value: string | null }

// components

export const DatePicker: FC<_DatePickerProps> = ({ label, onChange, value }) => {
  const [element, refCallback] = useRefCallback()

  // ReactDatePicker does not support aria-label, so it must be set manually.
  useEffect(() => {
    element?.querySelector('input')?.setAttribute('aria-label', 'Enter a date')
  }, [element])

  const parsedDate = value ? parseISO(value) : null

  return (
    <div className={css.root} ref={refCallback}>
      {label && <div className={css.label}>{label}</div>}

      <ReactDatePicker onChange={onChange} selected={isValid(parsedDate) ? parsedDate : null} />
    </div>
  )
}

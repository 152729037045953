import { Box, Slide } from '@mui/material'
import { CCI_RightPanel_ChecklistGptTab } from '../RightPanel/RightPanel_components/ChecklistGptTab/CCI_RightPanel_ChecklistGptTab'
import { DataPointField } from '../../../../graphql/codegen/schemas'
import { Z_INDEX_OVERLAY } from '../../../../utils/styleUtils'
import { common } from '@mui/material/colors'
import { useCciDealDataPointFieldsQuery } from '../../../../graphql/codegen/hooks'
import { useCciMainContext } from '../../CCI_Main'
import React, { FC, useEffect, useMemo, useRef } from 'react'

// components

export const FieldOverlay: FC = () => {
  const { data: fieldsData } = useCciDealDataPointFieldsQuery()
  const { fieldOverlay, setSelectedItem } = useCciMainContext()
  const scrollRef = useRef<HTMLDivElement>(null)

  const { fieldId } = useMemo(() => fieldOverlay, [fieldOverlay])

  const field = useMemo(() => fieldsData?.cci_deal_data_point_fields?.find(field => field?.id === fieldId) as DataPointField | undefined, [fieldsData, fieldId])

  useEffect(() => setSelectedItem(field || ''), [field, setSelectedItem])

  useEffect(() => scrollRef.current?.scrollTo(0, 0), [fieldId])

  return (
    <Slide direction="left" in={fieldOverlay.isOpen && !fieldOverlay.isClosing} onExited={fieldOverlay.handleExit}>
      <Box
        sx={{
          bgcolor: common.white,
          bottom: 0,
          boxShadow: '-4px 0 12px rgba(0, 0, 0, 0.25)',
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          right: 0,
          top: 0,
          width: 840,
          zIndex: Z_INDEX_OVERLAY
        }}
      >
        <Box ref={scrollRef} sx={{ flex: 1, overflowY: 'auto', px: 3 }}>
          <CCI_RightPanel_ChecklistGptTab />
        </Box>
      </Box>
    </Slide>
  )
}

import { ActionTypes } from '../../RightPanel/RightPanel_components/ChecklistGptTab/inputs/ActionTypeInput'
import { Typography } from '@mui/material'
import { useChecklistConfigContext } from '../../ChecklistConfigProvider'
import React, { FC } from 'react'

// types

type _ActionTypeCellProps = { actionTypeId: ActionTypes | string }

// components

export const ActionTypeCell: FC<_ActionTypeCellProps> = ({ actionTypeId }) => {
  const { actionTypeMap } = useChecklistConfigContext()

  return <Typography sx={{ fontSize: 14, whiteSpace: 'nowrap' }}>{actionTypeMap[actionTypeId]?.name || actionTypeId}</Typography>
}

import { ActiveComponents, useCciMainContext } from '../../../CCI_Main'
import { Box, IconButton, SvgIcon, Tooltip } from '@mui/material'
import { ReactComponent as Status } from '../../../../../assets/status.svg'
import { Statuses } from '../../../../../utils/cci'
import { common, grey, lightGreen, purple, red, yellow } from '@mui/material/colors'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import React, { FC, useCallback, useMemo } from 'react'

// types

type _FieldNameCellProps = { description?: string; id: string; isDeleted?: boolean; name: string; priority: number; status: Statuses | string }

// components

export const FieldNameCell: FC<_FieldNameCellProps> = ({ description, id, isDeleted, name, status }) => {
  const { documentTypesField, fieldOverlay, setActiveComponent } = useCciMainContext()

  const activeComponent = useMemo(
    () => (id === documentTypesField?.id ? ActiveComponents.EDIT_DOCUMENT_TYPES : ActiveComponents.EDIT_FIELD),
    [documentTypesField, id]
  )

  const { fieldId, setFieldId } = useMemo(() => fieldOverlay, [fieldOverlay])
  const isOpen = useMemo(() => id === fieldId && !fieldOverlay.isClosing, [fieldId, fieldOverlay.isClosing, id])

  const color = useMemo(() => {
    switch (status) {
      case Statuses.DRAFT:
        return purple[300]
      case Statuses.IN_REVIEW:
        return yellow[500]
      case Statuses.PUBLISHED:
        return lightGreen[700]
      default:
        return red[300]
    }
  }, [status])

  const iconButtonSx = useMemo(
    () => ({
      my: -1,
      ...(isOpen && { bgcolor: 'primary.main', color: common.white }),
      ':hover': { ...(isOpen ? { bgcolor: 'primary.main' } : { color: common.black }) },
      '& .MuiSvgIcon-root': { transform: 'rotate(-90deg)' }
    }),
    [isOpen]
  )

  const handleClick = useCallback(() => {
    if (!fieldOverlay.isOpen) {
      fieldOverlay.open(id)

      setActiveComponent(activeComponent)
    } else if (id === fieldId) {
      fieldOverlay.close()

      setActiveComponent(null)
    } else {
      setActiveComponent(activeComponent)

      setFieldId(id)
    }
  }, [activeComponent, fieldId, fieldOverlay, id, setActiveComponent, setFieldId])

  // the complex logic here ensures the tooltip is displayed correctly in both wrapping and non-wrapping cases

  return (
    <Box sx={{ alignItems: 'center', display: 'flex', gap: 1, justifyContent: 'space-between', width: '100%' }}>
      <Box sx={{ alignItems: 'center', display: 'flex', gap: 0.5 }}>
        <SvgIcon component={Status} sx={{ color, height: 18, position: 'relative', left: 1 }} />

        <Box component="span" sx={{ display: 'inline', ml: 1.25 }}>
          {name.includes(' ') ? name.replace(/\s+(\S+)$/, ' ') : ''}

          <Box component="span" sx={{ alignItems: 'center', display: 'inline-flex', gap: 0.5 }}>
            {name.includes(' ') ? name.match(/\s+(\S+)$/)?.[1] : name}

            {description && (
              <Tooltip arrow placement="top" title={description}>
                <InfoOutlinedIcon sx={{ color: grey[600], fontSize: 16 }} />
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>

      {isDeleted ? (
        <Tooltip arrow placement="top" title="Pending removal…">
          <DeleteOutlineIcon color="disabled" sx={{ mr: 0.75 }} />
        </Tooltip>
      ) : (
        <IconButton onClick={handleClick} size="small" sx={iconButtonSx}>
          <ExpandMoreIcon />
        </IconButton>
      )}
    </Box>
  )
}

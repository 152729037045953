import { ActionTypeCell } from './cells/ActionTypeCell'
import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { CONFIG_HEADER_HEIGHT } from '../Header'
import { CSS } from '@dnd-kit/utilities'
import { Column, HeaderGroup, Row, useTable } from 'react-table'
import { CorrectCell } from './cells/CorrectCell'
import { DndContext, DragEndEvent, closestCenter } from '@dnd-kit/core'
import { FieldNameCell } from './cells/FieldNameCell'
import { FieldOverlay } from './FieldOverlay'
import { FieldTypeCell } from './cells/FieldTypeCell'
import { HEADER_HEIGHT, Z_INDEX_DRAG } from '../../../../utils/styleUtils'
import { InReviewCell } from './cells/InReviewCell'
import { SamplesCell } from './cells/SamplesCell'
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { StatusCell } from './cells/StatusCell'
import { SystemSourceCell } from './cells/SystemSourceCell'
import { _Row, useChecklistConfigContext } from '../ChecklistConfigProvider'
import { common, grey } from '@mui/material/colors'
import { groupDataPointFields } from '../../../../utils/dataPointUtils'
import { isEmpty, isEqual, size, sortBy } from 'lodash'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { useCciMainContext } from '../../CCI_Main'
import { useContextInit } from '../../../../hooks/useContextInit'
import { useRefCallback } from '../../../../hooks/useRefCallback'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, { FC, createContext, useCallback, useEffect, useMemo, useState } from 'react'

// types

type _ChecklistTableContext = {
  areMultipleGroups: boolean
  firstRowRefCallback: (node: HTMLTableRowElement) => void
  prepareRow: (row: Row<_Row>) => void
  rows: Row<_Row>[]
  top: number
}

type _FieldRowProps = { fieldId: string; isOnlyFieldInGroup: boolean; row: Row<_Row> }
type _GroupAndFieldsRowsProps = { groupName: string }
type _GroupRowProps = { groupName: string }
type _HeaderRowProps = { headerGroup: HeaderGroup<_Row> }

// constants

const CELL_SX = { bgcolor: common.white, borderRight: `1px solid ${grey[300]}`, height: 49, py: 1 } as const

const SAMPLES_COLUMNS = ['Samples:', 'In Review', 'Correct']

const COLUMNS: Column<_Row>[] = [
  {
    Header: 'Field Name',
    accessor: 'fieldNameColumn',
    Cell: ({ value: { description, id, isDeleted, name, priority, status } }) => (
      <FieldNameCell description={description} id={id} isDeleted={isDeleted} name={name} priority={priority} status={status} />
    )
  },
  { Header: 'Status', accessor: 'statusColumn', Cell: ({ value: { mainStatus } }) => <StatusCell mainStatus={mainStatus} /> },
  {
    Header: 'System Source',
    accessor: 'systemSourceColumn',
    Cell: ({ value: { externalSource, source } }) => <SystemSourceCell externalSource={externalSource} source={source} />
  },
  {
    Header: SAMPLES_COLUMNS[0],
    accessor: 'samplesColumn',
    Cell: ({ value: { totalSamples } }) => <SamplesCell totalSamples={totalSamples} />
  },
  {
    Header: SAMPLES_COLUMNS[1],
    accessor: 'inReviewColumn',
    Cell: ({ value: { inReviewSamples } }) => <InReviewCell inReviewSamples={inReviewSamples} />
  },
  {
    Header: SAMPLES_COLUMNS[2],
    accessor: 'correctColumn',
    Cell: ({ value: { correctSamples } }) => <CorrectCell correctSamples={correctSamples} />
  },
  {
    Header: 'Field Type',
    accessor: 'fieldTypeColumn',
    Cell: ({ value: { fieldType } }) => <FieldTypeCell fieldType={fieldType} />
  },
  {
    Header: 'Action Type',
    accessor: 'actionTypeColumn',
    Cell: ({ value: { actionTypeId } }) => <ActionTypeCell actionTypeId={actionTypeId} />
  }
] as const

const FIRST_CELL_SX = {
  bgcolor: common.white,
  borderRight: `2px solid ${grey[300]}`,
  height: 49,
  left: 0,
  pl: 6.25,
  position: 'sticky',
  py: 1,
  zIndex: 2
} as const

// context

const ChecklistTableContext = createContext<_ChecklistTableContext | null>(null)

// hooks

const useChecklistTableContext = () => useContextInit(ChecklistTableContext)

// components

export const Checklist: FC = () => {
  const { fieldOverlay, setDocumentTypesField } = useCciMainContext()

  const { areAllGroupsCollapsed, fieldsData, isBusy, isGroupCollapsedMap, isSingleGroupExpanded, reorderField, reorderGroup, setIsGroupCollapsedMap } =
    useChecklistConfigContext()

  const [fields, setFields] = useState<_Row[]>([])
  const [firstRowElement, firstRowRefCallback] = useRefCallback()
  const [groupNames, setGroupNames] = useState<string[]>([])
  const [top, setTop] = useState(0)

  useMemo(
    () =>
      setFields(
        fieldsData?.cci_deal_data_point_fields
          ?.map(field => ({
            actionTypeColumn: { actionTypeId: field?.action_type?.id! },
            correctColumn: { correctSamples: field?.correct_samples! },
            fieldTypeColumn: { fieldType: field?.field_type! },
            fieldNameColumn: {
              description: field?.description!,
              id: field?.id!,
              isDeleted: field?.is_deleted!,
              name: field?.name!,
              priority: field?.priority!,
              status: field?.main_status!
            },
            group: field?.group!,
            id: field?.id!,
            inReviewColumn: { inReviewSamples: field?.in_review_samples! },
            name: field?.name!,
            priority: field?.priority!,
            samplesColumn: { totalSamples: field?.total_samples! },
            statusColumn: { mainStatus: field?.main_status! },
            systemSourceColumn: { externalSource: field?.external_source!, source: field?.source! }
          }))
          .sort((a, b) => (a?.priority! > b?.priority! ? 1 : -1)) || []
      ),
    [fieldsData]
  )

  const { getTableBodyProps, getTableProps, headerGroups, prepareRow, rows } = useTable({ columns: COLUMNS, data: fields })

  const areMultipleGroups = useMemo(() => size(groupNames) > 1, [groupNames])
  const firstRowElementHeight = useMemo(() => firstRowElement?.clientHeight || 0, [firstRowElement])

  useMemo(() => {
    const groups = groupDataPointFields(fieldsData?.cci_deal_data_point_fields || [])

    const current = sortBy(Object.keys(groups), key => groups[key][0]?.group_priority)

    setGroupNames(previous => (isEqual(current, previous) ? previous : current))
  }, [fieldsData])

  useMemo(() => {
    if (!firstRowElement) return

    setTop(firstRowElementHeight)
  }, [firstRowElement, firstRowElementHeight])

  useEffect(
    () => setDocumentTypesField(fieldsData?.cci_deal_data_point_fields?.find(field => field?.internal_mapping?.includes('document_type'))),
    [fieldsData?.cci_deal_data_point_fields, setDocumentTypesField]
  )

  useEffect(() => {
    if (isBusy || !isEmpty(isGroupCollapsedMap)) return

    setIsGroupCollapsedMap(groupNames.reduce((previous, current) => ({ ...previous, [current]: false }), {}))
  }, [groupNames, isBusy, isGroupCollapsedMap, setIsGroupCollapsedMap])

  const handleFieldDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (!active || !over || active.id === over.id) return

      // optimistic update
      setFields(
        arrayMove(
          fields,
          fields.findIndex(({ id }) => id === active.id),
          fields.findIndex(({ id }) => id === over.id)
        )
      )

      // backend update using the smaller array
      const fieldIds: string[] = active.data.current?.sortable.items
      const activeIndex = fieldIds.indexOf(active.id as string)
      const overIndex = fieldIds.indexOf(over.id as string)
      const sortedFieldIds = arrayMove(fieldIds, activeIndex, overIndex)
      const moveAfterId = overIndex > 0 ? sortedFieldIds[overIndex - 1] : false

      reorderField({
        variables: {
          data_point_field_id: active.id as string,
          ...(moveAfterId ? { move_after_data_point_field_id: moveAfterId } : { move_to_top_group: active.data.current?.sortable.containerId })
        }
      })
    },
    [fields, reorderField]
  )

  const handleGroupDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (!active || !over || active.id === over.id) return

      const toIndex = over.data.current?.sortable.index
      const reorderedGroupNames = arrayMove(groupNames, active.data.current?.sortable.index, toIndex)

      setGroupNames(reorderedGroupNames) // optimistic update
      reorderGroup({ variables: { group_name: active.id as string, move_after_group_name: !toIndex ? null : reorderedGroupNames[toIndex - 1] } }) // backend update
    },
    [groupNames, reorderGroup]
  )

  const context = useMemo(
    () => ({ areMultipleGroups, firstRowRefCallback, prepareRow, rows, top }),
    [areMultipleGroups, firstRowRefCallback, prepareRow, rows, top]
  )

  return (
    <Box sx={{ height: `calc(100vh - ${HEADER_HEIGHT + CONFIG_HEADER_HEIGHT}px)`, overflow: 'auto', width: '100vw' }}>
      <ChecklistTableContext.Provider value={context}>
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={areAllGroupsCollapsed ? handleGroupDragEnd : isSingleGroupExpanded ? handleFieldDragEnd : undefined}
        >
          <Table {...getTableProps()} stickyHeader sx={{ mr: fieldOverlay.isOpen && !fieldOverlay.isClosing ? 105 : 0, width: '100vw' }}>
            <TableHead>
              {headerGroups.map(headerGroup => (
                <HeaderRow headerGroup={headerGroup} key={headerGroup.getHeaderGroupProps().key} />
              ))}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
              <SortableContext items={groupNames} strategy={verticalListSortingStrategy}>
                {groupNames.map(groupName => (
                  <GroupAndFieldsRows groupName={groupName} key={groupName} />
                ))}
              </SortableContext>
            </TableBody>
          </Table>
        </DndContext>

        {fieldOverlay.isOpen && <FieldOverlay />}
      </ChecklistTableContext.Provider>
    </Box>
  )
}

const FieldRow: FC<_FieldRowProps> = ({ fieldId, isOnlyFieldInGroup, row }) => {
  const { isBusy, isReorderingEnabled, isSingleGroupExpanded } = useChecklistConfigContext()
  const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({ id: fieldId, disabled: !isReorderingEnabled })

  const boxSx = useMemo(
    () => ({
      alignItems: 'center',
      display: 'flex',
      gap: 1,
      justifyContent: 'space-between',
      width: '100%',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: -1,
        left: 0,
        right: 0,
        borderTop: `1px solid ${grey[300]}`
      }
    }),
    []
  )

  const iconButtonSx = useMemo(
    () => ({ cursor: isDragging ? 'grabbing' : 'grab', mr: -1, my: -1, ':hover': { color: common.black }, ...(isDragging && { color: common.black }) }),
    [isDragging]
  )

  const tableRowSx = useMemo(
    () => ({ ...(isDragging && { position: 'relative', zIndex: Z_INDEX_DRAG }), ...(transform && { transform: CSS.Transform.toString(transform) }) }),
    [isDragging, transform]
  )

  return (
    <TableRow ref={setNodeRef} {...row.getRowProps()} sx={tableRowSx}>
      {row.cells.map((cell, index) => {
        const { key, ...props } = cell.getCellProps()

        const showDragHandle = index === size(row.cells) - 1 && !isOnlyFieldInGroup && isReorderingEnabled && isSingleGroupExpanded

        return (
          <TableCell
            {...props}
            key={key}
            sx={{ ...(!index ? { ...FIRST_CELL_SX, maxWidth: 300, pl: 1.5, pr: 1 } : CELL_SX), '&:last-of-type': { borderRight: 'none' } }}
          >
            <Box sx={{ ...boxSx, ...(showDragHandle && { flexDirection: 'row-reverse' }) }}>
              {showDragHandle && (
                <IconButton disabled={isBusy} onClick={event => event.stopPropagation()} size="small" sx={iconButtonSx} {...attributes} {...listeners}>
                  <DragHandleIcon />
                </IconButton>
              )}

              {cell.render('Cell')}
            </Box>
          </TableCell>
        )
      })}
    </TableRow>
  )
}

const GroupAndFieldsRows: FC<_GroupAndFieldsRowsProps> = ({ groupName }) => {
  const { filterGroup, filterSearchTerm, isGroupCollapsedMap } = useChecklistConfigContext()
  const { prepareRow, rows } = useChecklistTableContext()

  const groupFields = useMemo(() => rows.filter(row => filterGroup(row, groupName)).filter(filterSearchTerm), [rows, groupName, filterGroup, filterSearchTerm])
  const groupFieldIds = useMemo(() => groupFields.map(field => field.original.id), [groupFields])

  return (
    <>
      <GroupRow groupName={groupName} />

      {!isGroupCollapsedMap[groupName] &&
        (isEmpty(groupFields) ? (
          <TableRow>
            <TableCell colSpan={size(COLUMNS)} sx={{ ...FIRST_CELL_SX, borderRight: 0 }}>
              <Typography sx={{ fontSize: 14, fontStyle: 'italic' }}>No fields found.</Typography>
            </TableCell>
          </TableRow>
        ) : (
          <SortableContext id={groupName} items={groupFieldIds} strategy={verticalListSortingStrategy}>
            {groupFields.map(row => {
              prepareRow(row)

              return <FieldRow fieldId={row.original.id} isOnlyFieldInGroup={size(groupFields) === 1} key={row.getRowProps().key} row={row} />
            })}
          </SortableContext>
        ))}
    </>
  )
}

const GroupRow: FC<_GroupRowProps> = ({ groupName }) => {
  const { areAllGroupsCollapsed, isBusy, isGroupCollapsedMap, isReorderingEnabled, setIsGroupCollapsedMap } = useChecklistConfigContext()
  const { areMultipleGroups, top } = useChecklistTableContext()
  const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({ id: groupName })

  const boxSx = useMemo(
    () => ({
      alignItems: 'center',
      bgcolor: common.white,
      borderBottom: `1px solid ${grey[300]}`,
      borderTop: `1px solid ${grey[300]}`,
      bottom: 0,
      display: 'flex',
      gap: 1,
      justifyContent: 'space-between',
      left: 0,
      pl: 1,
      position: 'absolute',
      py: 1,
      top: -1,
      width: '100vw',
      ...(isDragging && { cursor: 'grabbing' })
    }),
    [isDragging]
  )

  const dragHandleIconButtonSx = useMemo(
    () => ({ cursor: isDragging ? 'grabbing' : 'grab', mr: 1, ':hover': { color: common.black }, ...(isDragging && { color: common.black }) }),
    [isDragging]
  )

  const isGroupCollapsed = useMemo(() => isGroupCollapsedMap[groupName], [groupName, isGroupCollapsedMap])

  const expandMoreIconButtonSx = useMemo(
    () => ({
      ':hover': { color: common.black },
      '& .MuiSvgIcon-root': {
        transform: `rotate(${isGroupCollapsed ? '-90' : '0'}deg)`,
        transition: 'transform 0.1s'
      }
    }),
    [isGroupCollapsed]
  )

  const tableCellSx = useMemo(() => ({ ...FIRST_CELL_SX, borderBottom: 0, borderTop: 0, pl: 1, top, zIndex: 3 }), [top])

  const tableRowSx = useMemo(
    () => ({ ...(isDragging && { position: 'relative', zIndex: Z_INDEX_DRAG }), ...(transform && { transform: CSS.Transform.toString(transform) }) }),
    [isDragging, transform]
  )

  const toggleGroupCollapse = useCallback(
    (groupName: string) => setIsGroupCollapsedMap(previous => ({ ...previous, [groupName]: !previous[groupName] })),
    [setIsGroupCollapsedMap]
  )

  return (
    <TableRow ref={setNodeRef} sx={tableRowSx}>
      <TableCell sx={tableCellSx}>
        <Box sx={boxSx}>
          <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
            <IconButton disabled={isBusy} onClick={() => toggleGroupCollapse(groupName)} size="small" sx={expandMoreIconButtonSx}>
              <ExpandMoreIcon />
            </IconButton>

            <Typography sx={{ fontSize: 16, fontWeight: 600, whiteSpace: 'nowrap' }}>{groupName}</Typography>
          </Box>

          {areAllGroupsCollapsed && areMultipleGroups && isReorderingEnabled && (
            <IconButton disabled={isBusy} onClick={event => event.stopPropagation()} size="small" sx={dragHandleIconButtonSx} {...attributes} {...listeners}>
              <DragHandleIcon />
            </IconButton>
          )}
        </Box>
      </TableCell>
    </TableRow>
  )
}

const HeaderRow: FC<_HeaderRowProps> = ({ headerGroup }) => {
  const { areAllGroupsCollapsed, isBusy, setIsGroupCollapsedMap } = useChecklistConfigContext()
  const { firstRowRefCallback } = useChecklistTableContext()

  const iconButtonSx = useMemo(
    () => ({
      '& .MuiSvgIcon-root': {
        transform: `rotate(${areAllGroupsCollapsed ? '-90' : '0'}deg)`,
        transition: 'transform 0.1s'
      }
    }),
    [areAllGroupsCollapsed]
  )

  const tableCellSx = useMemo(() => ({ bgcolor: grey[100], position: 'sticky', top: 0, whiteSpace: 'nowrap', '&:last-of-type': { borderRight: 'none' } }), [])

  const toggleGroupsCollapse = useCallback(() => {
    setIsGroupCollapsedMap(previous => {
      const isCollapsing = Object.values(previous).some(value => !value)

      return Object.fromEntries(Object.keys(previous).map(key => [key, isCollapsing]))
    })
  }, [setIsGroupCollapsedMap])

  return (
    <TableRow {...headerGroup.getHeaderGroupProps()} ref={firstRowRefCallback}>
      {headerGroup.headers.map((column, index) => {
        const { key, ...props } = column.getHeaderProps()

        return (
          <TableCell
            {...props}
            key={key}
            sx={{
              ...(!index ? { ...FIRST_CELL_SX, pl: 1, zIndex: 3 } : CELL_SX),
              ...tableCellSx,
              ...(SAMPLES_COLUMNS.includes(column.Header as string) && { bgcolor: grey[200], width: 10 }),
              ...(column.Header === SAMPLES_COLUMNS[0] && { fontWeight: 600 })
            }}
          >
            <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
              {!index && (
                <IconButton disabled={isBusy} onClick={toggleGroupsCollapse} size="small" sx={iconButtonSx}>
                  <ExpandMoreIcon />
                </IconButton>
              )}

              {column.render('Header')}
            </Box>
          </TableCell>
        )
      })}
    </TableRow>
  )
}

import { isEmpty } from 'lodash'
import { useBroadcastChannel } from './useBroadcastChannel'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHasPageBeenViewed } from './useHasPageBeenViewed'
import { useLocation } from 'react-router-dom'

// constants

const PAGE_QUEUE_BROADCAST_CHANNEL = 'PAGE_QUEUE_BROADCAST_CHANNEL'

const PAGE_QUEUE_PREFIX = 'PAGE_QUEUE'

// types

type BroadcastMessage = { nextPage: string; type: typeof PAGE_QUEUE_BROADCAST_CHANNEL }

// functions

const getNextPageInQueue = (): string | null => {
  const queue = getSortedQueue()

  return isEmpty(queue) ? null : queue[0].replace(PAGE_QUEUE_PREFIX, '')
}

const getQueueKey = (pathname: string) => `${PAGE_QUEUE_PREFIX}${pathname}`

const getSortedQueue = (): string[] => {
  const queue: Array<[string, number]> = []

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)

    if (key?.startsWith(PAGE_QUEUE_PREFIX)) {
      const timestamp = getTimestamp(key)

      queue.push([key, timestamp])
    }
  }

  return queue.sort(([, timestampA], [, timestampB]) => timestampA - timestampB).map(([key]) => key)
}

const getTimestamp = (key: string) => parseInt(localStorage.getItem(key) || '0', 10)

const removeStaleKeys = () => {
  const now = Date.now()
  const oneHour = 60 * 60 * 1000

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)

    if (key?.startsWith(PAGE_QUEUE_PREFIX)) {
      const timestamp = getTimestamp(key)
      const isKeyStale = now - timestamp > oneHour

      if (isKeyStale) localStorage.removeItem(key)
    }
  }
}

// hooks

/**
 * Coordinates page loads when multiple tabs are opened. This is important when opening multiple <DealPage> and <DocumentPage> tabs because the
 * `useDataPointsForGroupQuery` in the <ChecklistTab> is resource-intensive, and running too many queries in parallel will degrade performance.
 *
 * The hook ensures that only one page loads at a time by:
 * 1. Registering each page in a queue (using localStorage)
 * 2. Loading pages sequentially based on when they were opened
 * 3. Using BroadcastChannel to notify the next page when it's their turn to load
 *
 * Note: If the user views a page (by clicking its tab), it will start loading immediately regardless of its position in the queue.
 */
export const usePageLoadingQueue = () => {
  const { pathname } = useLocation()
  const [shouldLoadPage, setShouldLoadPage] = useState(false)
  const [shouldLoadNextPage, setShouldLoadNextPage] = useState(false)
  const hasPageBeenViewed = useHasPageBeenViewed()

  const key = useMemo(() => getQueueKey(pathname), [pathname])

  const handleMessage = useCallback(
    (message: BroadcastMessage) => {
      if (message.type === PAGE_QUEUE_BROADCAST_CHANNEL && message.nextPage === pathname) setShouldLoadPage(true)
    },
    [pathname]
  )

  const { postMessage } = useBroadcastChannel(PAGE_QUEUE_BROADCAST_CHANNEL, handleMessage)

  useEffect(() => {
    removeStaleKeys()

    if (!localStorage.getItem(key)) {
      localStorage.setItem(key, Date.now().toString())

      const queue = getSortedQueue()
      const isFirstInQueue = queue[0] === key

      setShouldLoadPage(isFirstInQueue)
    }

    return () => localStorage.removeItem(key)
  }, [key])

  useEffect(() => {
    if (shouldLoadNextPage) {
      localStorage.removeItem(key)

      const nextPage = getNextPageInQueue()

      if (nextPage) postMessage({ type: PAGE_QUEUE_BROADCAST_CHANNEL, nextPage } as BroadcastMessage)
    }
  }, [key, shouldLoadNextPage, postMessage])

  useEffect(() => {
    if (hasPageBeenViewed) {
      localStorage.removeItem(key)

      setShouldLoadPage(true)
    }
  }, [key, hasPageBeenViewed])

  useEffect(() => {
    const handlePageClose = () => localStorage.removeItem(key)

    window.addEventListener('beforeunload', handlePageClose)

    return () => window.removeEventListener('beforeunload', handlePageClose)
  }, [key])

  return [shouldLoadPage, setShouldLoadNextPage] as const
}

import { CciDealDataPointFieldsDocument, MatchingChildrenDataPointFieldsDocument, useDeleteDataPointFieldMutation } from '../../../../../graphql/codegen/hooks'
import { useCciMainContext } from '../../../CCI_Main'
import Button from '../../../../../components/Button'
import React, { useEffect, useState } from 'react'
import TypeToConfirm from '../../../../../components/TypeToConfirm'
import WithTooltip from '../../../../../components/WithTooltip'
import css from '../style.module.scss'

export default function DeleteField({ closeModal, dataPointId, dataPointName }: { closeModal?: any; dataPointId?: string; dataPointName?: string }) {
  const { fieldOverlay, setActiveComponent, setMessageTitle, setModalError, setModalLoading, setModalLoadingMessage, setModalSuccess, setSelectedItem } =
    useCciMainContext()
  const [confirmed, setConfirmed] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)

  const [deleteDPF, { error: deleteDPFError, loading: deleteDPFLoading }] = useDeleteDataPointFieldMutation({
    onCompleted: () => {
      fieldOverlay.close()
      setActiveComponent(null)
      setMessageTitle(dataPointName!)
      setModalSuccess('Field successfully deleted. Pending removal…')
      setSelectedItem('')
    },
    refetchQueries: [{ query: CciDealDataPointFieldsDocument }, { query: MatchingChildrenDataPointFieldsDocument }],
    update(cache, { data }) {
      if (data?.delete_data_point_field?.success) {
        cache.modify({
          id: cache.identify({ __typename: 'DataPointField', id: dataPointId }),
          fields: { is_deleted: () => true }
        })
      }
    }
  })

  const handleDelete = () => {
    if (dataPointId) {
      deleteDPF({ variables: { data_point_field_id: dataPointId } })
    } else {
      setModalError('Datapoint id is missing')
    }
  }

  useEffect(() => {
    if (confirmed) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [confirmed])

  useEffect(() => {
    if (deleteDPFLoading) {
      setModalLoading(true)
      setModalLoadingMessage('Deleting Field…')
    } else {
      setModalLoading(false)
      setModalLoadingMessage('')
    }
    // eslint-disable-next-line
    }, [deleteDPFLoading])

  useEffect(() => {
    if (deleteDPFError) {
      setMessageTitle('Error deleting field:')
      setModalError(deleteDPFError)
    }
    // eslint-disable-next-line
    }, [deleteDPFError])

  return (
    <>
      {dataPointName && <h5 style={{ textAlign: 'center' }}>{dataPointName}</h5>}
      <p style={{ textAlign: 'center', marginTop: '8px' }}>{`Are you sure you want to delete this field?`}</p>
      <TypeToConfirm setConfirmed={setConfirmed} />
      <div className={css.modalButtonRow}>
        <Button onClick={closeModal} variant={'secondary'}>{`Cancel`}</Button>
        <WithTooltip content={!dataPointId ? 'Datapoint id is missing' : isDisabled ? 'Type Confirm to proceed' : ''}>
          <Button disabled={!dataPointId || isDisabled} onClick={handleDelete}>{`Submit`}</Button>
        </WithTooltip>
      </div>
    </>
  )
}
